import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {DataTableDirective} from 'angular-datatables';
import {DataTablesResponse} from '../models/datatableResponse';

@Component({
  selector: 'app-add-keywords-to-campaigns-modal',
  templateUrl: './add-keywords-to-campaigns-modal.component.html',
  styleUrls: ['./add-keywords-to-campaigns-modal.component.css']
})
export class AddKeywordsToCampaignsModalComponent implements OnInit {

  CampaignForKeywords: any;
  Step: number = 1;
  SkuShowingCount: number = 10;
  SearchINTableSKU: string = '';

  dtOptionsSKU: DataTables.Settings = {};
  dtOptionsCampaign: DataTables.Settings = {};
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;
  allSKUs: any[];
  SelectAllCheckboxSKU: boolean = false;
  CampaignShowingCount: number = 10;
  SearchINTableCmapaigns: string = '';
  selectedSKU: any [];
  onlyManual: boolean = false;
  allCampaigns: any [];
  SelectAllCheckboxCampaign: boolean = false;
  AdGroupForKeywords: any[];
  NewADGroups: any[];
  DefaultBid: number = 0.2;
  additionalKeywords: string;
  KeywordList: any[];
  sku: any = {};

  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, private http: HttpClient) {
    this.CampaignForKeywords = {CampaignName: ''};
    const url = '/api/SKUList/manage?SelectAll=true&t=' + Date.now();
    this.sku.r1 = 1;

    this.dtOptionsSKU = {

      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [3, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {
        const postData = {...dataTablesParameters, ...this.postdata()};
        this.http
          .post<DataTablesResponse>(
            url,
            postData
          ).subscribe(resp => {

          this.allSKUs = resp.data;
          console.log('data');
          console.log(resp);

          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }

          if (resp.error === false || resp.error === undefined) {

          } else {
            this.toastr.warning(resp.msg);
          }
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'slug', name: 'action', orderable: false},
        {data: 'SKU', name: 'AdGroup'},
        {data: 'Image', name: 'Revenue', orderable: false},
        {data: 'Name', name: 'Orders'}

      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {

      }
    };

    const urlCampaigns = '/api/SearchTermTools/campaignList?t=' + Date.now();

    this.dtOptionsCampaign = {

      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [3, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {
        const postData = {...dataTablesParameters, ...this.postdata()};
        this.http
          .post<DataTablesResponse>(
            url,
            postData
          ).subscribe(resp => {

          this.allCampaigns = resp.data;
          console.log('data');
          console.log(resp);

          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }

          if (resp.error === false || resp.error === undefined) {
          } else {
            this.toastr.warning(resp.msg);
          }
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'id', name: 'id', orderable: false},
        {data: 'CampaignName', name: 'CampaignName'},

      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {

      }
    };
  }

  ngOnInit() {
  }

  postdata() {
    return {
      skuList: this.selectedSKU, onlyManual: this.onlyManual
    };

  }

  DeleteAdgroup(adGroup) {

  }

  AddAdgroup() {

  }

  addNewKeywords() {

  }

  DeleteSelectedRecord(keyword, adgroup) {

  }

  NextStep() {
    this.Step += 1;
  }

  AddKeywordsToCampaign() {

  }

  ADDSKU(curentItem) {

  }
}
