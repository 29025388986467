export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  accessToken: string;
  helpersBlock: any;
  email: string;
  emailFull: string;
  CurrentAccountId: number;
  CurrentAccount: any;
  ReportTypes: string;
  AccountList: any;
  prorfolioList: any;
  AccountId: string;
  Currency: any;
  CurrencyCurentObject: any;
  CurrencyCurentId: number;
  walkmeCompleted: boolean;
  DisableAutoloadData: boolean;
  TrialEnd: string;
  currencyCode: string;
  ShowStartupModal: boolean;
  ShowWzardModal: boolean;
  ShowAutopilotModal: boolean;
  childRole: number;
  countWarnings: number;
  suggestions: any;
  totalSuggestions: number;
  totalAutopilot: number;
  totalCM: number;
  CangeMasterCounts: any;
  show30DiscountLine: boolean;
  admin: boolean;
  CurrentSubscription: any;
  SyncProgressPercent: string;

}
