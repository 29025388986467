import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-add-to-negative-modal',
  templateUrl: './add-to-negative-modal.component.html',
  styleUrls: ['./add-to-negative-modal.component.scss']
})
export class AddToNegativeModalComponent implements OnInit {

  @Input() selectedRecords;
  @Input() negativeTarget;
  NegativeType: string = 'exact';
  SelectedAdGroup: string = 'all';
  ShowCurrentAdGroup: boolean = false;
  AllADgroups: any;

  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, private http: HttpClient) {

  }

  ngOnInit() {
  }

  AddToNegative() {
    const url = '/api/negatives/add';
    const postData = {
      adGroup: this.SelectedAdGroup,
      negativeTarget: this.negativeTarget,
      negativeType: this.NegativeType,
      records: this.selectedRecords,
      startDate: null,
      endDate: null
    };
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result.error === false) {
          this.toastr.success(result.msg, 'Updated', {enableHtml: true});
          this.activeModal.close('updated');
        } else {
          this.toastr.warning(result.msg);
        }
      });
  }


}
