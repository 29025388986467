import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {DataTablesResponse} from '../models/datatableResponse';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {UploadService} from '../upload.service';
import {SharedService2Service} from '../shared-service2.service';
import {Lightbox} from 'ngx-lightbox';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {FBWizardModalComponent} from '../fbwizard-modal/fbwizard-modal.component';
import {ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fbwizard-new',
  templateUrl: './fbwizard-new.component.html',
  styleUrls: ['./fbwizard-new.component.scss']
})
export class FBWizardNewComponent implements OnInit {

  OnlySettings: boolean = false;
  Step: number = 1;
  HelpersBlock: any;
  AllStores: any[];
  SelectAll: boolean = false;
  @Input() public wizardType: string;
  @Input() public landingId: number = 0;
  dtOptionsProducts: DataTables.Settings = {};
  dtOptionsProductsWizard: DataTables.Settings = {};
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;
  AllAvaliableProducts: any[];
  skucostPerUnit: any = {};
  skuFBAFee: any = {};
  skuAVGPrice: any = {};
  skuProfitMargin: any = {};
  skuProfitMarginPercent: any = {};
  AllAvaliableSKUS: any[];
  selectedProducts: number;
  SelectedSKU: any[];
  skuWizzard: any = {};
  TableLength: number = 10;
  WizzardParams: any;
  amazonProductUrl: string = '';
  amazonProductImageMain: string = '';
  amazonProductName: string = '';
  marketplace: string = 'us';
  amazonProductAsin: string = '';
  amazonProductPrice: string = '';
  productImagesList: string = '';
  amazonProductDescription: string = '';
  PixelId: string = '';
  ConversionIds: string = '';
  tagId: string = '';
  private _albums: any = [];
  selectedTemplate: number = 0;
  LandingName: string = '';
  editorIframeUrl: SafeResourceUrl;
  iframeLoaded: boolean = false;
  addedUrlInProcess: boolean = false;
  AddedUrls: any = [];
  selectedUrlType: string = '';
  customUrl: string = '';
  advertiserStore: string = '';
  Marketplace: string = '';
  brand: string = '';
  keywords: string = '';
  minPrice: string = '';
  maxPrice: string = '';

  keyword1: string = '';
  keyword2: string = '';
  keyword3: string = '';
  keyword4: string = '';
  keyword5: string = '';
  productQuantity: string = '';
  productQuantity2: string = '';
  productQuantity3: string = '';
  productQuantity4: string = '';
  amazonProductAsin2: string = '';
  amazonProductAsin3: string = '';
  amazonProductAsin4: string = '';
  editedIndex: number = -1;

  trafic_fb: boolean = false;
  trafic_google: boolean = false;
  trafic_other: boolean = false;
  trafic_social: boolean = false;
  CustomeId: string = '';
  faceboockTrackingEnabled: boolean = false;
  googleTracking: boolean = false;
  customeTracking: boolean = false;


  constructor(private toastr: ToastrService, public http: HttpClient, private router: Router,
              private uploadService: UploadService, public dataShared: SharedService2Service, private _lightbox: Lightbox, private dom: DomSanitizer, private route: ActivatedRoute) {

    this.skuWizzard.r1 = 1;
    this.skucostPerUnit.r1 = 1;
    this.skuAVGPrice.r1 = 1;
    this.skuProfitMargin.r1 = 1;
    this.skuProfitMarginPercent.r1 = 1;
    this.skuFBAFee.r1 = 1;
    this.WizzardParams = {SelectedSkus: []};


    (window as any).iframeCallback = (param: any) => this.actualCallback(param);

    if (window.addEventListener) {
      console.log('ADD LISTENER');
      window.addEventListener('message', this.receiveMessage.bind(this), false);
    }


    const urlProducts = '/api/SKUList/FBWizzard';
    this.dtOptionsProducts = {

      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [3, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {

        const postData = {...dataTablesParameters, ...this.postdataProductsWizard()};

        this.http
          .post<DataTablesResponse>(
            urlProducts, postData
          ).subscribe(resp => {

          this.AllAvaliableProducts = resp.data;
          console.log('data');
          console.log(resp);
          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }
          if (resp.error === false || resp.error === undefined) {
          } else {
            this.toastr.warning(resp.msg);
          }
          resp.data.forEach((element, index: number) => {
            this.skucostPerUnit[element.slug] = element.skucostPerUnitOriginal;
            this.skuAVGPrice[element.slug] = element.skuAVGPriceOriginal;
            this.skuProfitMargin[element.slug] = element.skuProfitMarginOriginal;
            this.skuProfitMarginPercent[element.slug] = element.skuProfitMarginPercentOriginal;
            this.skuFBAFee[element.slug] = element.skuFBAFeeOriginal;
          });

          this.AllAvaliableSKUS = resp.allAvaliableSKU;


          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });

      },
      columns: [

        {data: 'select', name: 'select'},
        {data: 'Image', name: 'Image'},
        {data: 'sku_asin', name: 'sku_asin'},
        {data: 'orders', name: 'orders'},
        {data: 'product_name', name: 'product_name'},

      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {

      }
    };


    const url = '/api/FBWizzard/allAdvertisers';
    this.http.get<any>(url).toPromise().then(result => {
      console.log(result);
      if (result.error === true) {
        this.toastr.warning(result.msg);
      } else {
        console.log('all worked');
        this.AllStores = result.AllStores;
        this.advertiserStore = result.AllStores[0].advertiserId.toString();

      }
    });


    let src = '/assets/img/templates/1-2.jpg';
    const caption = 'Image  caption here';
    let thumb = '/assets/img/templates/1-2-220x218.jpg';


    this._albums.push({
      src: src,
      caption: caption,
      thumb: thumb
    });


    src = '/assets/img/templates/2.jpg';
    thumb = '/assets/img/templates/2-220x181.jpg';


    this._albums.push({
      src: src,
      caption: caption,
      thumb: thumb
    });

    src = '/assets/img/templates/newses.jpg';
    thumb = '/assets/img/templates/newses-220x220.jpg';


    this._albums.push({
      src: src,
      caption: caption,
      thumb: thumb
    });


  }


  actualCallback(param: any) {
    console.log('Callback executed:', param);
  }

  receiveMessage: any = (event: any) => {
    console.log(event);
  };


  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }


  ngOnInit() {

    /*  const landingId = history.state.landingId;

      console.log('landing');
      console.log(landingId);
  */
    this.route.queryParams
      .subscribe(params => {
          console.log(params); // { order: "popular" }
          const landingId = params.landingId;
          this.showFBWizardSettings(landingId);

        }
      );
  }


  showFBWizardSettings(WizardId) {
    console.log('show settings for ' + WizardId);
    if (WizardId === undefined) {
      return false;
    }
    const url = '/api/FBWizzard/settings/' + WizardId;
    this.http.get<any>(url).toPromise().then(result => {
      console.log(result);
      if (result.error === true) {
        this.toastr.warning(result.msg);
      } else {
        console.log('all worked');

        this.OnlySettings = true;

        this.amazonProductUrl = result.settings.amazonProductUrl;
        this.ConversionIds = result.settings.ConversionIds;
        this.PixelId = result.settings.PixelId;
        this.amazonProductAsin = result.settings.amazonProductAsin;
        this.amazonProductDescription = result.settings.amazonProductDescription;
        this.amazonProductName = result.settings.amazonProductName;
        this.amazonProductPrice = result.settings.amazonProductPrice;
        this.amazonProductImageMain = result.settings.amazonProductImageMain;
        this.landingId = result.settings.id;
        this.LandingName = result.settings.landingName;
        this.advertiserStore = result.settings.advertiserStore;
        this.selectedTemplate = result.settings.selectedTemplate;
        this.tagId = result.settings.tagId;
        this.editorIframeUrl = this.dom.bypassSecurityTrustResourceUrl(result.linkEdit);
        this.AddedUrls = JSON.parse(result.settings.AddedUrls);
if(this.AddedUrls == null){
  this.AddedUrls =[];
}
        this.trafic_fb = result.settings.trafic_fb;
        this.trafic_google = result.settings.trafic_google;
        this.trafic_other = result.settings.trafic_other;
        this.trafic_social = result.settings.trafic_social;
        this.CustomeId = result.settings.CustomeId;
        this.faceboockTrackingEnabled = result.settings.faceboockTrackingEnabled;
        this.googleTracking = result.settings.googleTracking;
        this.customeTracking = result.settings.customeTracking;


        this.Step = 6;

      }
    });

  }


  closeHelp(type) {
    console.log('type');
  }

  updatePageData() {
    if (this.dtElements !== undefined) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          dtInstance.table().ajax.reload();
        });
      });
    }
  }

  postdataProductsWizard() {
    return {
      selected: this.SelectedSKU,
      wizardType: this.wizardType,
      SelectAll: this.SelectAll
    };
  }


  ADDSKUToWizzard(selectedItem) {
    console.log(selectedItem);
    if (this.SelectedSKU !== undefined && this.SelectedSKU.indexOf(selectedItem.sku) >= 0) {
      this.SelectedSKU.splice(this.SelectedSKU.indexOf(selectedItem.sku), 1);
    } else {
      console.log('sku not present in array');
      if (this.SelectedSKU !== undefined) {
        if (this.SelectedSKU.length === 1) {
          this.skuWizzard[selectedItem.slug] = false;
          this.toastr.warning('Only 1 item per landing allowed');
          return false;
        }

        this.SelectedSKU.push(selectedItem.sku);
      } else {
        this.SelectedSKU = [];
        this.SelectedSKU.push(selectedItem.sku);
      }
    }
  }

  changePageLength(event) {
    if (this.dtElements !== undefined) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          dtInstance.table().page.len(this.TableLength).draw();
        });
      });
    }
  }

  saveSelectedSku() {

    this.WizzardParams.SelectedSkus = [];
    if (this.SelectedSKU === undefined) {
      this.toastr.warning('Please select SKU');
    } else {
      this.AllAvaliableSKUS.forEach((element, index: number) => {
        if (this.SelectedSKU.indexOf(element.sku) >= 0) {
          this.WizzardParams.SelectedSkus.push(element);
        }
      });
      if (this.WizzardParams.SelectedSkus.length === 0) {
        this.toastr.warning('Please select SKU');
        return false;
      } else {


        const postData = {
          landingId: this.landingId,
          selectedSKU: this.WizzardParams.SelectedSkus
        };


        const url = '/api/saveFBProducts';
        this.http.post<any>(url, postData).toPromise()
          .then(result => {
            if (result.error === true) {
              this.toastr.warning(result.msg);
            } else {
              this.toastr.success(result.msg);
              this.landingId = result.landingId;
              this.amazonProductUrl = result.amazonProductUrl;
              this.amazonProductImageMain = result.amazonProductImageMain;
              this.amazonProductName = result.amazonProductName;
              this.amazonProductAsin = result.amazonProductAsin;
              this.amazonProductPrice = result.amazonProductPrice;
              this.productImagesList = result.productImagesList;
              this.amazonProductDescription = result.amazonProductDescription;

              this.Step = 2;
              this.stepChanged();
            }
          });
      }
    }
  }

  stepChanged() {

  }


  saveDescriptions() {

    const postData = {
      landingId: this.landingId,
      amazonProductUrl: this.amazonProductUrl,
      amazonProductImageMain: this.amazonProductImageMain,
      amazonProductName: this.amazonProductName,
      amazonProductAsin: this.amazonProductAsin,
      amazonProductPrice: this.amazonProductPrice,
      productImagesList: this.productImagesList,
      amazonProductDescription: this.amazonProductDescription
    };


    const url = '/api/saveFBDescriptions';
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          this.toastr.success(result.msg);

          this.Step = 3;
          this.stepChanged();
        }
      });


  }


  goToStep(step) {
    this.Step = step;
  }

  savePixel() {

    if (this.LandingName === '' || this.LandingName === undefined) {
      this.toastr.warning('Please enter portal name');
      return false;
    }

    const postData = {
      landingId: this.landingId,
      PixelId: this.PixelId,
      ConversionIds: this.ConversionIds,
      tagId: this.tagId,
      LandingName: this.LandingName,
      advertiserStore: this.advertiserStore,
      trafic_fb: this.trafic_fb,
      trafic_google: this.trafic_google,
      trafic_other: this.trafic_other,
      trafic_social: this.trafic_social,
      CustomeId: this.CustomeId,
      faceboockTrackingEnabled: this.faceboockTrackingEnabled,
      googleTracking: this.googleTracking,
      customeTracking: this.customeTracking
    };


    const url = '/api/saveFBPixel';
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          this.toastr.success(result.msg);

          this.Step = 4;
          this.stepChanged();
        }
      });

  }


  saveTemplate() {


    if (this.selectedTemplate === 0) {
      this.toastr.warning('Please select template');
      return false;
    }

    const postData = {
      landingId: this.landingId,
      selectedTemplate: this.selectedTemplate
    };


    const url = '/api/saveFBTemplate';
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          this.toastr.success(result.msg);

          this.Step = 6;

          this.editorIframeUrl = this.dom.bypassSecurityTrustResourceUrl(result.url);

          this.stepChanged();
        }
      });

  }

  headerStep(newStep) {
    switch (newStep) {
      case 1:
        this.Step = newStep;
        break;
      case 2:
        if (this.amazonProductUrl !== undefined && this.amazonProductUrl !== '') {
          this.Step = newStep;
        }
        break;
      case 3:
        if (this.tagId !== undefined && this.tagId !== '') {
          this.Step = newStep;
        }
        break;
      case 4:


        if (this.AddedUrls !== undefined && this.AddedUrls !== null && this.AddedUrls.length > 0) {
          this.Step = newStep;
        }
        break;
      case 5:
        if (this.selectedTemplate !== undefined && this.selectedTemplate !== 0) {
          this.Step = newStep;
        }
        break;

      case 6:
        if (this.editorIframeUrl !== undefined && this.editorIframeUrl !== 0) {
          this.Step = newStep;
        }
        break;


    }

  }

  isStepActive(step) {
    if (this.Step === step) {
      return true;
    }

    switch (step) {
      case 1:
        return true;

        break;
      case 2:
        if (this.amazonProductUrl !== undefined && this.amazonProductUrl !== '') {
          return true;
        }
        break;
      case 3:
        if (this.tagId !== undefined && this.tagId !== '') {
          return true;
        }
        break;

      case 4:
        if (this.AddedUrls !== undefined && this.AddedUrls !== null && this.AddedUrls.length > 0) {
          return true;
        }
        break;
      case 5:
        if (this.selectedTemplate !== undefined && this.selectedTemplate !== 0) {
          return true;
        }
        break;
      case 6:
        if (this.editorIframeUrl !== undefined && this.editorIframeUrl !== 0) {
          return true;
        }
        break;

    }

    return false;
  }

  iFrameLoaded() {
    console.log('IFRAME LOADED');
    if (this.Step > 4) {
      this.iframeLoaded = true;
    }
  }

  goBack() {
    if (this.OnlySettings && this.Step == 2) {
      this.router.navigate(['fbwizard']);
    } else {
      if (this.Step > 1) {
        this.Step--;
      } else {
        this.router.navigate(['fbwizard']);
      }
    }
  }

  saveUrls() {
    console.log('save urls');


    if (this.AddedUrls.length === 0) {
      this.toastr.warning('Please add urls first');
      return false;
    }

    const postData = {
      landingId: this.landingId,
      AddedUrls: this.AddedUrls
    };


    const url = '/api/saveFBUrls';
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          this.toastr.success(result.msg);

          this.Step = 5;
          this.stepChanged();
        }
      });

  }


  saveAndContinue() {
    switch (this.Step) {
      case 1:
        this.saveSelectedSku();
        break;

      case 2:
        this.saveDescriptions();
        break;

      case 3:
        this.savePixel();
        break;

      case 4:
        this.saveUrls();
        break;


      case 5:
        this.saveTemplate();
        break;
    }

  }

  showAddUrl(type) {
    this.addedUrlInProcess = true;
    this.selectedUrlType = type;
    this.editedIndex = -1;
  }

  addUrl() {
    let url = '';
    let urlData = {};

    switch (this.selectedUrlType) {
      case 'custom':

        urlData = {
          typeVerbose: 'Custom URL',
          parametrsVerbose: 'url=' + this.customUrl,
          url: this.customUrl,
          parametrs: {url: this.customUrl},
          type: this.selectedUrlType
        };

        if (this.editedIndex === -1) {
          this.AddedUrls.push(urlData);
        } else {
          this.AddedUrls[this.editedIndex] = urlData;
        }


        break;

      case '2stepBrand':

        url = 'https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-brand=' + this.brand + '&field-keywords=' + this.keywords + '&field-asin=' + this.amazonProductAsin + '&low-price=' + this.minPrice + '&high-price=' + this.maxPrice;
        urlData = {
          typeVerbose: '2-Step Via Brand',
          parametrsVerbose: '( brand=' + this.brand + ', keyword=' + this.keywords + ', asin=' + this.amazonProductAsin + ', min-price=' + this.minPrice + ', max-price=' + this.maxPrice + ' )',
          url: url,
          type: this.selectedUrlType,
          parametrs: {
            brand: this.brand,
            Marketplace: this.Marketplace,
            keyword: this.keywords,
            asin: this.amazonProductAsin,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,

          }
        };

        if (this.editedIndex === -1) {
          this.AddedUrls.push(urlData);
        } else {
          this.AddedUrls[this.editedIndex] = urlData;
        }

        break;

      case '2stepKeyword':

        url = 'https://www.amazon.com/s/ref=nb_sb_noss_1?url=search-alias%3Daps&field-keywords=' + this.keywords + '&hidden-keywords=' + this.amazonProductAsin;
        urlData = {
          typeVerbose: '2-Step Via Hidden Keyword',
          parametrsVerbose: '(  keyword=' + this.keywords + ', asin=' + this.amazonProductAsin + ' )',
          url: url,
          type: this.selectedUrlType,
          parametrs: {
            Marketplace: this.Marketplace,
            keyword: this.keywords,
            asin: this.amazonProductAsin
          }
        };
        if (this.editedIndex === -1) {
          this.AddedUrls.push(urlData);
        } else {
          this.AddedUrls[this.editedIndex] = urlData;
        }

        break;

      case '2stepAsin':

        url = 'https://www.amazon.com/s/?keywords=' + this.keywords + '&ie=UTF8&field-asin=' + this.amazonProductAsin + '&rh=i:aps,ssx:relevance';
        urlData = {
          typeVerbose: '2-Step Via Field-ASIN',
          parametrsVerbose: '(  keyword=' + this.keywords + ', asin=' + this.amazonProductAsin + ' )',
          url: url,
          type: this.selectedUrlType,
          parametrs: {
            Marketplace: this.Marketplace,
            keyword: this.keywords,
            asin: this.amazonProductAsin
          }
        };
        if (this.editedIndex === -1) {
          this.AddedUrls.push(urlData);
        } else {
          this.AddedUrls[this.editedIndex] = urlData;
        }

        break;

      case 'canonical':

        url = 'https://www.amazon.com/' + this.keyword1 + '-' + this.keyword2 + '-' + this.keyword3 + '-' + this.keyword4 + '-' + this.keyword5 + '/dp/' + this.amazonProductAsin;
        urlData = {
          typeVerbose: 'Canonical URL',
          parametrsVerbose: '(  keyword1=' + this.keyword1 + ',  keyword2=' + this.keyword2 + ', keyword3=' + this.keyword3 + ', keyword4=' + this.keyword4 + ', keyword5=' + this.keyword5 + ',    asin=' + this.amazonProductAsin + ' )',
          url: url,
          type: this.selectedUrlType,
          parametrs: {
            Marketplace: this.Marketplace,
            keyword1: this.keyword1,
            keyword2: this.keyword2,
            keyword3: this.keyword3,
            keyword4: this.keyword4,
            keyword5: this.keyword5,
            asin: this.amazonProductAsin
          }
        };
        if (this.editedIndex === -1) {
          this.AddedUrls.push(urlData);
        } else {
          this.AddedUrls[this.editedIndex] = urlData;
        }

        break;

      case 'add2cart':

        url = 'https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=' + this.amazonProductAsin + '&Quantity.1=' + this.productQuantity;
        urlData = {
          typeVerbose: 'Add To Cart',
          parametrsVerbose: '(  asin=' + this.amazonProductAsin + ',  quantity=' + this.productQuantity + ' )',
          url: url,
          type: this.selectedUrlType,
          parametrs: {
            Marketplace: this.Marketplace,
            productQuantity: this.productQuantity,
            asin: this.amazonProductAsin
          }
        };
        if (this.editedIndex === -1) {
          this.AddedUrls.push(urlData);
        } else {
          this.AddedUrls[this.editedIndex] = urlData;
        }

        break;

      case 'targetedAsin':

        url = 'https://www.amazon.com/s/?k=' + this.amazonProductAsin + '&ref=nb_sb_noss';
        urlData = {
          typeVerbose: 'Targeted ASIN Search URL',
          parametrsVerbose: '(  asin=' + this.amazonProductAsin + ')',
          url: url,
          type: this.selectedUrlType,
          parametrs: {
            Marketplace: this.Marketplace,
            asin: this.amazonProductAsin
          }
        };
        if (this.editedIndex === -1) {
          this.AddedUrls.push(urlData);
        } else {
          this.AddedUrls[this.editedIndex] = urlData;
        }

        break;

      case 'buyToogether':

        url = 'https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=' + this.amazonProductAsin + '&Quantity.1=' + this.productQuantity + '&ASIN.2=' + this.amazonProductAsin2 + '&Quantity.2=' + this.productQuantity2 + '&ASIN.3=' + this.amazonProductAsin3 + '&Quantity.3=' + this.productQuantity3 + '&ASIN.4=' + this.amazonProductAsin4 + '&Quantity.4=' + this.productQuantity4;
        urlData = {
          typeVerbose: 'Buy Together',
          parametrsVerbose: '(  asin=' + this.amazonProductAsin + ' , quantity=' + this.productQuantity + ',  asin2=' + this.amazonProductAsin2 + ' , quantity2=' + this.productQuantity2 + ',   asin3=' + this.amazonProductAsin3 + ' , quantity3=' + this.productQuantity3 + ',  asin4=' + this.amazonProductAsin4 + ' , quantity4=' + this.productQuantity4 + '  )',
          url: url,
          type: this.selectedUrlType,
          parametrs: {
            Marketplace: this.Marketplace,
            asin: this.amazonProductAsin,
            quantity: this.productQuantity,
            asin2: this.amazonProductAsin2,
            quantity2: this.productQuantity2,
            asin3: this.amazonProductAsin3,
            quantity3: this.productQuantity3,
            asin4: this.amazonProductAsin4,
            quantity4: this.productQuantity4
          }
        };
        if (this.editedIndex === -1) {
          this.AddedUrls.push(urlData);
        } else {
          this.AddedUrls[this.editedIndex] = urlData;
        }

        break;
    }
    this.addedUrlInProcess = false;
  }


  editUrl(url) {

    console.log(url);

    this.addedUrlInProcess = true;
    this.selectedUrlType = url.type;
    this.editedIndex = -1;

    this.AddedUrls.forEach((element, index: number) => {
      if (element.url === url.url) {
        this.editedIndex = index;
      }
    });


    switch (url.type) {
      case 'custom':
        this.customUrl = url.parametrs.url;

        break;

      case '2stepBrand':

        this.brand = url.parametrs.brand;
        this.Marketplace = url.parametrs.Marketplace;
        this.keywords = url.parametrs.keyword;
        this.amazonProductAsin = url.parametrs.asin;
        this.minPrice = url.parametrs.minPrice;
        this.maxPrice = url.parametrs.maxPrice;


        break;

      case '2stepKeyword':
        this.Marketplace = url.parametrs.Marketplace;
        this.amazonProductAsin = url.parametrs.asin;
        this.keywords = url.parametrs.keyword;

        break;

      case '2stepAsin':

        this.Marketplace = url.parametrs.Marketplace;
        this.amazonProductAsin = url.parametrs.asin;
        this.keywords = url.parametrs.keyword;


        break;

      case 'canonical':

        this.Marketplace = url.parametrs.Marketplace;
        this.amazonProductAsin = url.parametrs.asin;
        this.keyword1 = url.parametrs.keyword1;
        this.keyword2 = url.parametrs.keyword2;
        this.keyword3 = url.parametrs.keyword3;
        this.keyword4 = url.parametrs.keyword4;
        this.keyword5 = url.parametrs.keyword5;

        break;

      case 'add2cart':

        this.Marketplace = url.parametrs.Marketplace;
        this.amazonProductAsin = url.parametrs.asin;
        this.productQuantity = url.parametrs.productQuantity;


        break;

      case 'targetedAsin':

        this.Marketplace = url.parametrs.Marketplace;
        this.amazonProductAsin = url.parametrs.asin;


        break;

      case 'buyToogether':

        this.Marketplace = url.parametrs.Marketplace;
        this.amazonProductAsin = url.parametrs.asin;
        this.productQuantity = url.parametrs.quantity;
        this.amazonProductAsin2 = url.parametrs.asin2;
        this.productQuantity2 = url.parametrs.quantity2;
        this.amazonProductAsin3 = url.parametrs.asin3;
        this.productQuantity3 = url.parametrs.quantity3;
        this.amazonProductAsin4 = url.parametrs.asin4;
        this.productQuantity4 = url.parametrs.quantity4;
        break;
    }
  }

  deleteUrl(url) {
    console.log(url);
    this.AddedUrls.forEach((element, index: number) => {
      if (element.url === url.url) {
        this.AddedUrls.splice(index, 1);
      }
    });
  }


}
