import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-filters-modal',
  templateUrl: './filters-modal.component.html',
  styleUrls: ['./filters-modal.component.css']
})
export class FiltersModalComponent implements OnInit {
  @Input() public selectedFilters: any [];
  filterParametr: string;
  filterCondition: string;

  filterValue: string;


  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService) {

  }

  ngOnInit() {
    console.log(this.selectedFilters);
  }


  makeVerbose() {

    let verboseString = this.filterParametr + ' ';

    switch (this.filterCondition) {

      case 'greated':
        verboseString += ' > ';
        break;
      case 'greatedOrEqual':
        verboseString += ' >= ';
        break;
      case 'lessThan':
        verboseString += ' < ';
        break;
      case 'lessOrEqual':
        verboseString += ' <= ';
        break;
      default :
        verboseString += ' = ';
        break;

    }
    verboseString += ' ' + this.filterValue;
    return verboseString;


  }


  addFilterCondition() {
    if (this.canFilterConditionAdded()) {
      this.selectedFilters.push({
        filterParametr: this.filterParametr,
        filterCondition: this.filterCondition,
        filterValue: this.filterValue,
        locked: false,
        verbose: this.makeVerbose()
      });
    } else {
      this.toastr.warning('we cant add this filter. please try another');
    }

  }

  canFilterConditionAdded() {
    let parametrExist = false;
    let weCanAddCondition = true;
    this.selectedFilters.forEach(eachObj => {

      if (this.filterParametr === eachObj.filterParametr) {
        parametrExist = true;
        switch (eachObj.filterCondition) {
          case 'equals': {
            weCanAddCondition = false;
            break;
          }
          case 'greated': {
            const avaliableConditions = ['lessThan', 'lessOrEqual'];
            if (avaliableConditions.indexOf(this.filterCondition) === -1) {
              weCanAddCondition = false;
            } else {
              if (parseFloat(eachObj.filterValue) > parseFloat(this.filterValue)) {
                weCanAddCondition = false;
              } else {
                weCanAddCondition = true;
              }
            }
            break;
          }

          case 'greatedOrEqual': {
            const avaliableConditions = ['lessThan', 'lessOrEqual'];
            if (avaliableConditions.indexOf(this.filterCondition) === -1) {
              weCanAddCondition = false;
            } else {
              if (parseFloat(eachObj.filterValue) > parseFloat(this.filterValue)) {
                weCanAddCondition = false;
              } else {
                weCanAddCondition = true;
              }
            }
            break;
          }

          case 'lessThan': {
            const avaliableConditions = ['greated', 'greatedOrEqual'];
            if (avaliableConditions.indexOf(this.filterCondition) === -1) {
              weCanAddCondition = false;
            } else {
              if (parseFloat(eachObj.filterValue) < parseFloat(this.filterValue)) {
                weCanAddCondition = false;
              } else {
                weCanAddCondition = true;
              }
            }
            break;
          }

          case 'lessOrEqual': {
            const avaliableConditions = ['greated', 'greatedOrEqual'];
            if (avaliableConditions.indexOf(this.filterCondition) === -1) {
              weCanAddCondition = false;
            } else {
              if (parseFloat(eachObj.filterValue) < parseFloat(this.filterValue)) {
                weCanAddCondition = false;
              } else {
                weCanAddCondition = true;
              }

            }
            break;
          }
        }
      }
    });
    return weCanAddCondition;
  }


  SaveFilters() {
    this.activeModal.close(this.selectedFilters);
  }

  deleteFilter(item) {
    this.selectedFilters.splice(this.selectedFilters.indexOf(item), 1);
  }

}
