import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpUserEvent,
  HttpResponse, HttpProgressEvent, HttpHeaderResponse, HttpSentEvent
} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, map, switchMap, take} from 'rxjs/operators';
import {AuthService} from '../auth.service';
import {first} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  cachedRequest: Promise<any>;

  constructor(private authenticationService: AuthService) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt access_token if available
    const currentUser = this.authenticationService.currentUserValue;
    // console.log( currentUser)
    // let userInStorage = localStorage.getItem('currentUser');
    // console.log(userInStorage);
    if (currentUser && currentUser.accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.accessToken}`
        }
      });
    }

    return next.handle(request);

  }
}
