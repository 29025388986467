import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-visibility-filter-modal',
  templateUrl: './visibility-filter-modal.component.html',
  styleUrls: ['./visibility-filter-modal.component.css']
})
export class VisibilityFilterModalComponent implements OnInit {

  @Input() public allColumnsVis;
  @Input() public datatableInstance;
  @Input() public visibleColumns;

  // https://github.com/l-lin/angular-datatables/issues/1344

  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService) {
  }

  ngOnInit() {
  }

  ChangeVisability(columnId) {
    console.log('column id');
    const column = this.datatableInstance.column(columnId);
    column.visible(!column.visible());
    this.datatableInstance.draw();
    this.datatableInstance.columns.adjust();
    this.visibleColumns[columnId] = column.visible();


  }

  selectAllColumns() {
    this.allColumnsVis.forEach((element, index: number) => {

      const column = this.datatableInstance.column(element.id);
      column.visible(true);
      this.visibleColumns[element.id] = column.visible();
      this.allColumnsVis[index].visibility = true;
    });

    this.datatableInstance.draw();
    this.datatableInstance.columns.adjust();

  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }
  /*
    reverseKeyOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
      return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
    }

    valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
      return a.value.localeCompare(b.value);
    }
  */

}
