import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {DataTablesResponse} from '../models/datatableResponse';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-child-schedules-table',
  templateUrl: './child-schedules-table.component.html',
  styleUrls: ['./child-schedules-table.component.scss']
})
export class ChildSchedulesTableComponent implements OnInit {
  @Input() public campaignId;
  @Input() data: any[];
  @ViewChildren(DataTableDirective)
  private datatableElement: DataTableDirective;
  dtOptionsSchedules: DataTables.Settings = {};
  showDemoData: boolean = false;
  allSchedules: any = [];

  constructor(
    private toastr: ToastrService,
    public http: HttpClient
  ) {


    const url = '/api/ScheduleList/manage?t=' + Date.now();


    this.dtOptionsSchedules = {

      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [3, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {

        if (this.showDemoData === true) {


        } else {
          const postData = {...dataTablesParameters, ...this.postData()};

          this.http
            .post<DataTablesResponse>(
              url,
              postData
            ).subscribe(resp => {

            this.allSchedules = resp.data;

            console.log('data');
            console.log(resp);


            if (resp.redirect !== undefined && resp.redirect !== '') {
              console.log('we have redirect');
              return false;
            }


            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }
      },

      columns: [

        {data: 'stateHtml', name: 'stateHtml'},
        {data: 'TimeFrom', name: 'TimeFrom'},
        {data: 'TimeTo', name: 'TimeTo'},
        {data: 'targetAction', name: 'targetAction'},
        {data: 'curentState', name: 'curentState'},
        {data: 'action', name: 'action'},


      ],


      drawCallback: (settings) => {

      }
    };


  }

  ngOnInit() {
  }


  postData() {
    return {'campaignId': this.campaignId};
  }


  pauseSchedule(schedule) {
    console.log(schedule);

    const url = '/api/schedule/campaigns/pauseOne';
    const postData = {schedule};

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success('Updated');
          } else {
            this.toastr.warning('Error while update ' + result.msg);
          }
        }
      });
  }

  runSchedule(schedule) {
    console.log(schedule);

    const url = '/api/schedule/campaigns/runOne';
    const postData = {schedule};

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success('Updated');
          } else {
            this.toastr.warning('Error while update ' + result.msg);
          }
        }
      });
  }

  deleteSchedule(schedule) {
    console.log(schedule);
    const url = '/api/schedule/campaigns/DeleteOne';
    const postData = {schedule};
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success('Deleted');
          } else {
            this.toastr.warning('Error while update ' + result.msg);
          }
        }
      });
  }




}
