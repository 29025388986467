import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders} from '@angular/common/http';
import {Observable, throwError, from} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthService} from '../auth.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  cachedRequest: Promise<any>;

  constructor(private authenticationService: AuthService,
              private toastr: ToastrService,
              private router: Router
  ) {
  }

  /*
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError(err => {

        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          location.reload(true);
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      }));
    }
    */

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.addBearerToken(req, next));
  }


  private async addBearerToken(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {

    const currentUser = this.authenticationService.currentUserValue;
    // console.log(currentUser);
    let token = '';
    if (currentUser && currentUser.accessToken) {
      token = currentUser.accessToken;
    }

    const headerSettings: any = req.headers.keys().reduce(
      (acc, cur) => {
        acc[cur] = req.headers.getAll(cur);
        return acc;
      }, {});

    if (token) {
      headerSettings.Authorization = `Bearer ${token}`;
    } else {
      console.log('performing request without auth!');
    }
    // prevent 302 redirect to challenge on a 401
    headerSettings['X-Requested-With'] = 'XMLHttpRequest';
    const headers = new HttpHeaders(headerSettings);
    const newRequest = req.clone({headers});
    const result = next.handle(newRequest).toPromise();

    return result.catch(async (err) => {
      console.log(err);
      if (err.status === 401) {
        if (err.error.errorMsg !== undefined) {
          console.log(err.error.errorMsg);
          this.toastr.warning(err.error.errorMsg);
          if (err.error.errorMsg === 'User not found') {
            return next.handle(req).toPromise().then(data => {
              console.log('requeried data:', data); // <-- I also see this fire, with the valid data coming back from the second request
              return data; // <-- however the original caller doesn't get this data
            });
          }
        }

        if (err.error.redirect === 'Login') {
          console.log(' go to login');
          this.authenticationService.logout();
          this.router.navigate(['/Login']);
        } else {

          if (!this.cachedRequest) {
            this.cachedRequest = this.authenticationService.refreshToken();
          }
          const newToken = await this.cachedRequest;
          console.log(newToken);
          this.cachedRequest = null;
          headerSettings.Authorization = `Bearer ${newToken}`;
          const updatedHeaders = new HttpHeaders(headerSettings);
          const updatedRequest = req.clone({headers: updatedHeaders, url: 'https://jsonplaceholder.typicode.com/todos/1'});
          console.log('requery with new token');
          // <-- I see this when I have a 401, eg by altering the auth token to be bad, whilst leaving the refresh token alone
          return next.handle(updatedRequest).toPromise().then(data => {
            console.log('requeried data:', data); // <-- I also see this fire, with the valid data coming back from the second request
            return data; // <-- however the original caller doesn't get this data
          });
        }
      } else if (err.status === 500) {
        console.log(err.error);
        this.toastr.warning(err.error.message);
      }
    });

  }


}
