import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {SharedService2Service} from '../shared-service2.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';
import {DataTableDirective} from 'angular-datatables';
import {DataTablesResponse} from '../models/datatableResponse';
import {ChildDuplicatesTableComponent} from '../child-duplicates-table/child-duplicates-table.component';
import {ChildSchedulesTableComponent} from '../child-schedules-table/child-schedules-table.component';
import {Subject} from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss']
})
export class SchedulesComponent extends BaseComponent implements OnInit {

  onlyActive: boolean = true;

  dtOptionsCampaignListForDuplicates: DataTables.Settings = {};
  @ViewChildren(DataTableDirective) dtElements: any;
  @ViewChild(DataTableDirective, {static: false}) dtElementScedule: any;
  allCampaigns: any [];
  state: any = {};
  title: string;
  campaigns: any = {};
  private childRow: ComponentRef<ChildSchedulesTableComponent>;
  dtTrigger: Subject<any> = new Subject();


  dtOptionsDuplicates: DataTables.Settings = {};



  constructor(public dataShared: SharedService2Service,
              public modalService: NgbModal, private router: Router,
              private authenticationService: AuthService,
              public http: HttpClient, public toastr: ToastrService,
              private compFactory: ComponentFactoryResolver,
              private renderer: Renderer2,
              private viewRef: ViewContainerRef
  ) {
    super(dataShared, http, toastr, modalService);

    this.campaigns.r1 = 1;
    this.bids.r1 = 0;
    this.panel = {};
    this.showDropdown.r1 = 1;
    this.tmpBid.r1 = 1;
    this.edited.r1 = 1;
    this.neg.r1 = 1;
    this.negPending.r1 = 1;
    this.records.r1 = 1;
    this.toggleValue.r1 = true;
    this.state.r1 = 'enabled';
    this.bids.r1 = 0;
    this.panel = {};
    this.showDropdown.r1 = 1;
    this.tmpBid.r1 = 1;
    this.edited.r1 = 1;
    this.neg.r1 = 1;
    this.negPending.r1 = 1;
    this.records.r1 = 1;
    this.onlywithSchedules = true;
    this.title = 'Schedules';

    const url = '/api/CampaignList/manage_7?t=' + Date.now();

    this.dtOptionsCampaignListForDuplicates = {

      pagingType: 'full_numbers',
      processing: true,
      //  serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [4, 'desc'],
      autoWidth: false,
      info: false,
      columnDefs: [
        {targets: [0, 2, 3, 13], searchable: false},
        {targets: [0, 2, 3, 13], orderable: false},
      ],
    };

  }

  getCampaignSchedules() {

    const url = '/api/CampaignList/manage_7?t=' + Date.now();
    const postData = {...this.postdata()};
    let isFirst = true;
    if (this.allCampaigns !== undefined) {
      if (this.allCampaigns.length > 0) {
        isFirst = false;
      }
    }

    this.http
      .post<DataTablesResponse>(
        url,
        postData
      ).toPromise().then(resp => {

      this.allCampaigns = resp.data;

      console.log('data');
      console.log(resp);

      if (resp.redirect !== undefined && resp.redirect !== '') {
        console.log('we have redirect');
        return false;
      }

      if (resp.error === false || resp.error === undefined) {

      } else {
        this.toastr.warning(resp.msg);
      }

      resp.data.forEach((element, index: number) => {
        this.state[element.slug] = element.state;
        this.bids[element.slug] = element.bidOriginal;
      });
      this.hideLoadingBar();

      this.footerData = {
        Revenue: 0,
        Spend: 0,
        Orders: 0,
        ACOSSum: 0,
        ACOSAVG: 0,
        AmazonFees: 0,
        Impressions: 0,
        Clicks: 0,
        CTRSum: 0,
        CTRAvg: 0,
        CPCSum: 0,
        CPCAvg: 0,
        CVRSum: 0,
        CVRAvg: 0,
        Inventory: 0,
        TotalRecords: 0
      };

      resp.data.forEach((element, index: number) => {
        if (element.Level === 'Campaign') {
          this.footerData.Revenue += parseFloat(element.Revenue);
          this.footerData.Spend += parseFloat(element.ADSpend);
          this.footerData.Orders += parseInt(element.Orders, 10);
          this.footerData.ACOSSum += parseFloat(element.ACOS);
          this.footerData.AmazonFees += parseFloat(element.amazon_FBA_fees);
          this.footerData.Impressions += parseInt(element.Impressions, 10);
          this.footerData.Clicks += parseInt(element.Clicks, 10);
          this.footerData.CTRSum += parseFloat(element.CTR);
          this.footerData.CPCSum += parseFloat(element.CPC);
          this.footerData.CVRSum += parseFloat(element.CVR);
          this.footerData.TotalRecords += 1;
        }
      });

      this.prepareFooterData();


      /* if (isFirst) {
         this.dtTrigger.next();
       } else {*/
      /*  if(this.dtElement.dtInstance !== undefined){
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            //   dtInstance.init();
            //  dtInstance.clear();
            //   dtInstance.length =25;
            //   dtInstance.draw();
            this.dtTrigger.next();
          });
        }
   */


      if (isFirst) {
        if (this.dtElementScedule.dtInstance === undefined) {
          this.dtTrigger.next();
        }
      } else {
        this.dtElementScedule.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();

          this.dtTrigger.next();
        });
      }


      //  }


    });
  }

  updatePageData() {
  //  this.getCampaignSchedules();
  }

  showBiddingControls(campaignId) {

  }


  ShowSchedulesList(campaignId) {
    if (this.CampaignIdList.indexOf(campaignId) >= 0) {
      this.CampaignIdList.splice(this.CampaignIdList.indexOf(campaignId), 1);
    } else {
      this.CampaignIdList.push(campaignId);
    }
    this.updatePageData();

  }

  editSchedule(selectedItem) {

  }

  pauseSchedule(selectedItem) {

  }

  deleteSchedule(selectedItem) {

  }

  ngInitFinished() {
    this.showMainLayout();
    this.getCampaignSchedules();
  //  this.initDuplicatesTable();
  }

  expandRow(trRef, rowData) {
    console.log(trRef);
    console.log('expand row');


    this.dtElementScedule.dtInstance.then((dtInstance: DataTables.Api) => {


   const row = dtInstance.row(trRef);
      const rows = dtInstance.rows();
      console.log(rows);
      console.log(row);
      console.log(row.child.isShown());
      if (row.child.isShown()) {
        row.child.hide();
        this.renderer.removeClass(trRef, 'shown');
      } else {
        const factory = this.compFactory.resolveComponentFactory(ChildSchedulesTableComponent);
        this.childRow = this.viewRef.createComponent(factory);
        this.childRow.instance.data = [rowData];
        this.childRow.instance.campaignId = rowData.campaignId;
        // this.childRow
        row.child(this.childRow.location.nativeElement).show();
        this.renderer.addClass(trRef, 'shown');
      }


    });

  }






  ////////////////////////////////



  initDuplicatesTable() {
    this.allVisibleDuplicates = [
      true,
      true,
      true,
      true,
      false,
      true,
      false,
      false,
      true,
      false,
      true,
      true,
      true,
      true,
      false,
      true,
      true,
    ];

    if (this.dtOptionsDuplicates.pagingType === undefined) {

      this.dtOptionsDuplicates = {
        pagingType: 'full_numbers',
        processing: true,
        destroy: true,
        pageLength: parseInt(this.TableLength,10),
        //     lengthChange: false,
        //    serverSide: true,
        language: {
          processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
        },
        order: [3, 'desc'],
        columnDefs: [
          {targets: [0, 1, 2, 3, 4, 6, 9, 11, 12, 13, 14, 16, 17], visible: true},
          {targets: '_all', visible: false},
          {targets: [0, 1, 3, 4], searchable: false},
          {targets: [0, 1, 3, 4], orderable: false},

        ],
          autoWidth: false,
        info: false
      };
    }
  }








}
