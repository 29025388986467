import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {User} from './models/user';
import {catchError, map} from 'rxjs/operators';
import {SharedService2Service} from './shared-service2.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private ProfileRunning: boolean = false;

  constructor(private http: HttpClient, public dataShared: SharedService2Service, public toastr: ToastrService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {

    console.log('do login');
    if (email === 'demo' && password === 'demo') {
      return this.http.post<any>(`https://jsonplaceholder.typicode.com/posts`, {email, password}).toPromise().then(user => {
        const userObject = new User();
        userObject.helpersBlock = {};
        userObject.firstName = 'Demo';
        userObject.lastName = 'User';
        userObject.email = 'demo@gmail.com';
        userObject.CurrentAccountId = 0;
        userObject.CurrentAccount = 0;
        userObject.ReportTypes = 'all';
        userObject.AccountList = [];
        userObject.AccountId = '0_eng';
        userObject.Currency = 'USD';
        userObject.CurrencyCurentObject = {};
        userObject.CurrencyCurentId = 0;
        userObject.walkmeCompleted = true;
        userObject.DisableAutoloadData = false;
        userObject.TrialEnd = '0';
        userObject.currencyCode = '$';
        userObject.id = 0;
        userObject.ShowStartupModal = true;
        userObject.ShowWzardModal = true;
        userObject.ShowAutopilotModal = true;
        userObject.childRole = 0;
        userObject.countWarnings = 1;
        userObject.suggestions = 2;
        userObject.totalSuggestions = 3;
        userObject.totalAutopilot = 4;
        userObject.totalCM = 5;
        userObject.CangeMasterCounts = 6;
        userObject.show30DiscountLine = true;
        userObject.admin = false;
        userObject.helpersBlock = {};
        userObject.CurrentSubscription = 'demo';
        userObject.accessToken = 'demo';
        this.dataShared.updateUser(userObject);
        console.log(userObject);
        if (userObject && userObject.accessToken) {
          // store user details and jwt access_token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(userObject));
          this.currentUserSubject.next(userObject);

        }

      });
    } else {
      return this.http.post<any>(`/api/login_jwt`, {email, password}).toPromise()
        .then(user => {
          // login successful if there's a jwt access_token in the response
          console.log(user);
          if (user && user.accessToken) {
            // store user details and jwt access_token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            this.getProfile();
          }

          //   return user;
        }, (reason) => {
          console.log(reason);
        });
    }
  }

  authByToken(token) {

    const userObject = new User();
    userObject.helpersBlock = {};
    userObject.firstName = 'Demo';
    userObject.lastName = 'User';
    userObject.email = 'demo@gmail.com';
    userObject.CurrentAccountId = 0;
    userObject.CurrentAccount = 0;
    userObject.ReportTypes = 'all';
    userObject.AccountList = [];
    userObject.AccountId = '0_eng';
    userObject.Currency = 'USD';
    userObject.CurrencyCurentObject = {};
    userObject.CurrencyCurentId = 0;
    userObject.walkmeCompleted = true;
    userObject.DisableAutoloadData = false;
    userObject.TrialEnd = '0';
    userObject.currencyCode = '$';
    userObject.id = 0;
    userObject.ShowStartupModal = true;
    userObject.ShowWzardModal = true;
    userObject.ShowAutopilotModal = true;
    userObject.childRole = 0;
    userObject.countWarnings = 1;
    userObject.suggestions = 2;
    userObject.totalSuggestions = 3;
    userObject.totalAutopilot = 4;
    userObject.totalCM = 5;
    userObject.CangeMasterCounts = 6;
    userObject.show30DiscountLine = true;
    userObject.admin = false;
    userObject.helpersBlock = {};
    userObject.CurrentSubscription = 'demo';
    userObject.accessToken = token;
    localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.currentUserSubject.next(userObject);
    this.router.navigate(['/productList']);
    this.getProfile();
    console.log('navigated');
  }

  register(email: string, firstName: string, lasttName: string, password: string) {
    return this.http.post(`/api/register7`, {email, firstName, lasttName, password}).toPromise().then((result: any) => {
      console.log(result);
      if (result) {
        if (result.status === 'fail') {
          this.toastr.error(result.msg);
        } else {
          if (result.accessToken) {
            // store user details and jwt access_token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(result));
            this.currentUserSubject.next(result);
            this.getProfile();
          }
          this.toastr.success('Registration successful');
          this.router.navigate(['/productList']);
        }
      }
    }, (reason) => {
      console.log(reason);
    });

  }

  refreshToken() {
    return this.http.post<any>(`/api/refresh_jwt`, {}).toPromise();
  }


  logout() {
    // remove user from local storage to log user out
    console.log('logout service');
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return this.http.post(`/api/logout_jwt`, {});
  }

  recover(email) {
    const promise = new Promise<any>((resolve, reject) => {
      const postData = {email};
      this.http.post<any>('password/email', postData).toPromise()
        .then(result => {
          console.log('reg ok');
          console.log(result);
          resolve(result);
        }, msg => {
          console.log(msg);
          reject(msg);
        });
    });
    return promise;
  }


  getProfile() {
    /*
        if ($rootScope.user != undefined && $rootScope.user.id != undefined && $rootScope.CurrentAccount != undefined) {
          console.log('profile already present');
          return false;
        }
    */

    if (this.ProfileRunning === true) {
      return false;
    }
    const url = '/api/profile';

    this.http.get<any>(url).toPromise()
      .then(
        result => {
          if (result === undefined) {
            return false;
          }
          console.log(result);
          this.ProfileRunning = false;
          if (result.user === undefined) {
            console.log('not logined');
            return false;
          }
          if (result.user.id === 0) {
            console.log('not logined');
            return false;
          } else {
            console.log(result.user.id);
            const userObject = new User();
            userObject.helpersBlock = {};
            userObject.firstName = result.user.firstName;
            userObject.lastName = result.user.lastName;
            userObject.email = result.user.email;
            userObject.emailFull = result.user.emailFull;
            userObject.CurrentAccountId = result.currentAccountId.id;
            userObject.CurrentAccount = result.currentAccountId;
            userObject.ReportTypes = result.currentAccountId.ReportTypes;
            userObject.AccountList = result.AccountList;
            userObject.prorfolioList = result.prorfolioList;
            result.AccountList.forEach((element) => {
              if (element.id + '_' + element.country_coode === result.currentAccountId.sellerId) {
                userObject.AccountId = element.id + '_' + element.country_coode;
              }
            });
            userObject.Currency = result.Currency;
            userObject.CurrencyCurentObject = userObject.Currency[0];
            userObject.CurrencyCurentId = userObject.Currency[0].id;
            userObject.walkmeCompleted = result.user.walkmeCompleted;

            if (result.user.DisableAutoloadData === 0) {
              userObject.DisableAutoloadData = false;
            } else {
              userObject.DisableAutoloadData = true;

            }

            userObject.TrialEnd = result.user.TrialEnd;
            userObject.currencyCode = result.user.currencyCode;
            userObject.id = result.user.id;
            if (result.user.ShowStartupModal === 1) {
              userObject.ShowStartupModal = true;
            } else {
              userObject.ShowStartupModal = false;
            }
            if (result.user.ShowWzardModal === 1) {
              userObject.ShowWzardModal = true;
            } else {
              userObject.ShowWzardModal = false;
            }

            if (result.user.ShowAutopilotModal === 1) {
              userObject.ShowAutopilotModal = true;
            } else {
              userObject.ShowAutopilotModal = false;
            }

            userObject.childRole = result.user.childRole;

            userObject.countWarnings = result.countWarnings;
            userObject.suggestions = result.suggestions;
            userObject.totalSuggestions = result.totalSuggestions;
            userObject.totalAutopilot = result.totalAutopilot;
            userObject.totalCM = result.totalCM;
            userObject.CangeMasterCounts = result.CangeMasterCounts;
            userObject.show30DiscountLine = result.show30DiscountLine;

            // HelperService.autopilotSettings.TargetAcos = $rootScope.CurrentAccount.acos_zone;
            if (result.user.role === 'admin') {
              userObject.admin = true;
            } else {
              userObject.admin = false;
            }

            userObject.Currency.forEach((element) => {
              if (element.CurrencyCode === result.user.currencyCode) {
                userObject.CurrencyCurentObject = element;
                userObject.CurrencyCurentId = element.id;
              }
            });

            result.helpersBlockSettings.forEach((element) => {
              userObject.helpersBlock[element.section] = element.state;
            });


            if (result.subscription !== undefined) {
              userObject.CurrentSubscription = result.subscription;
            } else {
              userObject.CurrentSubscription = {};
            }

            if (result.redirectToAdditionalSetup === true) {
              /*         $timeout(function() {
                          $state.go('SettingsNewAccount', {subscriptionId: result.subscriptionId});
                        });
          */
            }

            this.dataShared.updateUser(userObject);

            if (result.user.role === 'cancelled' || result.user.role === 'trialEnd') {
              /*      $timeout(function() {
                      $state.go('CancelledAccount');
                    });
      */
            } else {
              return result.user;
            }
          }

        });

    // }
  }


}
