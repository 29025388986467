import {Component, Inject, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {SharedService2Service} from '../shared-service2.service';
import {BaseComponent} from '../base/base.component';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-customer-portal',
  templateUrl: './customer-portal.component.html',
  styleUrls: ['./customer-portal.component.css']
})
export class CustomerPortalComponent extends BaseComponent implements OnInit {

  customerPortal: string = '';

  constructor(@Inject(DOCUMENT) private document: Document,
              public modalService: NgbModal,
              public http: HttpClient, public toastr: ToastrService,
              public dataShared: SharedService2Service) {
    super(dataShared, http, toastr, modalService);
  }

  ngInitFinished() {
    const url = '/api/portal';
    this.http.get<any>(url).toPromise().then(
      result => {
        if (result.status === 1) {
          //   this.customerPortal = result.url;

          this.document.location.href = result.url;
        } else {
          this.toastr.warning(result.errors);
        }
      });

  }


}
