import {Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, Renderer2, ViewChildren, ViewContainerRef} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {DataTablesResponse} from '../models/datatableResponse';

@Component({
  selector: 'app-child-duplicates-table',
  templateUrl: './child-duplicates-table.component.html',
  styleUrls: ['./child-duplicates-table.component.scss']
})
export class ChildDuplicatesTableComponent implements OnInit {
  @ViewChildren(DataTableDirective) dtElements: any;
  @ViewChildren(DataTableDirective)
  private datatableElement: DataTableDirective;
  dtOptionsDuplicatesDetails: DataTables.Settings = {};
  allDuplicates: any[];
  panel: object;
  bids: any = {};

  showDemoData: boolean = false;
  @Input() public keywordId;
  @Input() data: any[];

  constructor(private toastr: ToastrService, public http: HttpClient) {
    this.bids.r1 = 0;
    this.panel = {};

    const url = '/api/suggestions/otherDuplicates?t=' + Date.now();

    if (location.host === 'localhost:4200') {
      this.showDemoData = true;
      console.log('running dev');
    }


    this.dtOptionsDuplicatesDetails = {

      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [3, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {

        if (this.showDemoData === true) {
          const str = '';
          const resp = JSON.parse(str);
          this.allDuplicates = resp.data;
          resp.data.forEach((element, index: number) => {
            this.bids[element.slug] = element.bidOriginal;
          });

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: resp.data
          });

        } else {
          const postData = {...dataTablesParameters, ...this.postData()};

          this.http
            .post<DataTablesResponse>(
              url,
              postData
            ).subscribe(resp => {

            this.allDuplicates = resp.data;

            console.log('data');
            console.log(resp);

            if (resp.redirect !== undefined && resp.redirect !== '') {
              console.log('we have redirect');
              return false;
            }

            if (resp.error === false || resp.error === undefined) {

            } else {
              this.toastr.warning(resp.msg);
            }
            resp.data.forEach((element, index: number) => {
              this.bids[element.slug] = element.bidOriginal;
            });
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }
      },

      columns: [

        {data: 'keywordText', name: 'keywordText'},
        {data: 'Bid', name: 'Bid'},
        {data: 'ACOS', name: 'ACOS'},
        {data: 'AdSpend', name: 'AdSpend'},
        {data: 'Revenue', name: 'Revenue'},
        {data: 'CPC', name: 'CPC'},
        {data: 'Orders', name: 'Orders'},
        {data: 'CampaignName', name: 'CampaignName'},
        {data: 'Action', name: 'Action'}

      ],


      drawCallback: (settings) => {

      }
    };


  }

  ngOnInit() {
  }


  showPanel(panelName) {
    console.log(panelName);
    this.panel[panelName] = true;
  }

  hidePanel(panelName) {
    this.panel[panelName] = false;
  }

  SaveBid(record, bidType = 1) {
    console.log('saveBid');
    console.log(record);
    this.panel[record.slug] = false;

    const url = '/api/keywordsBid/update';
    let recordId = 0;
    switch (bidType) {
      case 1:
        recordId = record.keywordId;
        break;
      case 2:
        recordId = record.targetId;
        break;
      case 3:
        recordId = record.adGroupId;
        break;
      case 4:
        recordId = record.targetId;
        break;
    }

    const postData = {keyword: recordId, newBid: this.bids[record.slug], bidType: bidType};
    this.http.post<any>(url, postData).toPromise().then(
      result => {
        console.log('From Promise:', result);
        if (result.error === false) {
          this.toastr.success(result.msg);
        } else {
          this.toastr.warning('Error while update keyword' + result.msg);
        }
      }
    );
  }

  postData() {
    return {
      'keywordId': this.keywordId
    };
  }

  isAcosRed(acos) {
    if (isNaN(acos)) {
      return null;
    } else {
      if (acos === 0) {
        return null;
      } else {
        const acosZone = 30;
        if (acos > acosZone) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  }

  pauseDuplicates(keywordId) {


    let postData = {};
    if (keywordId == null) {
      return false;
    } else {
      postData = {keyword: [keywordId]};
    }
    const url = '/api/keywordsAction/pause';
    this.http.post<any>(url, postData).toPromise().then(result => {

      if (result.error === false) {
        this.toastr.success(result.msg);

        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          if (dtElement.dtInstance !== undefined) {
            dtElement.dtInstance.then((dtInstance: any) => {
              console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
              //      this.TableLength = dtInstance.table().page.len();
              dtInstance.table().ajax.reload();
            });
          }
        });

      } else {

        this.toastr.warning('Error while update keyword' + result.msg);
      }
    });


  };


}
