import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {DataTableDirective} from 'angular-datatables';
import {SharedService2Service} from '../shared-service2.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {DataTablesResponse} from '../models/datatableResponse';


class DataTablesCampaignRow {
  productImage: string;
  id: number;
  campaignId: number;
  adGroupId?: number;
}

class DashboardGraphResponse {
  redirect: string;
  graphData: any[];
  Tick: {
    values,
    days
  };
}

declare var $: any;


@Component({
  selector: 'app-negative-keywords',
  templateUrl: './negative-keywords.component.html',
  styleUrls: ['./negative-keywords.component.scss']
})
export class NegativeKeywordsComponent extends BaseComponent implements OnInit {

  dtOptionsKeywordsAngular: DataTables.Settings = {};
  closeResult: string;
  SelectAll: any;
  showSearchTerms: boolean = false;
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;
  allKeywords: any[];
  private firstUpdateTableCall: boolean = true;
  selectAll: boolean = false;
  title: string = 'Negative keywords';
  activeIdString: string = 'targets';
  graphOptions;
  graphData;
  public graphDays;
  @Input() portfolioId: number = -1;


  constructor(
    public dataShared: SharedService2Service,
    public modalService: NgbModal,
    public http: HttpClient,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {


    super(dataShared, http, toastr, modalService);
    this.bids.r1 = 0;
    this.panel = {};
    this.showDropdown.r1 = 1;
    this.tmpBid.r1 = 1;
    this.edited.r1 = 1;
    this.neg.r1 = 1;
    this.negPending.r1 = 1;
    this.records.r1 = 1;
    this.listAvaliableFilters = ['adGroupId', 'campaignId', 'targetFilter', 'Clicks', 'Orders', 'CVR',
      'CPC', 'CTR', 'Impressions', 'Sales', 'ACOS', 'AdSpend'];

    this.graphOptions = {
      chart: {
        type: 'lineChart',
        height: 200,
        margin: {
          top: 20,
          right: 20,
          bottom: 50,
          left: 55
        },
        showValues: true,
        valueFormat(d) {
          return d3.format(',.2f')(d);
        },
        duration: 500,
        xAxis: {
          axisLabel: 'days',
          ticks: 5,
          tickFormat: (d) => {
            return d3.time.format('%-d %b')(new Date(this.graphDays[d]));
          }

        },
        yAxis: {
          axisLabel: 'value',
          axisLabelDistance: -10,
          tickFormat(d) {
            return d3.format('.2f')(d);
          }
        }
      }
    };


  }

  ngInitFinished() {
    this.showMainLayout();
    const url = '/api/negativeKeywordList?t=' + Date.now();

    this.dtOptionsKeywordsAngular = {

      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      lengthChange: false,
      pageLength: this.TableLength,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [1, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {


        const postData = {...dataTablesParameters, ...this.postdata()};
        this.http
          .post<DataTablesResponse>(
            url,
            postData
          ).subscribe(resp => {

          this.allKeywords = resp.data;
          this.totalData = resp.totalData;
          console.log('data');
          console.log(resp);


          this.AllAvaliableRecords = [];
          console.log('Get all keywords');

          this.footerData = {
            Revenue: 0,
            Spend: 0,
            Orders: 0,
            ACOSSum: 0,
            ACOSAVG: 0,
            AmazonFees: 0,
            Impressions: 0,
            Clicks: 0,
            CTRSum: 0,
            CTRAvg: 0,
            CPCSum: 0,
            CPCAvg: 0,
            CVRSum: 0,
            CVRAvg: 0,
            Inventory: 0,
            TotalRecords: 0
          };


          this.prepareFooterData();


          resp.data.forEach((element, index: number) => {
            if (element.Level === 'Target') {
              this.AllAvaliableRecords.push({
                slugCheckbox: element.slugCheckbox,
                keywordId: parseInt(element.keywordId, 10),
                targetId: parseInt(element.targetId, 10),
                NegativeKeywordId: parseInt(element.NegativeKeywordId, 10),
                keywordText: element.keywordText_origin,
                searchTerm: element.searchTerm_origin
              });
            }
            this.state[element.slug] = element.state;
            if (element.Level === 'Target' || element.Level === 'AdGroup' || element.Level === 'Campaign') {
              this.bids[element.slug] = element.bidOriginal;
            }

            this.neg[element.slugST] = element.neg;

          });
       //   this.updateNegatives();
          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }

          if (resp.error === false || resp.error === undefined) {

          } else {
            this.toastr.warning(resp.msg);
          }
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });

        });

      },
      columns: [
        {name: 'action', orderable: false},
        {data: 'Campaign', name: 'Target'},
        /*    {data: 'state', name: 'state', orderable: false},
       {data: 'bid', name: 'Bid', orderable: false},
         {data: 'Revenue', name: 'Revenue'},
         {data: 'ADSpend', name: 'Spend'},
         {data: 'Orders', name: 'Orders'},
         {data: 'ACOS', name: 'ACoS'},
         {data: 'suggested', name: 'Suggested', orderable: false},*/
        {data: 'isNegative', name: 'isNegative', orderable: false},
     /*   {data: 'Impressions', name: 'Impressions'},
        {data: 'Clicks', name: 'Clicks'},
        {data: 'CTR', name: 'CTR'},
        {data: 'CPC', name: 'CPC'},
        {data: 'CVR', name: 'CVR'},*/

      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {
        this.updatesInProgress = false;
        this.hideLoadingBar();
      }
    };

    console.log('STATE2');
    console.log(history.state);
    const campaignIdFilter = history.state.filterCampaignId;
    const campaignNameFilter = history.state.filterCampaignName;
    const adGroupIdFilter = history.state.filterAdGroupId;
    const adGroupNameFilter = history.state.filterAdGroupName;


    const keywordIdFilter = history.state.keywordId;
    const targetIdFilter = history.state.targetId;
    const keywordTextFilter = history.state.keywordText;

    const keywordId = history.state.keywordId;
    const targetId = history.state.targetId;
    const keywordText = history.state.keywordText;

    let mustUpdateFilterObject = false;

    if (campaignIdFilter !== undefined) {
      let filterExisted = false;
      this.selectedFilters.forEach((element) => {
        if (element.filterParametr === 'campaignId' && element.filterValue === campaignIdFilter) {
          filterExisted = true;
        }
      });
      if (!filterExisted) {
        this.selectedFilters.push({
          filterParametr: 'campaignId',
          filterCondition: 'equal',
          filterValue: campaignIdFilter,
          locked: false,
          verbose: '[CAMP] ' + campaignNameFilter
        });
        mustUpdateFilterObject = true;
      }
    }

    if (adGroupIdFilter !== undefined) {
      let filterExisted = false;
      this.selectedFilters.forEach((element) => {
        if (element.filterParametr === 'adGroupId' && element.filterValue === adGroupIdFilter) {
          filterExisted = true;
        }
      });
      if (!filterExisted) {
        this.selectedFilters.push({
          filterParametr: 'adGroupId',
          filterCondition: 'equal',
          filterValue: adGroupIdFilter,
          locked: false,
          verbose: '[ADG] ' + adGroupNameFilter
        });
        mustUpdateFilterObject = true;
      }
    }

    if ((keywordId !== undefined && keywordId !== null) || (targetId !== undefined && targetId !== null)) {
      let filterExisted = false;
      this.selectedFilters.forEach((element) => {
        if (element.filterParametr === 'targetFilter' && element.filterValue === keywordId + '|' + targetId) {
          filterExisted = true;
        }
      });
      if (!filterExisted) {
        this.selectedFilters.push({
          filterParametr: 'targetFilter',
          filterCondition: 'equal',
          filterValue: keywordId + '|' + targetId,
          locked: false,
          verbose: '[KW] ' + keywordText
        });
        mustUpdateFilterObject = true;
      }
    }

    if (mustUpdateFilterObject === true) {
      this.updateGlonbalObject();
    }

  }

  updatePageData() {

    this.selectAll = false;
    this.selectAllRecords();
    if (this.firstUpdateTableCall === true) {
      this.firstUpdateTableCall = false;
      if (this.dtElements !== undefined) {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          });
        });
      }
    } else {
      if (this.dtElements !== undefined) {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
            dtInstance.table().ajax.reload();
          });
        });
      }
    }

    this.getDashboardGraph();
    this.getTotalProfit();
  }

  selectAllRecords() {
    console.log('select all records');
    this.selectedRecords = [];
    if (this.allKeywords === undefined) {
      this.allKeywords = [];
    } else {
      this.allKeywords.forEach((element) => {
        if (this.selectAll === true) {
          this.recordCheckboxes[element.slug] = true;
          this.AddRecord(element);
        } else {
          this.recordCheckboxes[element.slug] = false;
        }
      });
    }
  }

  getDashboardGraph() {


    const url = '/api/NegativeKeywords/graph';
    this.http.post(url, this.postdata()).subscribe((params: DashboardGraphResponse) => {
      console.log('params');
      console.log(params);
      this.hideLoadingBar();
      if (params.redirect !== '') {
        this.router.navigate([params.redirect]);
      } else {
        this.graphData = [];
        this.graphDays = [];
        this.graphData = params.graphData;
        this.graphDays = params.Tick.days;
      }
    });

  }

  getTotalProfit() {
/*
    const url = '/api/calculateProfit';
    const postData = {...{section: 'negativeKeywords'}, ...this.postdata()};
    this.http
      .post<any>(
        url,
        postData
      ).toPromise().then(result => {
      console.log(result);

      if (result.error === false) {
        this.profitDownloaded = true;
        this.TotalProfit = result.profit.real;
        this.ProfitPPC = result.profit.ppc;
      }
    });
    */
  }


  archiveNegativeKeyword(keyword){
    console.log(keyword);
    const url = '/api/negatives/deleteByNegativeId';
    const postData = {
      records: [keyword.NegativeKeywordId],
    };

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        setTimeout(() => {
          this.ActionValueNegative = 'init';
        }, 100);
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
            this.updatePageData();
          } else {
            this.toastr.warning('Error while update keyword' + result.msg);

          }
        }
      });

  }

  archiveSelectedNegatives(){
    if (this.selectedRecords.length === 0) {
      this.toastr.warning('Please select records first');
      return false;
    }

    const url = '/api/negatives/deleteByNegativeId';
    var records = [];
    this.selectedRecords.forEach((element)=>{

      records.push(element.NegativeKeywordId);
    })

    const postData = {
       records: records,
    };

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        setTimeout(() => {
          this.ActionValueNegative = 'init';
        }, 100);
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
            this.updatePageData();
          } else {
            this.toastr.warning('Error while update keyword' + result.msg);

          }
        }
      });
  }


  AddRecord(record) {

    if (this.selectedRecords !== undefined) {
      let recordExisted = false;
      let indexExisted = 0;
      this.selectedRecords.forEach((element, index: number) => {
        if (element.NegativeKeywordId === record.NegativeKeywordId ) {
          recordExisted = true;
          indexExisted = index;
        }
      });

      if (!recordExisted) {
        this.selectedRecords.push({
          keywordId: record.keywordId,
          targetId: record.targetId,
          NegativeKeywordId: record.NegativeKeywordId,
          keywordText: record.keywordText_origin,
          searchTerm: record.searchTerm_origin,
          slug: record.slug,
          campaignId: record.campaignId,
          adGroupId: record.adGroupId
        });
      } else {
        this.selectedRecords.splice(indexExisted, 1);

      }
    } else {
      this.selectedRecords = [];
      this.selectedRecords.push({
        keywordId: record.keywordId,
        targetId: record.targetId,
        NegativeKeywordId: record.NegativeKeywordId,
        keywordText: record.keywordText,
        searchTerm: record.keywordText,
        slug: record.slug,
        campaignId: record.campaignId,
        adGroupId: record.adGroupId
      });
    }
  }




}
