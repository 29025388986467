import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {DataTableDirective} from 'angular-datatables';
import {DataTablesResponse} from '../models/datatableResponse';

@Component({
  selector: 'app-tracking-details-modal',
  templateUrl: './tracking-details-modal.component.html',
  styleUrls: ['./tracking-details-modal.component.css']
})
export class TrackingDetailsModalComponent implements OnInit {

  @Input() public parentAsin;
  @Input() public dateRange;
  @Input() public selectedFilters;
  SearchINTableProducts: string;
  TableLength: number = 10;
  @ViewChildren(DataTableDirective)
  dtOptionsTrackingListModal: DataTables.Settings = {};
  allSkus: any = [];
  public chartLineData: any = {};
  public chartLineOptions;
  panel: object;
  bids: any = {};

  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, public http: HttpClient) {
    this.bids.r1 = 0;
    this.panel = {};

    this.chartLineOptions = {
      xkey: 'x',
      ykeys: ['a'],
      labels: ['position'],
      resize: true,
      redraw: true,
      smooth: false,
      axes: false,
      grid: false,
      hideHover: 'always',
      lineWidth: 1,
      pointSize: 2,
      xLabels: 'day',
      yLabelFormat(y) {
        if (y % 1 === 0) {
          return -y;
        } else {
          return '';
        }
      }


    };
    this.chartLineData.r1 = true;
    const url = '/api/keywordsTrackPositionList';
    this.dtOptionsTrackingListModal = {

      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [3, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {
        //    console.log(dataTablesParameters);
        const postData = {...dataTablesParameters, ...this.postdataTracking()};

        this.http
          .post<DataTablesResponse>(
            url,
            postData
          ).subscribe(resp => {

          this.allSkus = resp.data;

          console.log('data');
          console.log(resp);

          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }

          if (resp.error === false || resp.error === undefined) {

          } else {
            this.toastr.warning(resp.msg);
          }


          resp.data.forEach((element, index: number) => {
            this.chartLineData[element.keywordsTrackingId] = element.positionArray;
            this.bids[element.slug] = element.bidOriginal;
          });


          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },


      columns: [
        {data: 'expand', name: 'expand'},

        {data: 'ACOS', name: 'ACOS'},
        {data: 'TotalSpend', name: 'TotalSpend'},
        {data: 'CPC', name: 'CPC'},
        {data: 'Orders', name: 'Orders'},

        {data: 'keywordText', name: 'keywordText'},
        {data: 'keywordVolume', name: 'keywordVolume'},
        {data: 'keywordCompetition', name: 'keywordCompetition'},
        {data: 'Log', name: 'Log'},
        {data: 'Bid', name: 'Bid'},
        {data: 'AdSpend', name: 'AdSpend'},
        {data: 'Revenue', name: 'Revenue'},
        {data: 'AveCPC', name: 'AveCPC'},
        {data: 'Orders', name: 'Orders'},
        {data: 'matchType', name: 'matchType'},
        {data: 'variation_asin_sku', name: 'variation_asin_sku'},
        {data: 'CampaignName', name: 'CampaignName'},
        {data: 'position', name: 'position'},
        {data: 'oneDayAgo', name: 'oneDayAgo'},
        {data: 'sevenDayAgo', name: 'sevenDayAgo'},
        {data: 'Action', name: 'Action'}
      ],


      drawCallback: (settings) => {

      }
    };


  }

  ngOnInit() {

    console.log('INIT');
  }

  searchIndatatables(event) {

  }

  changePageLength(event) {

  }

  postdataTracking() {
    return {
      startDate: this.dateRange.startDate.format('YYYY-MM-DD'),
      endDate: this.dateRange.endDate.format('YYYY-MM-DD'),
      filter: this.selectedFilters,
      parentAsin: this.parentAsin


    };
  }


  showPanel(panelName) {
    console.log(panelName);
    this.panel[panelName] = true;
  }

  hidePanel(panelName) {
    this.panel[panelName] = false;
  }

  SaveBid(record, bidType = 1) {
    console.log('saveBid');
    console.log(record);
    this.panel[record.slug] = false;

    const url = '/api/keywordsBid/update';
    let recordId = 0;
    switch (bidType) {
      case 1:
        recordId = record.keywordId;
        break;
      case 2:
        recordId = record.targetId;
        break;
      case 3:
        recordId = record.adGroupId;
        break;
      case 4:
        recordId = record.targetId;
        break;
    }

    const postData = {keyword: recordId, newBid: this.bids[record.slug], bidType: bidType};
    this.http.post<any>(url, postData).toPromise().then(
      result => {
        console.log('From Promise:', result);
        if (result.error === false) {
          this.toastr.success(result.msg);
        } else {
          this.toastr.warning('Error while update keyword' + result.msg);
        }
      }
    );
  }

  ReturnDefault(record) {


  }
}
