import {Component, OnInit} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {SharedService2Service} from '../shared-service2.service';
import {BaseComponent} from '../base/base.component';
import {WizardModalComponent} from '../wizard-modal/wizard-modal.component';
import {SettingsAddMwsComponent} from '../settings-add-mws/settings-add-mws.component';
import {User} from '../models/user';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends BaseComponent implements OnInit {

  PageTab: string = 'account';
  params: any;
  allAvaliableTariffs: any [];
  user: any;
  ReportFileLink: string = '';
  notinserted: number = 0;
  ClickingShield: any;
  uploader: any;
  title: string = 'Settings';
  currentUser: User;

  constructor(public modalService: NgbModal,
              public http: HttpClient, public toastr: ToastrService,
              private authenticationService: AuthService,
              public dataShared: SharedService2Service) {
    super(dataShared, http, toastr, modalService);
    this.showMainLayout();
    this.params = {
      CurentAccount: {
        sellerId: '',
        accountName: '',
        mwsToken: '',
        profitMargins: '',
        acosZone: '',
        subscription: ''
      }

    };
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    this.user = {
      DisableAutoloadData: false
    };
    this.ClickingShield = {
      adspend: 1,
      enabled: false
    };


    this.hideLoadingBar();
    this.dataShared.currentUser.subscribe(currentUser => {
      console.log('shared current user =');
      console.log(currentUser);
      this.currentUser = currentUser;
      if (currentUser !== undefined) {
        if (currentUser.CurrentAccount !== undefined) {
          this.params.CurentAccount = currentUser.CurrentAccount;
          this.user = currentUser;
        }

      }

    });

  }


  saveAccount(account) {
    console.log(account);

    const url = '/api/settings/account/saveName';
    this.http.post<any>(url, {sellerId: account.sellerId, accountName: account.accountName}).toPromise().then(
      result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          this.toastr.success(result.msg);
        }
      });


  }

  deleteAccount(account) {
    console.log(account);

    const url = '/api/settings/account/delete';
    this.http.post<any>(url, {sellerId: account.sellerId }).toPromise().then(
      result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          this.toastr.success(result.msg);
          this.authenticationService.getProfile();
        }
      });

  }

  updatePageData() {
    const url = '/api/settings';
    this.http.get<any>(url).toPromise().then(
      result => {
        if (result.error === false) {
          this.params = result;
        } else {
          this.toastr.warning('Error while update keyword' + result.msg);
        }
        this.hideLoadingBar();
      }
    );
  }


  CreateNewAccount() {
    const modalRef = this.modalService.open(SettingsAddMwsComponent, {size: 'xl'});

    modalRef.result.then((result) => {
      console.log(result);
      if (result) {
        console.log(result);

      }
    }, (reason) => {
      console.log(reason);
      if (reason === ModalDismissReasons.ESC) {
        console.log('by pressing ESC');
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        console.log('by clicking on a backdrop');
      } else {
        console.log(`with: ${reason}`);
      }
    });

  }

  SaveMarginAcos() {
    const url = '/api/settings/account/marginacos';
    this.http.post<any>(url, this.params.CurentAccount).toPromise().then(
      result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          this.toastr.success(result.msg);
          if (result.subscriptionChanged === true) {
            //      $state.go('SKUList', {'selectSku': true});
          }
        }
      });

    const urlAutoload = '/api/settings/account/settings';
    this.http.post<any>(url, {DisableAutoloadData: this.user.DisableAutoloadData}).toPromise().then(
      result => {

      });
  }


  UploadReportPPCscope() {

  }

  UploadReport() {

  }

  saveShield(clickingShield) {

  }
}
