import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {first} from 'rxjs/operators';
import {BaseComponent} from '../base/base.component';
import {SharedService2Service} from '../shared-service2.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient, HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent implements OnInit {

  email: string;
  tariffs: any [];
  myTarif: string;
  agree: boolean = false;
  loading: boolean = false;
  submitted: boolean = false;
  bodyLayout = 'auth';
  private myClientId: string = 'amzn1.application-oa2-client.e360d62d47e14817b2bafb150553f45d';
  private redirectUrl: string = 'https://app.ppc.tools/amazonReg';
  firstName: string;
  lasttName: string;
  password: string;

  constructor(
    private route: ActivatedRoute,
    public dataShared: SharedService2Service,
    private router: Router,
    public modalService: NgbModal,
    public http: HttpClient,
    private authenticationService: AuthService,
    public toastr: ToastrService,
  ) {
    super(dataShared, http, toastr, modalService);
    // redirect to home if already logged in
    this.email = '';
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
    this.showAuthLayout();
  }

  ngOnInit() {
    console.log('init register');
    this.hideLoadingBar();
    /*   this.route.paramMap.subscribe(params => {
         console.log(params);
         this.email =params['email'];
       })
   */
    this.route.queryParams.subscribe(params => {
      if (params.email !== undefined && params.email !== '') {
        this.email = params.email;
      }

    });
    /*
        const url = window.location.href;
        let paramValue;
        if (url.includes('?')) {
          const httpParams = new HttpParams({ fromString: url.split('?')[1] });
          console.log(httpParams);
          paramValue = httpParams.get('email');
        }
        console.log( paramValue);
    */


  }

  Doregister() {


    if (this.firstName === '' || this.firstName === undefined) {
      this.toastr.warning('Please enter your first name');
      return false;
    }

    if (this.lasttName === '' || this.lasttName === undefined) {
      this.toastr.warning('Please enter your last name');
      return false;
    }

    if (this.email === '' || this.email === undefined) {
      this.toastr.warning('Please enter your email');
      return false;
    }

    if (this.password === '' || this.password === undefined) {
      this.toastr.warning('Please enter your password');
      return false;
    }


    this.submitted = true;
    this.loading = true;
    this.authenticationService.register(this.email, this.firstName, this.lasttName, this.password).then(result => {

        this.loading = false;
      }
    );

    /*
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);


        },
        error => {
          this.toastr.warning(error);

        });*/
  }

}
