import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DaterangepickerModule} from 'angular-2-daterangepicker';
import {DataTablesModule} from 'angular-datatables';
import {NvD3Module} from 'ng2-nvd3';
import {MorrisJsModule} from 'angular-morris-js';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AmazonLoginComponent} from 'angular-amazon-login';

// d3 and nvd3 should be included somewhere
import 'd3';
import 'nvd3';
import {RouterModule} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ProductListComponent} from './product-list/product-list.component';
import {BaseComponent} from './base/base.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {ReselectSkuModalComponent} from './reselect-sku-modal/reselect-sku-modal.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FiltersModalComponent} from './filters-modal/filters-modal.component';
import {CampaignsListComponent} from './campaigns-list/campaigns-list.component';
import {AdgroupListComponent} from './adgroup-list/adgroup-list.component';
import {KeywordToolsComponent} from './keyword-tools/keyword-tools.component';
import {SearchtermToolsComponent} from './searchterm-tools/searchterm-tools.component';
import {SharedService2Service} from './shared-service2.service';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor} from './helpers/error.interceptor';
import {JwtInterceptor} from './helpers/jwt.interceptor';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {AuthGuard} from './auth.guard';
import {SuggestionsComponent} from './suggestions/suggestions.component';
import {PpcWizardComponent} from './ppc-wizard/ppc-wizard.component';
import {TrackingComponent} from './tracking/tracking.component';
import {SchedulesComponent} from './schedules/schedules.component';
import {ProfileComponent} from './profile/profile.component';
import {SettingsComponent} from './settings/settings.component';
import {CustomerPortalComponent} from './customer-portal/customer-portal.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {AddChildUserComponent} from './add-child-user/add-child-user.component';
import {ChangeManagerComponent} from './change-manager/change-manager.component';
import {WizardModalComponent} from './wizard-modal/wizard-modal.component';
import {SkulistModalComponent} from './skulist-modal/skulist-modal.component';
import {AllProfitMarginsModalComponent} from './all-profit-margins-modal/all-profit-margins-modal.component';
import {VisibilityFilterModalComponent} from './visibility-filter-modal/visibility-filter-modal.component';
import {ConfirmationModalComponent} from './confirmation-modal/confirmation-modal.component';
import {ScheduleModalComponent} from './schedule-modal/schedule-modal.component';
import {ScheduleListModalComponent} from './schedule-list-modal/schedule-list-modal.component';
import {AddToNegativeModalComponent} from './add-to-negative-modal/add-to-negative-modal.component';
import {AddKeywordsToCampaignsModalComponent} from './add-keywords-to-campaigns-modal/add-keywords-to-campaigns-modal.component';
import {TrackingDetailsModalComponent} from './tracking-details-modal/tracking-details-modal.component';
import {RecoverPasswordComponent} from './recover-password/recover-password.component';
import {FileSelectDirective, FileUploadModule} from 'ng2-file-upload';
import {MatButtonModule, MatCardModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatSelectModule} from '@angular/material';
import {InlineSVGModule} from 'ng-inline-svg';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MatIconModule} from '@angular/material';
import {SetupAccountComponent} from './setup-account/setup-account.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material';
import {MatProgressBarModule} from '@angular/material';
import {AutopilotComponent} from './autopilot/autopilot.component';
import {AutopilotModalComponent} from './autopilot-modal/autopilot-modal.component';
import {TrackiChildTableComponent} from './tracki-child-table/tracki-child-table.component';
import {TrackingChild2TableComponent} from './tracking-child2-table/tracking-child2-table.component';
import {ChildDuplicatesTableComponent} from './child-duplicates-table/child-duplicates-table.component';
import {AccountSetupComponent} from './account-setup/account-setup.component';
import {CancelledPageComponent} from './cancelled-page/cancelled-page.component';
import {ChildSchedulesTableComponent} from './child-schedules-table/child-schedules-table.component';
import {FBWizardComponent} from './fbwizard/fbwizard.component';
import {FBWizardModalComponent} from './fbwizard-modal/fbwizard-modal.component';

import {ConnectFacebookComponent} from './connect-facebook/connect-facebook.component';
import {MainComponentComponent} from './main-component/main-component.component';
import { Tracking2Component } from './tracking2/tracking2.component';
import {LightboxModule} from 'ngx-lightbox';
import { FBWizardNewComponent } from './fbwizard-new/fbwizard-new.component';
import { SettingsAddMwsComponent } from './settings-add-mws/settings-add-mws.component';
import { NegativeKeywordsComponent } from './negative-keywords/negative-keywords.component';
import { KeywordSettingsModalComponent } from './keyword-settings-modal/keyword-settings-modal.component';

// import { createPopper } from '@popperjs/core';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ProductListComponent,
    BaseComponent,
    ReselectSkuModalComponent,
    FiltersModalComponent,
    CampaignsListComponent,
    AdgroupListComponent,
    KeywordToolsComponent,
    SearchtermToolsComponent,
    LoginComponent,
    RegisterComponent,
    SuggestionsComponent,
    PpcWizardComponent,
    TrackingComponent,
    SchedulesComponent,
    ProfileComponent,
    SettingsComponent,
    CustomerPortalComponent,
    ContactUsComponent,
    AddChildUserComponent,
    ChangeManagerComponent,
    WizardModalComponent,
    SkulistModalComponent,
    AllProfitMarginsModalComponent,
    VisibilityFilterModalComponent,
    ConfirmationModalComponent,
    ScheduleModalComponent,
    ScheduleListModalComponent,
    AddToNegativeModalComponent,
    AddKeywordsToCampaignsModalComponent,
    TrackingDetailsModalComponent,
    RecoverPasswordComponent,
    FileSelectDirective,
    SetupAccountComponent,
    AutopilotComponent,
    AutopilotModalComponent,
    TrackiChildTableComponent,
    TrackingChild2TableComponent,
    ChildDuplicatesTableComponent,
    AccountSetupComponent,
    AmazonLoginComponent,
    CancelledPageComponent,
    ChildSchedulesTableComponent,
    FBWizardComponent,
    FBWizardModalComponent,
    ConnectFacebookComponent,
    MainComponentComponent,
    Tracking2Component,
    FBWizardNewComponent,
    SettingsAddMwsComponent,
    NegativeKeywordsComponent,
    KeywordSettingsModalComponent
    //   AmazonLoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DaterangepickerModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    NgSelectModule,
    HttpClientModule,
    LightboxModule,
    NvD3Module,
    MatCardModule,
    InlineSVGModule.forRoot(),
    PerfectScrollbarModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    NgbModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxDaterangepickerMd.forRoot(),
    MorrisJsModule,
    RouterModule.forRoot([


      //   {path: '', redirectTo: '/Login', pathMatch: 'full'},
      {path: 'Login', component: LoginComponent},
      {path: 'Logout', component: LoginComponent},
      {path: 'register', component: RegisterComponent},
      {path: 'recover', component: RecoverPasswordComponent},

      {
        path: '', component: MainComponentComponent,
        children: [
          {path: 'SettingsNewAccount', component: AccountSetupComponent},
          {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
          {path: 'productList', component: ProductListComponent},
          {path: 'campaignsList', component: CampaignsListComponent},
          {path: 'keywordTools', component: KeywordToolsComponent},
          {path: 'searchtermTools', component: SearchtermToolsComponent},
          {path: 'negativeKeywords', component: NegativeKeywordsComponent},
          {path: 'adGroupList', component: AdgroupListComponent},
          {path: 'UpgradeTariff', component: ProductListComponent},
          {path: 'suggestions', component: SuggestionsComponent},
          {path: 'wizard', component: PpcWizardComponent},
          {path: 'tracking', component: TrackingComponent},
          {path: 'tracking2', component: Tracking2Component},

          {path: 'schedules', component: SchedulesComponent},
          {path: 'profile', component: ProfileComponent},
          {path: 'settings', component: SettingsComponent},
          {path: 'customerPortal', component: CustomerPortalComponent},
          {path: 'contactUs', component: ContactUsComponent},
          {path: 'addChildAccount', component: AddChildUserComponent},
          {path: 'changeMaster', component: ChangeManagerComponent},
          {path: 'SetupAccount', component: SetupAccountComponent},
          {path: 'autopilot', component: AutopilotComponent},
          {path: 'cancelledaccount', component: CancelledPageComponent},
          {path: 'fbwizard', component: FBWizardComponent},
          {path: 'fbwizardNew', component: FBWizardNewComponent},


        ]
      }


    ])
  ],
  providers: [SharedService2Service,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: Window, useValue: window}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ReselectSkuModalComponent, FiltersModalComponent, WizardModalComponent, SkulistModalComponent, AutopilotModalComponent,
    AllProfitMarginsModalComponent, VisibilityFilterModalComponent, ConfirmationModalComponent, ScheduleModalComponent,
    ScheduleListModalComponent, AddToNegativeModalComponent, AddKeywordsToCampaignsModalComponent, TrackingDetailsModalComponent,
    TrackiChildTableComponent, TrackingChild2TableComponent, ChildDuplicatesTableComponent, ChildSchedulesTableComponent, FBWizardModalComponent,
    ConnectFacebookComponent, SettingsAddMwsComponent, KeywordSettingsModalComponent
  ]
})
export class AppModule {
}
