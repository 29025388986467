import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-cancelled-page',
  templateUrl: './cancelled-page.component.html',
  styleUrls: ['./cancelled-page.component.scss']
})
export class CancelledPageComponent implements OnInit {

  user: any;

  constructor() {
  }

  ngOnInit() {
  }

}
