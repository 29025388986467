import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../models/user';
import {DOCUMENT, Location} from '@angular/common';
import {NgSelectConfig} from '@ng-select/ng-select';
import {SharedService2Service} from '../shared-service2.service';
import {NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingBarService} from '@ngx-loading-bar/core';
import * as moment from 'moment';
import {BaseComponent} from '../base/base.component';

declare let d3: any;

@Component({
  selector: 'app-main-component',
  templateUrl: './main-component.component.html',
  styleUrls: ['./main-component.component.scss']
})
export class MainComponentComponent extends BaseComponent implements OnInit {

  title = 'ppc-tools';
  options;
  data;
  message: string;
  currentUser: User;
  today: number = Date.now();

  chartLineData = [
    {y: '2006', a: 100, b: 90},
    {y: '2007', a: 75, b: 65},
    {y: '2008', a: 50, b: 40},
    {y: '2009', a: 75, b: 65},
    {y: '2010', a: 50, b: 40},
    {y: '2011', a: 75, b: 65},
    {y: '2012', a: 100, b: 90}
  ];

  chartLineOptions = {
    xkey: 'y',
    ykeys: ['a', 'b'],
    labels: ['Series A', 'Series B']
  };
  selfLayout: string;
  asideDisplay: boolean;
  toggleOptions: any;
  fluid: boolean;
  rootArrowEnabled: boolean;
  clear: boolean;
  location: Location;

  constructor(private config: NgSelectConfig, public dataShared: SharedService2Service,
              private router: Router,
              public http: HttpClient,
              private authenticationService: AuthService,
              public toastr: ToastrService,
              public modalService: NgbModal,
              public loader: LoadingBarService,
              location: Location,
              @Inject(DOCUMENT) private document: Document
  ) {
    super(dataShared, http, toastr, modalService);


    console.log('construct MAIN');
    console.log(this.router.url);
    console.log(this.authenticationService.currentUserValue);

    if (this.authenticationService.currentUserValue) {
      if (this.router.url === '' || this.router.url === '/') {
        this.router.navigate(['/productList']);
      }
    } else {
      this.router.navigate(['/Login']);
    }


    this.selfLayout = '';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
      }
    });


    // this.currentUser = new User();
    this.TableLength = 10;
    this.config.notFoundText = 'Custom not found';
    console.log('APP COMPONENT1');

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.authenticationService.getProfile();

    this.options = {
      chart: {
        type: 'discreteBarChart',
        height: 450,
        margin: {
          top: 20,
          right: 20,
          bottom: 50,
          left: 55
        },
        x(d) {
          return d.label;
        },
        y(d) {
          return d.value;
        },
        showValues: true,
        valueFormat(d) {
          return d3.format(',.4f')(d);
        },
        duration: 500,
        xAxis: {
          axisLabel: 'X Axis'
        },
        yAxis: {
          axisLabel: 'Y Axis',
          axisLabelDistance: -10
        }
      }
    };
    this.data = [
      {
        key: 'Cumulative Return',
        values: [
          {
            label: 'A',
            value: -29.765957771107
          },
          {
            label: 'B',
            value: 0
          },
          {
            label: 'C',
            value: 32.807804682612
          },
          {
            label: 'D',
            value: 196.45946739256
          },
          {
            label: 'E',
            value: 0.19434030906893
          },
          {
            label: 'F',
            value: -98.079782601442
          },
          {
            label: 'G',
            value: -13.925743130903
          },
          {
            label: 'H',
            value: -5.1387322875705
          }
        ]
      }
    ];


  }


  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/Login']);
  }

  rangeSelected(event) {
    console.log(event);
    console.log(event.start.format('MMM Do YY'));
  }

  ngInitFinished() {
    console.log('init base');

    this.myValueSub = this.dataShared.currentMessage.subscribe(globalSharedObject => {
      if (globalSharedObject !== undefined) {
        console.log(globalSharedObject);
        if (globalSharedObject.dateRange === undefined) {
          this.dateRange = {
            startDate: moment().subtract(5, 'days'),
            endDate: moment().subtract(1, 'days')
          };
          console.log('update date object in app component');
        } else {
          if (this.dateRange === undefined) {
            this.dateRange = globalSharedObject.dateRange;
          } else {
            if (globalSharedObject.dateRange.startDate !== null && globalSharedObject.dateRange.endDate !== null) {
              if ((this.dateRange.startDate.format('YYYY-MM-DD') !== globalSharedObject.dateRange.startDate.format('YYYY-MM-DD'))
                || (this.dateRange.endDate.format('YYYY-MM-DD') !== globalSharedObject.dateRange.endDate.format('YYYY-MM-DD'))) {
                this.dateRange = globalSharedObject.dateRange;
                console.log('dates changed need update');
              }
            }
          }
        }
        this.campaignType = globalSharedObject.campaignType;
        this.portfolioId = globalSharedObject.portfolioId;
        this.bodyLayout = globalSharedObject.bodyLayout;
        this.selectedFilters = globalSharedObject.selectedFilters;

      }
      this.LoadingInProgress = globalSharedObject.LoadingInProgress;
    });

    this.dataShared.currentUser.subscribe(currentUser => {
      console.log('shared current user =');
      console.log(currentUser);
      this.currentUser = currentUser;

    });


  }


  syncAmazonData() {
    console.log('Start sync process');
    const url = '/api/settings/account/startSync';
    this.http.post<any>(url, {}).toPromise().then(
      result => {
        if (result.error === false) {
          this.toastr.success(result.msg);
          this.currentUser.CurrentAccount.syncInProgress = 1;
        } else {
          this.toastr.warning('Error while update keyword' + result.msg);
        }
      }
    );

  }

  addCoupon(couponCode) {

    const url = '/api/applyCoupon';

    this.http.post<any>(url, {coupon: couponCode}).toPromise()
      .then(result => {

        if (result.error === true) {
          this.toastr.warning(result.msg);
          return false;
        } else {
          this.toastr.success('Discount coupon added. Please add your payment details');

          const urlPortal = '/api/portal';
          this.http.get<any>(urlPortal).toPromise().then(
            resultPortal => {
              if (resultPortal.status === 1) {
                this.document.location.href = resultPortal.url;
              } else {
                this.toastr.warning(resultPortal.errors);
              }
            });


        }
      });


  }


  getSyncProgress() {

    this.http.get<any>('/api/settings/account/Sync').toPromise()
      .then(
        result => {
          if (result.error === false) {
            this.currentUser.SyncProgressPercent = result.syncJobs.totalProgress + ' %';
            if (result.syncJobs.totalProgress === 100) {
              this.currentUser.CurrentAccount.syncInProgress = 0;
              this.toastr.success('Sync finished');
            }
          }
        });
  }

  getItemAttrSubmenuToggle(item) {

  }

  getItemCssClasses(item) {

  }

  getItemMenuSubmenuClass(item) {

  }

  isAnaliticsPage() {
    const analiticsRoutes = [
      '/adGroupList', '/dashboard', '/productList', '/campaignsList',
      '/keywordTools', '/searchtermTools'];
    if (analiticsRoutes.indexOf(this.router.url) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  AddPaymentDetails() {
    console.log('click');
    console.log('click');
    this.http.get<any>('/api/updatePayments').toPromise()
      .then(result => {
        console.log(result);
        if (result.status === 1) {
          this.document.location.href = result.url;
        } else {
          this.toastr.warning(result.errors);
        }

      });

  }

}
