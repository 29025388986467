import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-autopilot-modal',
  templateUrl: './autopilot-modal.component.html',
  styleUrls: ['./autopilot-modal.component.scss']
})
export class AutopilotModalComponent implements OnInit {

  @Input() selectedRecords;
  AutopilotLevel: string;
  AutopilotTarget: string;
  autopilotSettings: any = {};
  selectedTab: string = 'intro';
  ShowAutopilotModal: boolean = true;


  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, private http: HttpClient) {

    this.autopilotSettings = {
      TargetAcos: 30,
      MaxBid: 3,
      minClicks: 20,
      pauseClicks: 100,
      starterBid: 0.2,
      MinSuggested: true,
      StarterBidMinimumRange: true,
      SmoothAuto: false,
      Suggested: false,
      SuggestedRange: false,
      negative: {
        zeroSale: {
          maxClicks: 30,
          maxBid: 2.5
        },
        lowPotential: {
          maxImpressions: 35,
          minCTR: 0.3,
          maxBid: 2.5
        }
      },
      PlacementTop: 50,
      placementProductPage: '',
      enabled: true
    };


  }

  ngOnInit() {
  }

  SaveKeywords() {
    const url = '/api/autopilot/autoSetupCampaigns';
    const data = {
      element: 'campaign',
      campaigns: this.selectedRecords,
      parametrs: this.autopilotSettings,
      action: 'setup'
    };
    this.http.post<any>(url, data).toPromise()
      .then(result => {
        if (result.error === false) {
          this.toastr.success(result.msg, 'Updated', {enableHtml: true});
          this.activeModal.dismiss('success');
        } else {
          this.toastr.warning(result.msg);
        }
      });

  }
}
