import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {SharedService2Service} from '../shared-service2.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../base/base.component';
import {DataTableDirective} from 'angular-datatables';
import {DataTablesResponse} from '../models/datatableResponse';

@Component({
  selector: 'app-change-manager',
  templateUrl: './change-manager.component.html',
  styleUrls: ['./change-manager.component.css']
})
export class ChangeManagerComponent extends BaseComponent implements OnInit {

  CurrentAccount: any;
  SelectedTab: string = 'Bids';
  CangeMasterCounts: any;
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  dtOptionsChangeManagerPendingBID: DataTables.Settings = {};
  dtOptionsChangeManagerPendingNEGATIVES: DataTables.Settings = {};

  allPendingBids: any [];
  allPendingNegatives: any[];

  constructor(
    public dataShared: SharedService2Service,
    public modalService: NgbModal,
    public http: HttpClient,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) {

    super(dataShared, http, toastr, modalService);
    this.CurrentAccount = {
      ChangeManagerEnabled: 0
    };
    this.CangeMasterCounts = {
      bid: 0, neg: 0
    };


    const urlChangeBid = '/api/ChangeManager/bid/pending';
    this.dtOptionsChangeManagerPendingBID = {

      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [3, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {
        //    console.log(dataTablesParameters);
        const postData = {...dataTablesParameters, ...this.postdata()};

        this.http
          .post<DataTablesResponse>(
            urlChangeBid,
            postData
          ).subscribe(resp => {

          this.allPendingBids = resp.data;
          console.log('data');
          console.log(resp);

          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }

          if (resp.error === false || resp.error === undefined) {


          } else {
            this.toastr.warning(resp.msg);
          }


          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'action', name: 'action', orderable: false},
        {data: 'action2', name: 'action2', orderable: false},
        {data: 'keywordText', name: 'keywordText'},
        {data: 'details', name: 'details'},
        {data: 'old_bid', name: 'old_bid'},
        {data: 'new_bid', name: 'new_bid'},
        {data: 'date', name: 'date'},
        {data: 'ChangemanagerState', name: 'ChangemanagerState'},

      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {
        this.updatesInProgress = false;
        this.hideLoadingBar();
      }
    };


    const urlChangedNegatives = '/api/ChangeManager/negatives/pending';
    this.dtOptionsChangeManagerPendingNEGATIVES = {

      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [3, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {
        //    console.log(dataTablesParameters);
        const postData = {...dataTablesParameters, ...this.postdata()};

        this.http
          .post<DataTablesResponse>(
            urlChangedNegatives,
            postData
          ).subscribe(resp => {

          this.allPendingNegatives = resp.data;
          console.log('data');
          console.log(resp);

          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }

          if (resp.error === false || resp.error === undefined) {
          } else {
            this.toastr.warning(resp.msg);
          }


          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'rowId', name: 'rowId', orderable: false},
        {data: 'action', name: 'action', orderable: false},
        {data: 'Query', name: 'Query'},
        {data: 'state', name: 'state'},
        {data: 'keywordText', name: 'keywordText'},
        {data: 'Campaign', name: 'Campaign'},
        {data: 'bid', name: 'bid'},
        {data: 'ACOS', name: 'ACOS'},
        {data: 'CPC', name: 'CPC'},
        {data: 'suggested', name: 'suggested'},
        {data: 'isNegative', name: 'isNegative'},
        {data: 'matchType', name: 'matchType'},
        {data: 'Profit', name: 'Profit'},
        {data: 'Revenue', name: 'Revenue'},
        {data: 'ADSpend', name: 'ADSpend'},
        {data: 'NET', name: 'NET'},
        {data: 'Impressions', name: 'Impressions'},
        {data: 'Clicks', name: 'Clicks'},
        {data: 'CTR', name: 'CTR'},
        {data: 'CVR', name: 'CVR'},
        {data: 'Orders', name: 'Orders'},
        {data: 'action2', name: 'action2', orderable: false},
        {data: 'recomended', name: 'recomended', orderable: false},
        {data: 'NegativeAction', name: 'NegativeAction', orderable: false},
        {data: 'date', name: 'date', orderable: false},

      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {
        this.updatesInProgress = false;
        this.hideLoadingBar();
      }
    };


  }

  updatePageData() {

  }

  changeState() {

    const url = '/api/ChangeManager/changeState';
    const postData = {isEnabled: this.CurrentAccount.ChangeManagerEnabled};
    this.http.post<any>(url, postData).toPromise()
      .then(
        result => {
          console.log('params');
          console.log(result);
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            if (this.CurrentAccount.ChangeManagerEnabled === true) {
              //     $scope.dtInstanceChangeManagerPendingBID.reloadData(false, false);
              this.updatePageData();
            }
          }
        });
  }

}
