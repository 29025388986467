import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {SharedService2Service} from '../shared-service2.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit {

  AccountDetails: any;
  persons: any [];

  constructor(public modalService: NgbModal,
              public http: HttpClient, public toastr: ToastrService,
              public dataShared: SharedService2Service) {
    super(dataShared, http, toastr, modalService);
    this.AccountDetails = {
      person: '',
      FirstName: '',
      LastName: '',
      email: '',
      password: '',


    };
    this.hideLoadingBar();
    this.showMainLayout();

    this.dataShared.currentUser.subscribe(currentUser => {
      console.log('shared current user =');
      console.log(currentUser);
      this.AccountDetails = {
        person: '',
        FirstName: currentUser.firstName,
        LastName: currentUser.lastName,
        email: currentUser.email,
        password: '',
      };
    });
  }

  updatePageData() {
    const url = '/api/settings';
    this.http.get<any>(url).toPromise().then(
      result => {
        if (result.error === false) {

          this.AccountDetails = {
            FirstName: result.FirstName,
            LastName: result.LastName,
            email: result.email,
            person: result.person
          };

        } else {
          this.toastr.warning('Error while update keyword' + result.msg);
        }
      }
    );
  }

  SaveDetails() {
    const url = '/api/settings';
    this.http.post<any>(url, this.AccountDetails).toPromise().then(
      result => {
        console.log('params');
        console.log(result);
        if (result.status === 0) {
          this.toastr.warning(result.msg);
        } else {
          this.toastr.success(result.msg);
        }
      });
  }

}
