import {
  AfterViewChecked,
  Compiler,
  Component,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  NgModuleRef,
  OnInit,
  Output,
  NgModule,
  Renderer2, ViewChild, ViewContainerRef, ViewChildren, QueryList
} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {CommonModule} from '@angular/common';

import {DomSanitizer} from '@angular/platform-browser';
import {DataTableDirective} from 'angular-datatables';
import {HttpClient} from '@angular/common/http';
import {DataTablesResponse} from '../models/datatableResponse';


@Component({
  selector: 'app-reselect-sku-modal',
  templateUrl: './reselect-sku-modal.component.html',
  styleUrls: ['./reselect-sku-modal.component.css']
})


export class ReselectSkuModalComponent implements OnInit {
  @Input() public user;
  @Input() public TableLength: number;
  @Input() public maxallowed: number;
  Selected: number;
  SearchINTableReselect = '';
  ShowAllSKUReselect = false;
  allSKU: any;
  Selectedskus: any;
  sku: any = {};
  dtOptionsSKUReselectAngular: DataTables.Settings = {};
  allSKUs: any;

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;
  private cmpRef: ComponentRef<any>;

  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, public renderer: Renderer2,
              private sanitizer: DomSanitizer,
              private compiler: Compiler,
              private injector: Injector,
              private moduleRef: NgModuleRef<any>,
              private http: HttpClient
  ) {

    this.sku.r11 = 5;


    const url = '/api/skuList_reselect_7?SelectAll=' + this.ShowAllSKUReselect + '&d=' + Date.now();

    this.dtOptionsSKUReselectAngular = {

      pagingType: 'simple_numbers',
      processing: true,
      serverSide: true,
      pageLength: this.TableLength,
      lengthChange: false,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
        searchPlaceholder: 'Search',
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .get<DataTablesResponse>(
            url
          ).subscribe(resp => {
          this.allSKUs = resp.all;
          const data = resp;
          console.log('data');
          console.log(data);

          if (data.redirect !== undefined && data.redirect !== '') {
            console.log('we have redirect');
            return false;
          }
          this.allSKUs = resp.data;

          if (resp.error === false || resp.error === undefined) {

            this.Selectedskus = [];
            if (data.emptyProductNames === 0) {
              console.log('all done');
              this.Selected = 0;

              const allSkuKeyw = Object.keys(this.allSKU);

              for (const key of allSkuKeyw) {
                const value = this.allSKU[key];
                console.log(key);
                console.log(key);
                console.log(value);
                //  const model = $parse('sku.r' + key);
                if (value.selected === true) {
                  this.Selected++;
                  //     model.assign($scope, true);
                  this.Selectedskus.push(key);
                }

              }

            } else {
              console.log('all NOT  done. ' + data.emptyProductNames + ' items left');
              setTimeout(() => {
                this.getNewSKUreselect();
              }, 5000);
            }

          } else {
            this.toastr.warning(data.msg);
          }


          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      order: [2, 'desc'],
      autoWidth: false,
      info: false,
      columns: [{
        title: '<input type="checkbox" [(ngModel)]="SelectAllCheckbox"> action',
        data: 'action',
        name: 'action'
      },
        {data: 'Image', name: 'Image'},
        {data: 'product_name', name: 'Name'},
        {data: 'sku_asin', name: 'SKU/ASIN'},
      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      createdRow: (row, data, index) => {
      },
      drawCallback: (settings) => {

        console.log(settings);
        console.log('11111111');
        const Id = '6853441';
        this.sku[Id] = true;

      }
    };


  }


  ReinitDatatable(settings) {
    console.log(settings);

  }


  ngOnInit() {
    console.log(this.user);
    console.log(this.TableLength);
    console.log(this.maxallowed);

  }

  SaveSKU() {
    this.activeModal.close(this.user);
  }

  changedExtraHandler(event) {
    console.log(event);
    this.getNewSKUreselect();
    if (this.dtElements !== undefined) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          const url = '/api/skuList_reselect_7?SelectAll=' + this.ShowAllSKUReselect + '&d=' + Date.now();
          dtInstance.table().ajax.url(url).load();
          //    this.TableLength = dtInstance.table().page.len();
        });
      });
    }
  }


  changePageLength(event) {
    console.log(event);
    this.getNewSKUreselect();
    if (this.dtElements !== undefined) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          dtInstance.table().page.len(this.TableLength).draw();

        });
      });
    }
  }

  searchIndatatables(event) {
    console.log(event);
    if (this.dtElements !== undefined) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          dtInstance.table().search(this.SearchINTableReselect).draw();
          //   this.TableLength = dtInstance.table().page.len();
        });
      });
    }
  }

  getNewSKUreselect() {
  }

  ADDSKU(skuId, isChecked) {
    console.log(skuId);
    if (this.Selected < this.maxallowed) {
      if (isChecked === true) {
        if (this.Selectedskus.indexOf(skuId) >= 0) {
          console.log('sku already present');
        } else {
          console.log('sku not present in array');
          this.Selectedskus.push(skuId);
        }

      } else {
        if (this.Selectedskus.indexOf(skuId) >= 0) {
          console.log('sku already present in array ');
          this.Selectedskus.splice(this.Selectedskus.indexOf(skuId), 1);
        }
      }
      //  $scope.checkedCount();
    } else {

      if (isChecked === true) {
        // @todo uncheck this
        this.toastr.warning('You cant select more than ' + this.maxallowed + 'sku ');
      } else {
        this.Selectedskus.forEach(eachObj => {
          const key = eachObj.key();
          console.log(eachObj);
          this.Selectedskus.splice(key, 1);
        });
      }
      this.Selected = this.Selectedskus.length;
    }
  }

}
