import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {SharedService2Service} from '../shared-service2.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {WizardModalComponent} from '../wizard-modal/wizard-modal.component';
import {FBWizardModalComponent} from '../fbwizard-modal/fbwizard-modal.component';
import {ConnectFacebookComponent} from '../connect-facebook/connect-facebook.component';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';


declare var $: any;

class DashboardGraphResponse {
  redirect: string;
  graphData: any[];
  Tick: {
    values,
    days
  };
}

@Component({
  selector: 'app-fbwizard',
  templateUrl: './fbwizard.component.html',
  styleUrls: ['./fbwizard.component.scss']
})
export class FBWizardComponent extends BaseComponent implements OnInit {

  totalData: any;
  graphOptions;
  graphData;
  public graphDays;
  allLinks: any[];
  FBToken: boolean = false;
  FBTokenUrl: string;
  loggedIn: boolean;
  notificationShowed: boolean = false;
  dtOptionsShopLinks: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  showLinks: boolean = false;
  @ViewChild(DataTableDirective, {static: false}) dtElement: any;

  constructor(public dataShared: SharedService2Service,
              public modalService: NgbModal, private router: Router,
              private authenticationService: AuthService,
              public http: HttpClient, public toastr: ToastrService,
              private route: ActivatedRoute) {
    super(dataShared, http, toastr, modalService);
    this.toggleValue.r1 = 1;
    this.graphOptions = {
      chart: {
        type: 'lineChart',
        height: 200,
        margin: {
          top: 20,
          right: 20,
          bottom: 50,
          left: 55
        },
        showValues: true,
        valueFormat(d) {
          return d3.format(',.2f')(d);
        },
        duration: 500,
        xAxis: {
          axisLabel: 'days',
          ticks: 5,
          tickFormat: (d) => {
            return d3.time.format('%-d %b')(new Date(this.graphDays[d]));
            //   this.formatDateForGraph(d);
            //     (d) => this.formatDateForGraph(d);
          }

        },
        yAxis: {
          axisLabel: 'value',
          axisLabelDistance: -10,
          tickFormat(d) {
            return d3.format('.2f')(d);
          }
        }
      }
    };

  }


  ngInitFinished() {


    this.showMainLayout();
    this.hideLoadingBar();
    this.updateGlonbalObject();

    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (this.notificationShowed === false) {


        if (params.msg !== undefined && params.msg !== '') {
          this.notificationShowed = true;
          if (params.msg === 'connected') {
            this.toastr.success('Your FB account connected');
          } else {
            this.toastr.warning(params.msg);
          }
        }

      }

    });


    this.dtOptionsShopLinks = {

      pagingType: 'full_numbers',
      processing: false,
      serverSide: false,
      lengthChange: false,
      pageLength: this.TableLength,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [1, 'desc'],
      autoWidth: false,
      info: false,
      drawCallback: (settings) => {
        this.updatesInProgress = false;
        this.hideLoadingBar();
      }
    };
    console.log('get all links');
  //  this.getAllLinks();


    /*

   let xOffset = 10;
   let  yOffset = 30;

    // these 2 variable determine popup's distance from the cursor
    // you might want to adjust to get the right result


    $('a.preview').hover(function(e){
      console.log('preview');
        $('body').append('<p id="previewBigImage"><img src="'+ this.href +'" alt="Image preview" /></p>');
        $('#previewBigImage')
          .css('top',(e.pageY - xOffset) + 'px')
          .css('left',(e.pageX + yOffset) + 'px')
          .fadeIn('fast');
      },
      function(){
       $('#previewBigImage').remove();
      });
    $('a.preview').mousemove(function(e){
      $('#previewBigImage')
        .css('top',(e.pageY - xOffset) + 'px')
        .css('left',(e.pageX + yOffset) + 'px');
    });



*/


  }


  over(e, link) {

    let xOffset = 10;
    let yOffset = 30;
    console.log('Mouseover called');
    console.log(e);
    $('body').append('<p id="previewBigImage"><img src="' + link + '" alt="Image preview" /></p>');
    $('#previewBigImage')
      .css('top', (e.pageY - xOffset) + 'px')
      .css('left', (e.pageX + yOffset) + 'px')
      .fadeIn('fast');
  }

  out() {
    $('#previewBigImage').remove();
  }


  getDashboardGraph() {

    const url = '/api/FBWizard/graph';
    this.http.post(url, this.postdata()).subscribe((params: DashboardGraphResponse) => {
      console.log('params');
      console.log(params);
      this.hideLoadingBar();
      if (params.redirect !== '') {
        this.router.navigate([params.redirect]);
      } else {

        this.graphData = params.graphData;
        this.graphDays = params.Tick.days;
      }
    });

  }


  ShowFBModal() {

    this.router.navigate(['fbwizardNew']);
    return false;


    if (this.FBToken === false) {
      /*  alert('You must connect your FB account');
        window.location.href = this.FBTokenUrl;     */

      const modalRef = this.modalService.open(ConnectFacebookComponent, {size: 'xl'});
      modalRef.componentInstance.selectedFilters = this.selectedFilters;
      modalRef.result.then((result) => {
        console.log(result);
        if (result) {
          console.log(result);
        }
      }, (reason) => {
        console.log(reason);
        if (reason === ModalDismissReasons.ESC) {
          console.log('by pressing ESC');
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          console.log('by clicking on a backdrop');
        } else {
          console.log(`with: ${reason}`);
        }
      });


    } else {
      const modalRef = this.modalService.open(FBWizardModalComponent, {size: 'xl'});
      modalRef.componentInstance.selectedFilters = this.selectedFilters;
      modalRef.result.then((result) => {
        console.log(result);
        if (result) {
          console.log(result);
          this.getAllLinks();
        }
      }, (reason) => {
        console.log(reason);
        if (reason === ModalDismissReasons.ESC) {
          console.log('by pressing ESC');
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          console.log('by clicking on a backdrop');
        } else {
          console.log(`with: ${reason}`);
        }
      });
    }
  }


  getAllLinks() {

    const postData = {
      startDate: this.dateRange.startDate.format('YYYY-MM-DD'),
      endDate: this.dateRange.endDate.format('YYYY-MM-DD'),
      showLinks: this.showLinks,
      SelectAll: this.SelectAll,
      filter: this.selectedFilters,

    };
    const url = '/api/FBlistLinks';
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          let isFirst = true;
          if (this.allLinks !== undefined) {
            if (this.allLinks.length > 0) {
              isFirst = false;
            }
          }
          this.allLinks = result.allLinks;
          this.FBToken = result.FBToken;
          this.FBTokenUrl = result.FBTokenUrl;


          this.allLinks.forEach((element) => {
            if (element.Level === 'SKU') {
              this.toggleValue[element.slug] = element.landingEnabled;
            }

          });
          this.totalData = result.totalData;

          if (isFirst) {
            if (this.dtElement.dtInstance === undefined) {
              this.dtTrigger.next();
            }
          } else {
            if (this.dtElement.dtInstance !== undefined) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                //   dtInstance.init();
                //  dtInstance.clear();
                //   dtInstance.length =25;
                //   dtInstance.draw();
                this.dtTrigger.next();
              });
            }
          }
        }
      });
  }

  updatePageData() {
    console.log('update');
    this.getAllLinks();
    this.getDashboardGraph();
    this.getTotalProfit();
  }


  showFBWizardSettings(WizardId) {
    console.log('show settings for ' + WizardId);
    const url = '/api/FBWizzard/settings/' + WizardId;
    this.http.get<any>(url).toPromise().then(result => {
      console.log(result);
      if (result.error === true) {
        this.toastr.warning(result.msg);
      } else {

        const modalRef = this.modalService.open(FBWizardModalComponent, {size: 'xl'});
        modalRef.componentInstance.OnlySettings = true;
        modalRef.componentInstance.wizardSettings = result.settings;

        modalRef.result.then((resultModal) => {
          console.log(resultModal);
          if (resultModal) {
            console.log(resultModal);


          }
        }, (reason) => {
          console.log(reason);
          if (reason === ModalDismissReasons.ESC) {
            console.log('by pressing ESC');
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            console.log('by clicking on a backdrop');
          } else {
            console.log(`with: ${reason}`);
          }
        });
      }
    });

  }


  editLanding(landing) {
    console.log(landing);
    this.router.navigate(['fbwizardNew'], {queryParams: {landingId: landing.landingId}});
    return false;
  }

  regenerateScreenshot(landing) {
    const postData = {
      landingId: landing.landingId
    };
    const url = '/api/FBRegenerateSnapshot';
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          landing.previewScreenshot = result.previewScreenshot;
        }
      });
  }


  ChangeWizzardToogle(curItem) {
    console.log('cange wizard');


    const url = '/api/FBWizardToogleState';
    const postData = {'landingId': curItem.id, 'state': this.toggleValue[curItem.slug]};
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {

        }
      });


  }


  getTotalProfit() {

    let url = '/api/calculateProfit';
    const postData = {...{section: 'fbWizard'}, ...this.postdata()};
    this.http
      .post<any>(
        url,
        postData
      ).toPromise().then(result => {
      console.log(result);

      if (result.error === false) {
        this.profitDownloaded = true;
        this.TotalProfit = result.profit.real;
        this.ProfitPPC = result.profit.ppc;
      }
    });


  }


  ShowVisiblityFilter(section) {

    const allColumnsVis = [];

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

      // @ts-ignore
      console.log(dtInstance.table().columns());
      let id = 0;
      // @ts-ignore
      dtInstance.table().columns().every(function() {
        const data = this.data();
        console.log(data);
        //   console.log(this.column().name()); // since DT 2.0
        console.log(this.visible());
        const colheader = this.header();
        const title = $(colheader).text().trim();
        console.log(title);
        if (title !== '') {
          allColumnsVis.push({name: title, id, visibility: this.visible()});
        }
        id += 1;
      });
      this.allColumnsVis = allColumnsVis;
      // @ts-ignore
      this.openVisibilityModal(dtInstance.table());

    });
  }


}
