import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SharedService2Service} from '../shared-service2.service';
import {NgbModal, NgbProgressbarConfig} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {BaseComponent} from '../base/base.component';
import {interval} from 'rxjs';

@Component({
  selector: 'app-setup-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['./setup-account.component.scss']
})
export class SetupAccountComponent extends BaseComponent implements OnInit, AfterViewInit {

  SellerLink: string;
  country: string;
  NewAccount: any;
  currentStep: number = 1;
  devID: string;
  downloadingProgress: any;
  alldownload: boolean;
  PromiseIntervalFast: any;
  weKnowCurrentStage: boolean;

  constructor(public dataShared: SharedService2Service,
              public modalService: NgbModal, private router: Router,
              public http: HttpClient, public toastr: ToastrService, config: NgbProgressbarConfig) {
    super(dataShared, http, toastr, modalService);
    this.weKnowCurrentStage = false;
    this.NewAccount = {
      sellerId: '',
      country_coode: '',
      AuthToken: ''
    };

    this.devID = '3280-6223-7789';
    this.SellerLink = 'https://sellercentral.amazon.com/gp/mws/registration/register.html?signInPageDisplayed=1&developerName=PPC.tools&devMWSAccountId=' + this.devID;
    this.alldownload === false;
    this.downloadingProgress = {
      salesDone: 1,
      snapshotDone: 2,
      advDone: 3,

    };

    config.max = 100;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
    config.height = '20px';


  }

  ngOnInit() {
    this.showMainLayout();
    this.getSettings();
  }


  onSubmit() {

  }

  nextStep() {
    this.currentStep += 1;

  }

  ngAfterViewInit(): void {


  }

  addAccount() {
    this.http.post<any>('/api/settings/account', this.NewAccount).toPromise()
      .then(result => {
        console.log('params');
        if (result.status === 0) {
          this.toastr.warning(result.msg);
        } else {
          this.toastr.success('Data is valid');
          /*
                    this.params.selleraccounts.push({
                      id: params.CurrentAccount.id,
                      name: params.CurrentAccount.sellerId,
                      sellerId: params.CurrentAccount.sellerId,
                      mwsToken: params.CurrentAccount.mws_token,
                      country_coode: params.CurrentAccount.country_coode,
                      AuthToken: params.CurrentAccount.auth_token
                    });
                    $scope.CurentAccount = {
                      id: params.CurrentAccount.id,
                      name: params.CurrentAccount.sellerId,
                      sellerId: params.CurrentAccount.sellerId,
                      mwsToken: params.CurrentAccount.mws_token,
                      country_coode: params.CurrentAccount.country_coode,
                      AuthToken: params.CurrentAccount.auth_token

                    };
                    $scope.NewAccount = {
                      id: params.CurrentAccount.id,
                      name: params.CurrentAccount.sellerId,
                      sellerId: params.CurrentAccount.sellerId,
                      mwsToken: params.CurrentAccount.mws_token,
                      country_coode: params.CurrentAccount.country_coode,
                      AuthToken: params.CurrentAccount.auth_token
                    };

                    if ($scope.ProfileStage <= 3) {
                      $scope.ProfileStage = 3; // second when we have account and select them
                    }
                    angular.forEach($scope.tabs, function(value, key) {
                      //value = false;
                      $scope.tabs[key] = false;
                    });
                    $scope.tabs.selectToken = true;
                    */
          this.currentStep = 2;
        }
      });
  }

  changeAccountCountry(country) {
    switch (country) {
      case'de':
        this.NewAccount.country_coode = 'de';
        this.country = 'Germany';
        this.devID = '2393-8033-9656';
        break;
      case'uk':
        this.NewAccount.country_coode = 'uk';
        this.country = 'United Kingdom';
        this.devID = '2393-8033-9656';
        break;
      case'es':
        this.NewAccount.country_coode = 'es';
        this.country = 'Spain';
        this.devID = '2393-8033-9656';
        break;
      case'ca':
        this.NewAccount.country_coode = 'ca';
        this.country = 'Canada';
        this.devID = '2393-8033-9656';
        break;
      case'it':
        this.NewAccount.country_coode = 'it';
        this.country = 'Italy';
        this.devID = '2393-8033-9656';
        break;
      case'fr':
        this.NewAccount.country_coode = 'fr';
        this.country = 'France';
        this.devID = '2393-8033-9656';
        break;

      case'us':
        this.NewAccount.country_coode = 'us';
        this.country = 'United States';
        this.devID = '3280-6223-7789';
        break;
    }

  }


  getProgress() {
    this.http.get('/api/settings/progress').toPromise()
      .then(result => {
        console.log('progress ok');
      });
  }

  checkProgress() {

    this.PromiseIntervalFast = interval(15000).subscribe(x => {
      console.log(x);
      if (this.alldownload === true) {
        console.log('return true');
        this.PromiseIntervalFast.unsubscribe();
        this.router.navigate(['/dashboard']);
      } else {
        this.getProgressFast();
      }
    });
  }


  getProgressFast() {
    this.http.get<any>('/api/settings/progress/fast').toPromise()
      .then(result => {
        console.log('progress ok');
        if (result.error === false) {
          this.downloadingProgress = result.data.downloadingProgress;
          this.alldownload = result.data.alldownload;
          if (this.alldownload === true) {
            this.PromiseIntervalFast.unsubscribe();
            this.router.navigate(['/dashboard']);
          }
        }
      });
  }


  getSettings() {
    console.log('get settings');
    this.http.get<any>('/api/settings').toPromise()
      .then(result => {
        if (result.error === true) {
          this.toastr.warning(result.msg);
          return false;
        }
        if (result.redirect !== '' && result.redirect !== undefined) {
          //  $state.go(params.redirect);
        } else {
          if (result.tokenError !== '') {
            this.toastr.warning(result.tokenError);

          }

          this.NewAccount = result.CurentAccount;
          if (result.selleraccounts.length > 0) {
            this.currentStep = 2; // second when we have account
            console.log('1');
          }
          if (result.CurentAccount.id !== undefined && result.CurentAccount.id !== 'new') {
            this.currentStep = 3; // third when we have selected account
            console.log('2');
            if (result.CurentAccount.AuthToken === '') {
              this.currentStep = 3;
              console.log('3');
            } else {
              if (result.reportsDone === false) {
                console.log('4');
                this.currentStep = 4;
                this.checkProgress();

              } else if (result.reportsDone === true) {
                this.router.navigate(['/dashboard']);
              }
            }
          } else {
            console.log('promise havent acountid');
          }

          this.weKnowCurrentStage = true;
        }
      });
  }


}
