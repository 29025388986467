import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {ScheduleListModalComponent} from '../schedule-list-modal/schedule-list-modal.component';
import {DataTablesResponse} from '../models/datatableResponse';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss']
})
export class ScheduleModalComponent implements OnInit {

  @Input() public ScheduleTarget;
  @Input() public SelectedCampaigns;
  AllSchedules: any[];
  AllScheduleLog: any[];
  TimeFrom: string = '00:00';
  TimeFromAppend: string = 'AM';
  TimeTo: string = '11:30';
  TimeToAppend: string = 'PM';
  ScheduleAction: string = 'noAds';
  ScheduleActionVal: string = '0.2';
  ScheduleExist: string;
  OnlyOneCampaign: any;
  SelectedTab: string = 'Settings';
  amazonTime: string = '';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, private http: HttpClient, public modalService: NgbModal) {


  }

  ngOnInit() {

    const urlLogs = '/api/schedule/campaigns/log';
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [1, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {
        //    console.log(dataTablesParameters);
        const postData = {...dataTablesParameters, ...this.postdata()};
        // this.updatesInProgress = true;
        this.http
          .post<DataTablesResponse>(
            urlLogs,
            postData
          ).subscribe(resp => {

          this.AllScheduleLog = resp.data;
          console.log('data');
          console.log(resp);
          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }
          if (resp.error === false || resp.error === undefined) {
          } else {
            this.toastr.warning(resp.msg);
          }
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'startDate', name: 'startDate'},
        {data: 'endDate', name: 'endDate'},
        {data: 'oldBid', name: 'oldBid'},
        {data: 'newBid', name: 'newBid'},
        {data: 'state', name: 'state'},

      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {

      }
    };

    this.getSchedules();
  }

  pauseSchedule(schedule) {
    console.log(schedule);

    const url = '/api/schedule/campaigns/pauseOne';
    const postData = {schedule};

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success('Updated');
          } else {
            this.toastr.warning('Error while update keyword' + result.msg);
          }
        }
      });
  }

  deleteSchedule(schedule) {
    console.log(schedule);
    const url = '/api/schedule/campaigns/DeleteOne';
    const postData = {schedule};
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success('Deleted');
          } else {
            this.toastr.warning('Error while update keyword' + result.msg);
          }
        }
      });
  }

  /*
    ShowSchedules() {

      const modalRef = this.modalService.open(ScheduleListModalComponent, {size: 'xl'});
      modalRef.componentInstance.scheduleTargetText = this.ScheduleTarget;
      modalRef.componentInstance.ScheduleTargetId = this.SelectedCampaigns[0];
      modalRef.result.then((resultModal) => {
        console.log(resultModal);
      }, (reason) => {
        console.log(reason);
      });
    }
    */

  SaveSchedule() {

    const url = '/api/schedule/campaigns/Setup';
    const postData = {
      campaign: this.SelectedCampaigns,
      parametrs: {
        TimeFrom: this.TimeFrom + ' ' + this.TimeFromAppend,
        TimeTo: this.TimeTo + ' ' + this.TimeToAppend,
        ScheduleAction: this.ScheduleAction,
        ScheduleActionVal: this.ScheduleActionVal
      }
    };

    this.http.post<any>(url, postData).toPromise()
      .then(result => {

        if (result.error === false) {
          this.toastr.success('Updated');
          this.activeModal.close('Updated');

        } else {
          this.toastr.warning('Error while update campaigns' + result.msg);
        }
      });

  }


  getSchedules() {

    const url = '/api/schedule/campaigns/get';

    this.http.post<any>(url, this.postdata()).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.AllSchedules = result.schedules;
            this.amazonTime = result.amazonTime;
          } else {
            this.toastr.warning('Error while update keyword' + result.msg);

          }
        }
      });
  }

  postdata() {
    return {campaign: this.SelectedCampaigns};
  }

}
