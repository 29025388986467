import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {SharedService2Service} from '../shared-service2.service';
import {BaseComponent} from '../base/base.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends BaseComponent implements OnInit {

  subject: string = '';
  message: string;
  processed: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document,
              public modalService: NgbModal,
              public http: HttpClient, public toastr: ToastrService,
              public dataShared: SharedService2Service) {
    super(dataShared, http, toastr, modalService);
  }

  save() {
    const url = '/api/contactUs';
    const data = {subject: this.subject, message: this.message};

    if (this.processed === true) {
      return false;
    }
    this.processed = true;
    this.http.post<any>(url, data).toPromise()
      .then(
        result => {

          this.processed = false;
          console.log('params');

          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.toastr.success('Message was send');
            this.subject = '';
            this.message = '';
          }
        }
      );
  }

}
