import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataTablesResponse} from '../models/datatableResponse';
import {ScheduleModalComponent} from '../schedule-modal/schedule-modal.component';

@Component({
  selector: 'app-schedule-list-modal',
  templateUrl: './schedule-list-modal.component.html',
  styleUrls: ['./schedule-list-modal.component.scss']
})
export class ScheduleListModalComponent implements OnInit, OnDestroy {

  SelectedTab: string = 'ScheduleList';
  @Input() public scheduleTargetText;
  @Input() public ScheduleTargetId;
  amazonTime: string;
  AllSchedules: any[];
  AllScheduleLog: any [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();


  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, private http: HttpClient, public modalService: NgbModal) {


  }

  ngOnInit() {

    const urlLogs = '/api/schedule/campaigns/log';
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [1, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {
        //    console.log(dataTablesParameters);
        const postData = {...dataTablesParameters, ...this.postdata()};
        // this.updatesInProgress = true;
        this.http
          .post<DataTablesResponse>(
            urlLogs,
            postData
          ).subscribe(resp => {

          this.AllScheduleLog = resp.data;
          console.log('data');
          console.log(resp);
          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }
          if (resp.error === false || resp.error === undefined) {
          } else {
            this.toastr.warning(resp.msg);
          }
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'startDate', name: 'startDate'},
        {data: 'endDate', name: 'endDate'},
        {data: 'oldBid', name: 'oldBid'},
        {data: 'newBid', name: 'newBid'},
        {data: 'state', name: 'state'},

      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {

      }
    };


    this.getSchedules();

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }


  pauseSchedule(schedule) {
    console.log(schedule);

    const url = '/api/schedule/campaigns/pauseOne';
    const postData = {schedule};

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success('Updated');
          } else {
            this.toastr.warning('Error while update keyword' + result.msg);
          }
        }
      });
  }

  deleteSchedule(schedule) {
    console.log(schedule);
    const url = '/api/schedule/campaigns/DeleteOne';
    const postData = {schedule};
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success('Deleted');
          } else {
            this.toastr.warning('Error while update keyword' + result.msg);
          }
        }
      });
  }

  postdata() {
    return {campaign: this.ScheduleTargetId};
  }

  getSchedules() {

    const url = '/api/schedule/campaigns/get';

    this.http.post<any>(url, this.postdata()).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.scheduleTargetText = result.keywordText;
            this.AllSchedules = result.schedules;
            this.amazonTime = result.amazonTime;
          } else {
            this.toastr.warning('Error while update keyword' + result.msg);

          }
        }
      });
  }

  ShowScheduleModal() {
    console.log('call show modal');
    const SelectedCampaigns = [this.ScheduleTargetId];
    const modalRef = this.modalService.open(ScheduleModalComponent, {size: 'xl'});
    modalRef.componentInstance.ScheduleTarget = this.scheduleTargetText;
    modalRef.componentInstance.SelectedCampaigns = SelectedCampaigns;
    modalRef.result.then((resultModal) => {
      console.log(resultModal);
    }, (reason) => {
      console.log(reason);
    });
  }

}
