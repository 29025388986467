import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import * as moment from 'moment';
import {User} from './models/user';


class GlobalSharedObject {
  dateRange: any;
  LoadingInProgress: boolean;
  campaignType: string;
  portfolioId: number;
  bodyLayout: string;
  selectedFilters: any[];
  skipedFilters: any[];
  TableLength: any;
}


@Injectable()
export class SharedService2Service {

  dateRange: {
    startDate: moment.Moment, endDate: moment.Moment
  };
  campaignType: string = 'All';
  portfolioId: number = -1;
  private messageSource = new BehaviorSubject({
    LoadingInProgress: true,
    bodyLayout: 'auth',
    dateRange: this.dateRange,
    campaignType: 'All',
    portfolioId: -1,
    selectedFilters: [],
    skipedFilters: [],
    TableLength: '10'
  });
  currentMessage = this.messageSource.asObservable();


  private userSource = new BehaviorSubject(new User());
  currentUser = this.userSource.asObservable();


  constructor() {
  }

  changeMessage(message: GlobalSharedObject) {
    this.messageSource.next(message);
  }

  updateUser(userObject: User) {
    this.userSource.next(userObject);
  }


}

