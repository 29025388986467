import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {SharedServiceService} from '../shared-service.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {DataTableDirective} from 'angular-datatables';
import {FiltersModalComponent} from '../filters-modal/filters-modal.component';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {BaseComponent} from '../base/base.component';
import {SharedService2Service} from '../shared-service2.service';
import {DataTablesResponse} from '../models/datatableResponse';

class DataTablesCampaignRow {
  productImage: string;

  id: number;
  campaignId: number;
  adGroupId?: number;
}

class DashboardGraphResponse {
  redirect: string;
  graphData: any[];
  Tick: {
    values,
    days
  };
}

declare var $: any;

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss']
})
export class CampaignsListComponent extends BaseComponent implements OnInit {
  dtOptionsCampaignsAngular: DataTables.Settings = {};
  closeResult: string;
  SelectAll: any;
  showTargets: boolean = false;
  showAdGroups: boolean = false;
  showASINS: boolean = false;
  onlyActive: boolean = true;
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  private firstUpdateTableCall: boolean = true;
  selectAll: boolean = false;
  campaigns: any = {};
  title: string = 'Campaign List';
  activeIdString: string = 'campaigns';
  graphOptions;
  graphData;
  public graphDays;

  constructor(
    public dataShared: SharedService2Service,
    public modalService: NgbModal,
    public http: HttpClient,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) {

    super(dataShared, http, toastr, modalService);
    this.listAvaliableFilters = ['campaignId', 'sku', 'Clicks', 'Orders', 'CVR', 'CPC', 'CTR', 'Impressions', 'Sales', 'ACOS', 'AdSpend'];


    // this.selectedFilters = [];
    this.campaigns.r1 = 1;
    this.bids.r1 = 0;
    this.panel = {};
    this.showDropdown.r1 = 1;
    this.tmpBid.r1 = 1;
    this.edited.r1 = 1;
    this.neg.r1 = 1;
    this.negPending.r1 = 1;
    this.records.r1 = 1;
    this.toggleValue.r1 = true;

    this.graphOptions = {
      chart: {
        type: 'lineChart',
        height: 200,
        margin: {
          top: 20,
          right: 20,
          bottom: 50,
          left: 55
        },
        showValues: true,
        valueFormat(d) {
          return d3.format(',.2f')(d);
        },
        duration: 500,
        xAxis: {
          axisLabel: 'days',
          ticks: 5,
          tickFormat: (d) => {
            return d3.time.format('%-d %b')(new Date(this.graphDays[d]));
            //   this.formatDateForGraph(d);
            //     (d) => this.formatDateForGraph(d);
          }

        },
        yAxis: {
          axisLabel: 'value',
          axisLabelDistance: -10,
          tickFormat(d) {
            return d3.format('.2f')(d);
          }
        }
      }
    };

  }


  ngInitFinished() {
    this.showMainLayout();
    const url = '/api/CampaignList/manage_7?t=' + Date.now();

    this.dtOptionsCampaignsAngular = {

      pagingType: 'full_numbers',
      processing: true,
      lengthChange: false,
      serverSide: true,
      pageLength: this.TableLength,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
        //      paginate: {
        //        next: '<i class="fa fa-forward"></i>',
        //         previous: '<i class="fa fa-backward"></i>',
        //        first: '          <button class="mat-paginator-navigation-first mat-icon-button mat-button-base ng-star-inserted"\n' +
        //          '                  type="button" aria-label="First page" >\n' +
        //          '            <span class="mat-button-wrapper"><svg class="mat-paginator-icon" focusable="false" viewBox="0 0 24 24"><path\n' +
        //          '              d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path></svg></span>\n' +
        //          '          </button>',
        //         last: '<i class="fa fa-step-forward"></i>'
        //   }
      },
      order: [5, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {

        if (this.showDemoData === true) {
          this.totalData = {
            TotalSales: 10,
            TotalProfit: 11,
            Acos: 30,
            TotalOrders: 5,
            ADSpend: 100,
            Impressions: 200,
            Clicks: 5,
            CTR: 2,
            Conversion: 4
          };
          const respAdgrouptargets = {
            draw: 4,
            recordsTotal: 10,
            recordsFiltered: 10,
            data: [
              {
                id: 188957570852602,
                campaignId: 188957570852602,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/a_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 188957570852602, CampaignName:"PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218"})\'  title="PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218"     >PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218</a></div>',
                campaignName: 'PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218',
                campaignName_short: 'PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218',
                Profit: -19.77,
                Revenue: 899.1,
                ADSpend: 438.75,
                ACOS: 48.8,
                Impressions: '305988',
                Clicks: '259',
                CTR: 0.08,
                CPC: 1.69,
                CVR: 10.81,
                Orders: 28,
                Alerts: '',
                statehtml: '<div class="dropdown   state-a ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r188957570852602==\'enabled\', \'paused\':state.r188957570852602==\'paused\', \'archived\':state.r188957570852602==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(188957570852602, \'enabled\', \'r188957570852602\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(188957570852602, \'paused\' , \'r188957570852602\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(188957570852602, \'archived\' , \'r188957570852602\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r188957570852602',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c188957570852602"    ng-model="campaigns.r188957570852602" ng-change="ADDCampaign(188957570852602)" >\n                <label class="custom-control-label" for="c188957570852602"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(188957570852602)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r188957570852602}"  ng-init="bids.r188957570852602=100.00" >\n\n                                    <input type=text" is-number ng-model="bids.r188957570852602"  ng-click="showPanel(\'r188957570852602\',\'r188957570852602\')"  ng-blur="ReturnDefault(  \'r188957570852602\', \'r188957570852602\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r188957570852602,  188957570852602 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r188957570852602,  188957570852602, \'r188957570852602\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r188957570852602\', \'r188957570852602\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '100.00',
                Status: '<span ng-bind-html="servingStatus.r188957570852602"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'188957570852602\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '305988',
                Clicks: '259',
                ADSpend: '438.75',
                Revenue: '899.10',
                Orders: '28',
                adGroupName: 'D7-I9YG-IDTK',
                adGroupId: 276958478650949,
                ACOS: 48.8,
                CVR: 10.81,
                CPC: 1.69,
                CTR: 0.08,
                Campaign: 'D7-I9YG-IDTK',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 276958478650949,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '28',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '294151',
                Clicks: '239',
                ADSpend: '409.99',
                Revenue: '869.13',
                Orders: '27',
                expressions: ' \'[{"type":"asinSubstituteRelated"}]\'',
                ACOS: 47.17,
                CVR: 11.3,
                CPC: 1.72,
                CTR: 0.08,
                keywordText: null,
                keywordId: 0,
                targetId: 33940257494590,
                expressionType: 'auto',
                TdClass: 'padding2level',
                Campaign: '<img src="/images/auto_ico.png">',
                Level: 'Target'
              },
              {
                Impressions: '5793',
                Clicks: '13',
                ADSpend: '16.08',
                Revenue: '29.97',
                Orders: '1',
                expressions: ' \'[{"type":"asinAccessoryRelated"}]\'',
                ACOS: 53.65,
                CVR: 7.69,
                CPC: 1.24,
                CTR: 0.22,
                keywordText: null,
                keywordId: 0,
                targetId: 219694580387178,
                expressionType: 'auto',
                TdClass: 'padding2level',
                Campaign: '<img src="/images/auto_ico.png">',
                Level: 'Target'
              },
              {
                Impressions: '6020',
                Clicks: '7',
                ADSpend: '12.68',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"queryBroadRelMatches"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 1.81,
                CTR: 0.12,
                keywordText: null,
                keywordId: 0,
                targetId: 279113107470003,
                expressionType: 'auto',
                TdClass: 'padding2level',
                Campaign: '<img src="/images/auto_ico.png">',
                Level: 'Target'
              },
              {
                Impressions: '30',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"queryHighRelMatches"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 20496687103643,
                expressionType: 'auto',
                TdClass: 'padding2level',
                Campaign: '<img src="/images/auto_ico.png">',
                Level: 'Target'
              },
              {
                id: 261772738199901,
                campaignId: 261772738199901,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 261772738199901, CampaignName:"PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224"})\'  title="PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224"     >PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224</a></div>',
                campaignName: 'PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224',
                campaignName_short: 'PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224',
                Profit: -6.31,
                Revenue: 59.94,
                ADSpend: 28.4,
                ACOS: 47.38,
                Impressions: '21185',
                Clicks: '31',
                CTR: 0.15,
                CPC: 0.92,
                CVR: 3.23,
                Orders: 1,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r261772738199901==\'enabled\', \'paused\':state.r261772738199901==\'paused\', \'archived\':state.r261772738199901==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(261772738199901, \'enabled\', \'r261772738199901\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(261772738199901, \'paused\' , \'r261772738199901\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(261772738199901, \'archived\' , \'r261772738199901\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div><img src="/images/scheduleActive.svg"   style="    height: 20px;    cursor: pointer;"    ng-click="ShowSchedules(261772738199901)" >  ',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r261772738199901',
                is_auto: 0,
                is_schedule: 1,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c261772738199901"    ng-model="campaigns.r261772738199901" ng-change="ADDCampaign(261772738199901)" >\n                <label class="custom-control-label" for="c261772738199901"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(261772738199901)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r261772738199901}"  ng-init="bids.r261772738199901=10.00" >\n\n                                    <input type=text" is-number ng-model="bids.r261772738199901"  ng-click="showPanel(\'r261772738199901\',\'r261772738199901\')"  ng-blur="ReturnDefault(  \'r261772738199901\', \'r261772738199901\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r261772738199901,  261772738199901 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r261772738199901,  261772738199901, \'r261772738199901\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r261772738199901\', \'r261772738199901\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '10.00',
                Status: '<span ng-bind-html="servingStatus.r261772738199901"> </span>',
                Created: '12/24/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'261772738199901\')" >LS</span>',
                PAT: '',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '18465',
                Clicks: '27',
                ADSpend: '24.79',
                Revenue: '59.94',
                Orders: '1',
                adGroupName: '4G-MK89-15GR_broad',
                adGroupId: 217808545623769,
                ACOS: 41.36,
                CVR: 3.7,
                CPC: 0.92,
                CTR: 0.15,
                Campaign: '4G-MK89-15GR_broad',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 217808545623769,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '1',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '2172',
                Clicks: '6',
                ADSpend: '5.56',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.93,
                CTR: 0.28,
                keywordText: 'cat+litter+mat',
                keywordId: 75126026308687,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat+litter+mat',
                Level: 'Target'
              },
              {
                Impressions: '1952',
                Clicks: '5',
                ADSpend: '4.93',
                Revenue: '59.94',
                Orders: '1',
                expressions: null,
                ACOS: 8.22,
                CVR: 20,
                CPC: 0.99,
                CTR: 0.26,
                keywordText: 'litter mat cat',
                keywordId: 245421071510975,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter mat cat',
                Level: 'Target'
              },
              {
                Impressions: '5244',
                Clicks: '3',
                ADSpend: '2.99',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 1,
                CTR: 0.06,
                keywordText: 'cat mat',
                keywordId: 232285021620475,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat mat',
                Level: 'Target'
              },
              {
                Impressions: '1241',
                Clicks: '2',
                ADSpend: '1.93',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.97,
                CTR: 0.16,
                keywordText: 'pet cat',
                keywordId: 6401979119421,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet cat',
                Level: 'Target'
              },
              {
                Impressions: '1005',
                Clicks: '2',
                ADSpend: '1.92',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.96,
                CTR: 0.2,
                keywordText: 'jumbo cat litter',
                keywordId: 59205066302458,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo cat litter',
                Level: 'Target'
              },
              {
                Impressions: '1209',
                Clicks: '2',
                ADSpend: '1.59',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.8,
                CTR: 0.17,
                keywordText: 'jumbo mat',
                keywordId: 173542521547842,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo mat',
                Level: 'Target'
              },
              {
                Impressions: '396',
                Clicks: '2',
                ADSpend: '1.50',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.75,
                CTR: 0.51,
                keywordText: 'pet litter',
                keywordId: 4579763441651,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet litter',
                Level: 'Target'
              },
              {
                Impressions: '531',
                Clicks: '1',
                ADSpend: '1.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 1,
                CTR: 0.19,
                keywordText: 'pet mat',
                keywordId: 14882573243506,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet mat',
                Level: 'Target'
              },
              {
                Impressions: '375',
                Clicks: '1',
                ADSpend: '0.99',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.99,
                CTR: 0.27,
                keywordText: 'pet litter mat',
                keywordId: 212341327522856,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet litter mat',
                Level: 'Target'
              },
              {
                Impressions: '1055',
                Clicks: '1',
                ADSpend: '0.98',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.98,
                CTR: 0.09,
                keywordText: 'light cat litter',
                keywordId: 124228332079584,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'light cat litter',
                Level: 'Target'
              },
              {
                Impressions: '807',
                Clicks: '1',
                ADSpend: '0.97',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.97,
                CTR: 0.12,
                keywordText: 'non',
                keywordId: 143431165696918,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'non',
                Level: 'Target'
              },
              {
                Impressions: '28',
                Clicks: '1',
                ADSpend: '0.43',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.43,
                CTR: 3.57,
                keywordText: 'yellow mat',
                keywordId: 278734765187979,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'yellow mat',
                Level: 'Target'
              },
              {
                Impressions: '49',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter mats',
                keywordId: 3387244126071,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter mats',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'jumbo litter box',
                keywordId: 3793693139756,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo litter box',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'mat litter box',
                keywordId: 20148794538185,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'mat litter box',
                Level: 'Target'
              },
              {
                Impressions: '563',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat mat litter',
                keywordId: 29209539961141,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat mat litter',
                Level: 'Target'
              },
              {
                Impressions: '32',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet cat litter mat',
                keywordId: 30556573712298,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet cat litter mat',
                Level: 'Target'
              },
              {
                Impressions: '58',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet mat cat',
                keywordId: 47784551233429,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet mat cat',
                Level: 'Target'
              },
              {
                Impressions: '4',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'kw pet',
                keywordId: 56794252961413,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'kw pet',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'size yellow',
                keywordId: 68226534832783,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'size yellow',
                Level: 'Target'
              },
              {
                Impressions: '7',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'non toxic cat litter',
                keywordId: 68818221906463,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'non toxic cat litter',
                Level: 'Target'
              },
              {
                Impressions: '4',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'mat light',
                keywordId: 81458468360162,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'mat light',
                Level: 'Target'
              },
              {
                Impressions: '260',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'yellow cat',
                keywordId: 88973555656153,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'yellow cat',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large cat mat',
                keywordId: 99296927488484,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large cat mat',
                Level: 'Target'
              },
              {
                Impressions: '78',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'yellow light',
                keywordId: 99773647117033,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'yellow light',
                Level: 'Target'
              },
              {
                CTR: '-',
                CPC: '-',
                CVR: '-',
                ACOS: '-',
                adGroupId: 217808545623769,
                adGroupName: '4G-MK89-15GR_broad',
                TdClass: 'padding2level',
                Campaign: 'Show more ',
                Level: 'TargetMore'
              },
              {
                Impressions: '2156',
                Clicks: '4',
                ADSpend: '3.61',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '4G-MK89-15GR_phrase',
                adGroupId: 140675178458421,
                ACOS: 0,
                CVR: 0,
                CPC: 0.9,
                CTR: 0.19,
                Campaign: '4G-MK89-15GR_phrase',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 140675178458421,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '1079',
                Clicks: '2',
                ADSpend: '2.31',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 1.16,
                CTR: 0.19,
                keywordText: 'pet mat',
                keywordId: 190986097617253,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet mat',
                Level: 'Target'
              },
              {
                Impressions: '863',
                Clicks: '1',
                ADSpend: '0.98',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.98,
                CTR: 0.12,
                keywordText: 'litter box mat',
                keywordId: 121876684437623,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter box mat',
                Level: 'Target'
              },
              {
                Impressions: '32',
                Clicks: '1',
                ADSpend: '0.32',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.32,
                CTR: 3.13,
                keywordText: 'litter mats',
                keywordId: 205541386482943,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter mats',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter clay',
                keywordId: 25298330470174,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter clay',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cats pride litter',
                keywordId: 30046209674881,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cats pride litter',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter scoop',
                keywordId: 34229385679078,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter scoop',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter pan',
                keywordId: 55509601120554,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter pan',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat box furniture',
                keywordId: 59860865701954,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat box furniture',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter clean',
                keywordId: 68538911653137,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter clean',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat tree',
                keywordId: 70212220021498,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat tree',
                Level: 'Target'
              },
              {
                Impressions: '83',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'at litter',
                keywordId: 77100204433480,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'at litter',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'mat',
                keywordId: 78216881672653,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'mat',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter cat\'s pride',
                keywordId: 96402001041148,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter cat\'s pride',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter lightweight',
                keywordId: 114455475149994,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter lightweight',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter carpet',
                keywordId: 117273957513535,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter carpet',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter tray',
                keywordId: 118290985519003,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter tray',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat houses',
                keywordId: 121853808441708,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat houses',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'jumbo litter box',
                keywordId: 132686684144970,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo litter box',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter febreze',
                keywordId: 133140411784825,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter febreze',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter pellets',
                keywordId: 150979742984327,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter pellets',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat matt',
                keywordId: 153387919865929,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat matt',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'tidy cat',
                keywordId: 156355435321275,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'tidy cat',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cats',
                keywordId: 172607677676563,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cats',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large litter box',
                keywordId: 173644927218786,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large litter box',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat sand',
                keywordId: 194618541481538,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat sand',
                Level: 'Target'
              },
              {
                CTR: '-',
                CPC: '-',
                CVR: '-',
                ACOS: '-',
                adGroupId: 140675178458421,
                adGroupName: '4G-MK89-15GR_phrase',
                TdClass: 'padding2level',
                Campaign: 'Show more ',
                Level: 'TargetMore'
              },
              {
                Impressions: '564',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '4G-MK89-15GR_exact',
                adGroupId: 191054536856136,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: '4G-MK89-15GR_exact',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 191054536856136,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cats',
                keywordId: 2113616265279,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cats',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat rugs for litter',
                keywordId: 13288755518296,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat rugs for litter',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'dr.elseys cat litter',
                keywordId: 50270654010042,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'dr.elseys cat litter',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'perfect cat litter',
                keywordId: 57761065686505,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'perfect cat litter',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter tidy cats',
                keywordId: 57819073692538,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter tidy cats',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat attract litter',
                keywordId: 66439389276089,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat attract litter',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter scoop',
                keywordId: 73930462836837,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter scoop',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'betterway cat litter',
                keywordId: 77139296472601,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'betterway cat litter',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'precious cat litter',
                keywordId: 84385477532600,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'precious cat litter',
                Level: 'Target'
              },
              {
                Impressions: '4',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'scoopable cat litter',
                keywordId: 86297843503813,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'scoopable cat litter',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'silica cat litter',
                keywordId: 89278435516791,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'silica cat litter',
                Level: 'Target'
              },
              {
                Impressions: '423',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'lightweight cat litter',
                keywordId: 103243266148810,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'lightweight cat litter',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter box large',
                keywordId: 111789128239911,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter box large',
                Level: 'Target'
              },
              {
                Impressions: '40',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet mat',
                keywordId: 119547309733608,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet mat',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'mat',
                keywordId: 120955185975667,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'mat',
                Level: 'Target'
              },
              {
                Impressions: '30',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter pans',
                keywordId: 134580406681553,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter pans',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'outdoor cat houses',
                keywordId: 158336394483611,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'outdoor cat houses',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'tidy cats litter',
                keywordId: 162274910398292,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'tidy cats litter',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter carpet',
                keywordId: 182914197734709,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter carpet',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter tray',
                keywordId: 197008622657646,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter tray',
                Level: 'Target'
              },
              {
                Impressions: '22',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'extra large litter mat for cats',
                keywordId: 197253725884779,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'extra large litter mat for cats',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter slide',
                keywordId: 206998860495000,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter slide',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cats pride litter',
                keywordId: 207240132943373,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cats pride litter',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'modkat litter box',
                keywordId: 218240605091680,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'modkat litter box',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'natural cat litter',
                keywordId: 221674389602938,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'natural cat litter',
                Level: 'Target'
              },
              {
                CTR: '-',
                CPC: '-',
                CVR: '-',
                ACOS: '-',
                adGroupId: 191054536856136,
                adGroupName: '4G-MK89-15GR_exact',
                TdClass: 'padding2level',
                Campaign: 'Show more ',
                Level: 'TargetMore'
              },
              {
                id: 208258318022604,
                campaignId: 208258318022604,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 208258318022604, CampaignName:"PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218"})\'  title="PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218"     >PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218</a></div>',
                campaignName: 'PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218',
                campaignName_short: 'PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218',
                Profit: 5.56,
                Revenue: 59.94,
                ADSpend: 22.37,
                ACOS: 37.32,
                Impressions: '10373',
                Clicks: '25',
                CTR: 0.24,
                CPC: 0.89,
                CVR: 8,
                Orders: 2,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r208258318022604==\'enabled\', \'paused\':state.r208258318022604==\'paused\', \'archived\':state.r208258318022604==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(208258318022604, \'enabled\', \'r208258318022604\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(208258318022604, \'paused\' , \'r208258318022604\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(208258318022604, \'archived\' , \'r208258318022604\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r208258318022604',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c208258318022604"    ng-model="campaigns.r208258318022604" ng-change="ADDCampaign(208258318022604)" >\n                <label class="custom-control-label" for="c208258318022604"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(208258318022604)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r208258318022604}"  ng-init="bids.r208258318022604=100.00" >\n\n                                    <input type=text" is-number ng-model="bids.r208258318022604"  ng-click="showPanel(\'r208258318022604\',\'r208258318022604\')"  ng-blur="ReturnDefault(  \'r208258318022604\', \'r208258318022604\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r208258318022604,  208258318022604 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r208258318022604,  208258318022604, \'r208258318022604\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r208258318022604\', \'r208258318022604\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '100.00',
                Status: '<span ng-bind-html="servingStatus.r208258318022604"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'208258318022604\')" >LS</span>',
                PAT: '',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '2857',
                Clicks: '12',
                ADSpend: '10.53',
                Revenue: '29.97',
                Orders: '1',
                adGroupName: 'D7-I9YG-IDTK_broad',
                adGroupId: 158798992864612,
                ACOS: 35.14,
                CVR: 8.33,
                CPC: 0.88,
                CTR: 0.42,
                Campaign: 'D7-I9YG-IDTK_broad',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 158798992864612,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '1',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '342',
                Clicks: '2',
                ADSpend: '1.74',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.87,
                CTR: 0.58,
                keywordText: 'non toxic',
                keywordId: 273433878927689,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'non toxic',
                Level: 'Target'
              },
              {
                Impressions: '442',
                Clicks: '2',
                ADSpend: '1.69',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.85,
                CTR: 0.45,
                keywordText: 'non',
                keywordId: 146336846413620,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'non',
                Level: 'Target'
              },
              {
                Impressions: '70',
                Clicks: '2',
                ADSpend: '1.61',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.81,
                CTR: 2.86,
                keywordText: 'jumbo mat',
                keywordId: 115160626841826,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo mat',
                Level: 'Target'
              },
              {
                Impressions: '48',
                Clicks: '2',
                ADSpend: '1.44',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.72,
                CTR: 4.17,
                keywordText: 'jumbo pet',
                keywordId: 161137232813029,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo pet',
                Level: 'Target'
              },
              {
                Impressions: '154',
                Clicks: '1',
                ADSpend: '1.21',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 1.21,
                CTR: 0.65,
                keywordText: 'jumbo litter',
                keywordId: 58202128386614,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo litter',
                Level: 'Target'
              },
              {
                Impressions: '85',
                Clicks: '1',
                ADSpend: '1.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 1,
                CTR: 1.18,
                keywordText: 'mat non',
                keywordId: 95671159553290,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'mat non',
                Level: 'Target'
              },
              {
                Impressions: '9',
                Clicks: '1',
                ADSpend: '0.94',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.94,
                CTR: 11.11,
                keywordText: 'cat litter jumbo',
                keywordId: 179994820697730,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter jumbo',
                Level: 'Target'
              },
              {
                Impressions: '30',
                Clicks: '1',
                ADSpend: '0.90',
                Revenue: '29.97',
                Orders: '1',
                expressions: null,
                ACOS: 3,
                CVR: 100,
                CPC: 0.9,
                CTR: 3.33,
                keywordText: 'jumbo cat mat',
                keywordId: 88906264037592,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo cat mat',
                Level: 'Target'
              },
              {
                Impressions: '255',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'gray cat',
                keywordId: 41902684115309,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'gray cat',
                Level: 'Target'
              },
              {
                Impressions: '155',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'jumbo cat',
                keywordId: 44198553347772,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo cat',
                Level: 'Target'
              },
              {
                Impressions: '22',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'non toxic mat',
                keywordId: 67525865881911,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'non toxic mat',
                Level: 'Target'
              },
              {
                Impressions: '387',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet litter',
                keywordId: 79344326096686,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet litter',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet mat gray',
                keywordId: 91835481563306,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet mat gray',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat mat jumbo',
                keywordId: 120869835206971,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat mat jumbo',
                Level: 'Target'
              },
              {
                Impressions: '16',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter mats large',
                keywordId: 123194108463797,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter mats large',
                Level: 'Target'
              },
              {
                Impressions: '37',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'gray mat',
                keywordId: 131486384727088,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'gray mat',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'inch non',
                keywordId: 150590003746362,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'inch non',
                Level: 'Target'
              },
              {
                Impressions: '488',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'kitty mat',
                keywordId: 151512748336062,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'kitty mat',
                Level: 'Target'
              },
              {
                Impressions: '58',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet mat cat',
                keywordId: 166710990067309,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet mat cat',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet cat litter',
                keywordId: 190385224119930,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet cat litter',
                Level: 'Target'
              },
              {
                Impressions: '113',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'jumbo size',
                keywordId: 196544127249373,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo size',
                Level: 'Target'
              },
              {
                Impressions: '42',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet cat',
                keywordId: 234738294439764,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet cat',
                Level: 'Target'
              },
              {
                Impressions: '27',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet size',
                keywordId: 256161445891344,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet size',
                Level: 'Target'
              },
              {
                Impressions: '66',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'size cat',
                keywordId: 274217957380991,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'size cat',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'inch gray',
                keywordId: 278964390597745,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'inch gray',
                Level: 'Target'
              },
              {
                CTR: '-',
                CPC: '-',
                CVR: '-',
                ACOS: '-',
                adGroupId: 158798992864612,
                adGroupName: 'D7-I9YG-IDTK_broad',
                TdClass: 'padding2level',
                Campaign: 'Show more ',
                Level: 'TargetMore'
              },
              {
                Impressions: '2722',
                Clicks: '6',
                ADSpend: '5.97',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 149617006457057,
                ACOS: 0,
                CVR: 0,
                CPC: 1,
                CTR: 0.22,
                Campaign: 'D7-I9YG-IDTK_exact',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 149617006457057,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '26',
                Clicks: '1',
                ADSpend: '1.80',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 1.8,
                CTR: 3.85,
                keywordText: 'extra large litter mat for cats',
                keywordId: 146445065319199,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'extra large litter mat for cats',
                Level: 'Target'
              },
              {
                Impressions: '1280',
                Clicks: '2',
                ADSpend: '1.62',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.81,
                CTR: 0.16,
                keywordText: 'lightweight cat litter',
                keywordId: 223457379594518,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'lightweight cat litter',
                Level: 'Target'
              },
              {
                Impressions: '67',
                Clicks: '1',
                ADSpend: '1.10',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 1.1,
                CTR: 1.49,
                keywordText: 'pet mat',
                keywordId: 91403625690473,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet mat',
                Level: 'Target'
              },
              {
                Impressions: '97',
                Clicks: '1',
                ADSpend: '0.86',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.86,
                CTR: 1.03,
                keywordText: 'cat litter pans',
                keywordId: 21710795405199,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter pans',
                Level: 'Target'
              },
              {
                Impressions: '160',
                Clicks: '1',
                ADSpend: '0.59',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.59,
                CTR: 0.63,
                keywordText: 'cat',
                keywordId: 250092939554790,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'box cat',
                keywordId: 1760110629280,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'box cat',
                Level: 'Target'
              },
              {
                Impressions: '18',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large cat litter box',
                keywordId: 19375259588386,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large cat litter box',
                Level: 'Target'
              },
              {
                Impressions: '26',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'silica cat litter',
                keywordId: 25238633782235,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'silica cat litter',
                Level: 'Target'
              },
              {
                Impressions: '246',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large litter box mat',
                keywordId: 34083206233199,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large litter box mat',
                Level: 'Target'
              },
              {
                Impressions: '19',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'modkat litter box',
                keywordId: 36030855300221,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'modkat litter box',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter container',
                keywordId: 42101399196114,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter container',
                Level: 'Target'
              },
              {
                Impressions: '9',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'clumping cat litter',
                keywordId: 45608100277252,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'clumping cat litter',
                Level: 'Target'
              },
              {
                Impressions: '7',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat covered litter box',
                keywordId: 46957417498867,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat covered litter box',
                Level: 'Target'
              },
              {
                Impressions: '24',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter carpet',
                keywordId: 51828506514697,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter carpet',
                Level: 'Target'
              },
              {
                Impressions: '127',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat box furniture',
                keywordId: 53254328987342,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat box furniture',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'scoopable kitty litter',
                keywordId: 54699580036078,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'scoopable kitty litter',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'kitty litter scoop',
                keywordId: 67190075502040,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'kitty litter scoop',
                Level: 'Target'
              },
              {
                Impressions: '8',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter pellets',
                keywordId: 70178879338541,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter pellets',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'hooded litter box',
                keywordId: 87926946138795,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'hooded litter box',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'perfect cat litter',
                keywordId: 98288166403482,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'perfect cat litter',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter tidy cats',
                keywordId: 114927190421929,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter tidy cats',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'natural cat litter',
                keywordId: 125806329472314,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'natural cat litter',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'tidy cats litter',
                keywordId: 126187083231891,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'tidy cats litter',
                Level: 'Target'
              },
              {
                Impressions: '240',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large litter box',
                keywordId: 156772940903465,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large litter box',
                Level: 'Target'
              },
              {
                Impressions: '7',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'slide cat litter',
                keywordId: 162459267106815,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'slide cat litter',
                Level: 'Target'
              },
              {
                CTR: '-',
                CPC: '-',
                CVR: '-',
                ACOS: '-',
                adGroupId: 149617006457057,
                adGroupName: 'D7-I9YG-IDTK_exact',
                TdClass: 'padding2level',
                Campaign: 'Show more ',
                Level: 'TargetMore'
              },
              {
                Impressions: '4794',
                Clicks: '7',
                ADSpend: '5.87',
                Revenue: '29.97',
                Orders: '1',
                adGroupName: 'D7-I9YG-IDTK_phrase',
                adGroupId: 30129559784098,
                ACOS: 19.59,
                CVR: 14.29,
                CPC: 0.84,
                CTR: 0.15,
                Campaign: 'D7-I9YG-IDTK_phrase',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 30129559784098,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '1',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '1265',
                Clicks: '4',
                ADSpend: '4.61',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 1.15,
                CTR: 0.32,
                keywordText: 'pet mat',
                keywordId: 171111517801137,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet mat',
                Level: 'Target'
              },
              {
                Impressions: '1908',
                Clicks: '1',
                ADSpend: '0.59',
                Revenue: '29.97',
                Orders: '1',
                expressions: null,
                ACOS: 1.97,
                CVR: 100,
                CPC: 0.59,
                CTR: 0.05,
                keywordText: 'cat',
                keywordId: 166600502763923,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat',
                Level: 'Target'
              },
              {
                Impressions: '631',
                Clicks: '1',
                ADSpend: '0.35',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.35,
                CTR: 0.16,
                keywordText: 'litter boxes',
                keywordId: 84893268025800,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter boxes',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '1',
                ADSpend: '0.32',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.32,
                CTR: 33.33,
                keywordText: 'cat litter tray',
                keywordId: 15536620936199,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter tray',
                Level: 'Target'
              },
              {
                Impressions: '642',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large litter box',
                keywordId: 9359598757023,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large litter box',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter lightweight',
                keywordId: 16203198069404,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter lightweight',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large cat litter box',
                keywordId: 19115413275325,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large cat litter box',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter pads',
                keywordId: 43340345533147,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter pads',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'jumbo easyology',
                keywordId: 50886134077070,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo easyology',
                Level: 'Target'
              },
              {
                Impressions: '31',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'kitty litter',
                keywordId: 51003498144528,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'kitty litter',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter box jumbo',
                keywordId: 58153967437299,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter box jumbo',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet litter mat',
                keywordId: 68633294885153,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet litter mat',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter box pads',
                keywordId: 73721771397232,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter box pads',
                Level: 'Target'
              },
              {
                Impressions: '33',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'at litter',
                keywordId: 76494811243958,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'at litter',
                Level: 'Target'
              },
              {
                Impressions: '10',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large litter box mat',
                keywordId: 89718108203603,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large litter box mat',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'mat',
                keywordId: 89846172741609,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'mat',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat condos and towers',
                keywordId: 119273903225506,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat condos and towers',
                Level: 'Target'
              },
              {
                Impressions: '22',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat box furniture',
                keywordId: 130460975711035,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat box furniture',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat boxes',
                keywordId: 132621355876872,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat boxes',
                Level: 'Target'
              },
              {
                Impressions: '7',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter pan',
                keywordId: 134415585125008,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter pan',
                Level: 'Target'
              },
              {
                Impressions: '11',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'kitty litter box',
                keywordId: 139609302803820,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'kitty litter box',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet-litter',
                keywordId: 159698936385490,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet-litter',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat genie',
                keywordId: 211985870508923,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat genie',
                Level: 'Target'
              },
              {
                Impressions: '10',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat mat',
                keywordId: 216070748303408,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat mat',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter carpet',
                keywordId: 230265501626949,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter carpet',
                Level: 'Target'
              },
              {
                CTR: '-',
                CPC: '-',
                CVR: '-',
                ACOS: '-',
                adGroupId: 30129559784098,
                adGroupName: 'D7-I9YG-IDTK_phrase',
                TdClass: 'padding2level',
                Campaign: 'Show more ',
                Level: 'TargetMore'
              },
              {
                id: 102205254730768,
                campaignId: 102205254730768,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 102205254730768, CampaignName:"PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218"})\'  title="PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218"     >PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218</a></div>',
                campaignName: 'PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218',
                campaignName_short: 'PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218',
                Profit: -6.57,
                Revenue: 29.97,
                ADSpend: 20.54,
                ACOS: 68.54,
                Impressions: '19124',
                Clicks: '22',
                CTR: 0.12,
                CPC: 0.93,
                CVR: 4.55,
                Orders: 1,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r102205254730768==\'enabled\', \'paused\':state.r102205254730768==\'paused\', \'archived\':state.r102205254730768==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(102205254730768, \'enabled\', \'r102205254730768\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(102205254730768, \'paused\' , \'r102205254730768\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(102205254730768, \'archived\' , \'r102205254730768\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r102205254730768',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c102205254730768"    ng-model="campaigns.r102205254730768" ng-change="ADDCampaign(102205254730768)" >\n                <label class="custom-control-label" for="c102205254730768"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(102205254730768)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r102205254730768}"  ng-init="bids.r102205254730768=10.00" >\n\n                                    <input type=text" is-number ng-model="bids.r102205254730768"  ng-click="showPanel(\'r102205254730768\',\'r102205254730768\')"  ng-blur="ReturnDefault(  \'r102205254730768\', \'r102205254730768\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r102205254730768,  102205254730768 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r102205254730768,  102205254730768, \'r102205254730768\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r102205254730768\', \'r102205254730768\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '10.00',
                Status: '<span ng-bind-html="servingStatus.r102205254730768"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'102205254730768\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '4959',
                Clicks: '11',
                ADSpend: '10.63',
                Revenue: '29.97',
                Orders: '1',
                adGroupName: 'D7-I9YG-IDTK_PAT_ASINS',
                adGroupId: 248105359224653,
                ACOS: 35.47,
                CVR: 9.09,
                CPC: 0.97,
                CTR: 0.22,
                Campaign: 'D7-I9YG-IDTK_PAT_ASINS',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 248105359224653,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '1',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '4182',
                Clicks: '8',
                ADSpend: '7.74',
                Revenue: '29.97',
                Orders: '1',
                expressions: ' \'[{"type":"asinSameAs","value":"B007IRMTXE"}]\'',
                ACOS: 25.83,
                CVR: 12.5,
                CPC: 0.97,
                CTR: 0.19,
                keywordText: null,
                keywordId: 0,
                targetId: 174670979615121,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B007IRMTXE" target="_blank"  class="asinLink"  >B007IRMTXE  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '121',
                Clicks: '2',
                ADSpend: '1.99',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B06XYWJZNN"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 1,
                CTR: 1.65,
                keywordText: null,
                keywordId: 0,
                targetId: 9680734637544,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B06XYWJZNN" target="_blank"  class="asinLink"  >B06XYWJZNN  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '418',
                Clicks: '1',
                ADSpend: '0.90',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B001DCAAP4"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0.9,
                CTR: 0.24,
                keywordText: null,
                keywordId: 0,
                targetId: 6351535958597,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B001DCAAP4" target="_blank"  class="asinLink"  >B001DCAAP4  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07259MVP4"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 5201816283128,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07259MVP4" target="_blank"  class="asinLink"  >B07259MVP4  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B0711C6PWJ"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 11721941208043,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B0711C6PWJ" target="_blank"  class="asinLink"  >B0711C6PWJ  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01MSX0VDL"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 12050114900350,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01MSX0VDL" target="_blank"  class="asinLink"  >B01MSX0VDL  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07FCX3MSG"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 23739245681035,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07FCX3MSG" target="_blank"  class="asinLink"  >B07FCX3MSG  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01M189W6F"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 23979404333757,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01M189W6F" target="_blank"  class="asinLink"  >B01M189W6F  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '21',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B071ZFY73R"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 35536445618794,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B071ZFY73R" target="_blank"  class="asinLink"  >B071ZFY73R  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '39',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01AUU90K0"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 75150860222267,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01AUU90K0" target="_blank"  class="asinLink"  >B01AUU90K0  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '4',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01N97D4TL"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 82788291362927,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01N97D4TL" target="_blank"  class="asinLink"  >B01N97D4TL  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '53',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01CEU8W1C"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 88554921189827,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01CEU8W1C" target="_blank"  class="asinLink"  >B01CEU8W1C  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '7',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01M4HASE4"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 89328485073145,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01M4HASE4" target="_blank"  class="asinLink"  >B01M4HASE4  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B0158HNAY2"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 118686839571622,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B0158HNAY2" target="_blank"  class="asinLink"  >B0158HNAY2  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07F46NCSM"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 160931291059085,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07F46NCSM" target="_blank"  class="asinLink"  >B07F46NCSM  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B079HFKJNG"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 167486229086312,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B079HFKJNG" target="_blank"  class="asinLink"  >B079HFKJNG  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B06X6BRR86"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 168584685468364,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B06X6BRR86" target="_blank"  class="asinLink"  >B06X6BRR86  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '32',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B0719V735F"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 173486916068553,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B0719V735F" target="_blank"  class="asinLink"  >B0719V735F  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01JLCDHRA"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 191621647422828,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01JLCDHRA" target="_blank"  class="asinLink"  >B01JLCDHRA  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01KEMXHS0"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 192234474584060,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01KEMXHS0" target="_blank"  class="asinLink"  >B01KEMXHS0  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07L9S7VQM"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 209199531926511,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07L9S7VQM" target="_blank"  class="asinLink"  >B07L9S7VQM  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '11',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01MY1764B"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 213150305097628,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01MY1764B" target="_blank"  class="asinLink"  >B01MY1764B  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B014IOL3UO"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 215360030528867,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B014IOL3UO" target="_blank"  class="asinLink"  >B014IOL3UO  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07LDSYDCY"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 229590422749850,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07LDSYDCY" target="_blank"  class="asinLink"  >B07LDSYDCY  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '14',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B075CV6N6B"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 237063405399883,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B075CV6N6B" target="_blank"  class="asinLink"  >B075CV6N6B  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                CTR: '-',
                CPC: '-',
                CVR: '-',
                ACOS: '-',
                adGroupId: 248105359224653,
                adGroupName: 'D7-I9YG-IDTK_PAT_ASINS',
                TdClass: 'padding2level',
                Campaign: 'Show more ',
                Level: 'TargetMore'
              },
              {
                Impressions: '14165',
                Clicks: '11',
                ADSpend: '9.91',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'D7-I9YG-IDTK_PAT_CATEGORIES',
                adGroupId: 44842580523576,
                ACOS: 0,
                CVR: 0,
                CPC: 0.9,
                CTR: 0.08,
                Campaign: 'D7-I9YG-IDTK_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 44842580523576,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '13576',
                Clicks: '11',
                ADSpend: '9.91',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"2975296011"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0.9,
                CTR: 0.08,
                keywordText: null,
                keywordId: 0,
                targetId: 257617422466020,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=2975296011" target="_blank"  class="asinLink"  >Cat Litter & Housebreaking  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                Impressions: '589',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"2975241011"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 224010299660927,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=2975241011" target="_blank"  class="asinLink"  >Cat Supplies  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                id: 132131152914714,
                campaignId: 132131152914714,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 132131152914714, CampaignName:"PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218"})\'  title="PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218"     >PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218</a></div>',
                campaignName: 'PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218',
                campaignName_short: 'PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218',
                Profit: -12.82,
                Revenue: 0,
                ADSpend: 12.82,
                ACOS: 0,
                Impressions: '6187',
                Clicks: '16',
                CTR: 0.26,
                CPC: 0.8,
                CVR: 0,
                Orders: 0,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r132131152914714==\'enabled\', \'paused\':state.r132131152914714==\'paused\', \'archived\':state.r132131152914714==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(132131152914714, \'enabled\', \'r132131152914714\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(132131152914714, \'paused\' , \'r132131152914714\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(132131152914714, \'archived\' , \'r132131152914714\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r132131152914714',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c132131152914714"    ng-model="campaigns.r132131152914714" ng-change="ADDCampaign(132131152914714)" >\n                <label class="custom-control-label" for="c132131152914714"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(132131152914714)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r132131152914714}"  ng-init="bids.r132131152914714=100.00" >\n\n                                    <input type=text" is-number ng-model="bids.r132131152914714"  ng-click="showPanel(\'r132131152914714\',\'r132131152914714\')"  ng-blur="ReturnDefault(  \'r132131152914714\', \'r132131152914714\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r132131152914714,  132131152914714 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r132131152914714,  132131152914714, \'r132131152914714\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r132131152914714\', \'r132131152914714\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '100.00',
                Status: '<span ng-bind-html="servingStatus.r132131152914714"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'132131152914714\')" >LS</span>',
                PAT: '',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '6181',
                Clicks: '16',
                ADSpend: '12.82',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'D7-I9YG-IDTK_broad',
                adGroupId: 214007996512462,
                ACOS: 0,
                CVR: 0,
                CPC: 0.8,
                CTR: 0.26,
                Campaign: 'D7-I9YG-IDTK_broad',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 214007996512462,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '3504',
                Clicks: '10',
                ADSpend: '6.60',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.66,
                CTR: 0.29,
                keywordText: 'box cat',
                keywordId: 235167338915182,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'box cat',
                Level: 'Target'
              },
              {
                Impressions: '341',
                Clicks: '3',
                ADSpend: '3.62',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 1.21,
                CTR: 0.88,
                keywordText: 'pet mat',
                keywordId: 225330202714542,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet mat',
                Level: 'Target'
              },
              {
                Impressions: '1301',
                Clicks: '3',
                ADSpend: '2.60',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0.87,
                CTR: 0.23,
                keywordText: 'large litter box',
                keywordId: 208443421431673,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large litter box',
                Level: 'Target'
              },
              {
                Impressions: '25',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter cat',
                keywordId: 675307061077,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter cat',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'jumbo cat litter box',
                keywordId: 23582624762228,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'jumbo cat litter box',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'pet-litter',
                keywordId: 44237185222162,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'pet-litter',
                Level: 'Target'
              },
              {
                Impressions: '7',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat mats',
                keywordId: 45132387395149,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat mats',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'x large litter mat',
                keywordId: 56193799455035,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'x large litter mat',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat boxes',
                keywordId: 59700316767623,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat boxes',
                Level: 'Target'
              },
              {
                Impressions: '4',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'mat',
                keywordId: 60428865182089,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'mat',
                Level: 'Target'
              },
              {
                Impressions: '201',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat',
                keywordId: 67661437446853,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat',
                Level: 'Target'
              },
              {
                Impressions: '12',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'kitty litter',
                keywordId: 87246336163294,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'kitty litter',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat houses',
                keywordId: 140089493518704,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat houses',
                Level: 'Target'
              },
              {
                Impressions: '10',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'at litter',
                keywordId: 186608502688011,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'at litter',
                Level: 'Target'
              },
              {
                Impressions: '12',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'kitty litter box',
                keywordId: 196986807759379,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'kitty litter box',
                Level: 'Target'
              },
              {
                Impressions: '657',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter boxes',
                keywordId: 197941919490440,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter boxes',
                Level: 'Target'
              },
              {
                Impressions: '63',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large cat litter box',
                keywordId: 220206565655796,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large cat litter box',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large cat box',
                keywordId: 236171466425719,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large cat box',
                Level: 'Target'
              },
              {
                Impressions: '12',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'litter box jumbo',
                keywordId: 237334330359337,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'litter box jumbo',
                Level: 'Target'
              },
              {
                Impressions: '22',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'large litter box mat',
                keywordId: 253920678186791,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'large litter box mat',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'mats for pet',
                keywordId: 263026852509302,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'mats for pet',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: 'D7-I9YG-IDTK_exact',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: null,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: 'cat litter kit',
                keywordId: 249348662930087,
                targetId: 0,
                expressionType: null,
                TdClass: 'padding2level',
                Campaign: 'cat litter kit',
                Level: 'Target'
              },
              {
                id: 240451908552662,
                campaignId: 240451908552662,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 240451908552662, CampaignName:"PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218"})\'  title="PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218"     >PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218</a></div>',
                campaignName: 'PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218',
                campaignName_short: 'PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218',
                Profit: -10.65,
                Revenue: 0,
                ADSpend: 10.65,
                ACOS: 0,
                Impressions: '10863',
                Clicks: '13',
                CTR: 0.12,
                CPC: 0.82,
                CVR: 0,
                Orders: 0,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r240451908552662==\'enabled\', \'paused\':state.r240451908552662==\'paused\', \'archived\':state.r240451908552662==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(240451908552662, \'enabled\', \'r240451908552662\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(240451908552662, \'paused\' , \'r240451908552662\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(240451908552662, \'archived\' , \'r240451908552662\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r240451908552662',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c240451908552662"    ng-model="campaigns.r240451908552662" ng-change="ADDCampaign(240451908552662)" >\n                <label class="custom-control-label" for="c240451908552662"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(240451908552662)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r240451908552662}"  ng-init="bids.r240451908552662=10.00" >\n\n                                    <input type=text" is-number ng-model="bids.r240451908552662"  ng-click="showPanel(\'r240451908552662\',\'r240451908552662\')"  ng-blur="ReturnDefault(  \'r240451908552662\', \'r240451908552662\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r240451908552662,  240451908552662 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r240451908552662,  240451908552662, \'r240451908552662\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r240451908552662\', \'r240451908552662\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '10.00',
                Status: '<span ng-bind-html="servingStatus.r240451908552662"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'240451908552662\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '10598',
                Clicks: '13',
                ADSpend: '10.65',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '3K-CVS8-4O1B_PAT_CATEGORIES',
                adGroupId: 45091806970607,
                ACOS: 0,
                CVR: 0,
                CPC: 0.82,
                CTR: 0.12,
                Campaign: '3K-CVS8-4O1B_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 45091806970607,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '5969',
                Clicks: '10',
                ADSpend: '7.48',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"17295526011"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0.75,
                CTR: 0.17,
                keywordText: null,
                keywordId: 0,
                targetId: 259001689991015,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=17295526011" target="_blank"  class="asinLink"  >Cat Litter Mats  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                Impressions: '3497',
                Clicks: '3',
                ADSpend: '3.17',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"2975296011"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 1.06,
                CTR: 0.09,
                keywordText: null,
                keywordId: 0,
                targetId: 228673197585407,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=2975296011" target="_blank"  class="asinLink"  >Cat Litter & Housebreaking  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                Impressions: '1134',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"2975241011"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 82883184379276,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=2975241011" target="_blank"  class="asinLink"  >Cat Supplies  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                Impressions: '265',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '3K-CVS8-4O1B_PAT_ASINS',
                adGroupId: 241108390169214,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: '3K-CVS8-4O1B_PAT_ASINS',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 241108390169214,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B074C8XJQF"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 44431574248331,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B074C8XJQF" target="_blank"  class="asinLink"  >B074C8XJQF  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '10',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07BCGWLP2"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 49924022322285,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07BCGWLP2" target="_blank"  class="asinLink"  >B07BCGWLP2  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B074CHDDPC"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 64530025225905,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B074CHDDPC" target="_blank"  class="asinLink"  >B074CHDDPC  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '32',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B00H3R3JYW"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 75952212437541,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B00H3R3JYW" target="_blank"  class="asinLink"  >B00H3R3JYW  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B074C7TF7P"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 107635353576388,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B074C7TF7P" target="_blank"  class="asinLink"  >B074C7TF7P  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '4',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B074CDM1QM"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 111490674447809,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B074CDM1QM" target="_blank"  class="asinLink"  >B074CDM1QM  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07CQGF24V"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 130980037397657,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07CQGF24V" target="_blank"  class="asinLink"  >B07CQGF24V  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '191',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01BFOHQEC"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 150565969781168,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01BFOHQEC" target="_blank"  class="asinLink"  >B01BFOHQEC  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B0719H5PS3"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 167133081765421,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B0719H5PS3" target="_blank"  class="asinLink"  >B0719H5PS3  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B074C7G6LQ"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 181293003112435,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B074C7G6LQ" target="_blank"  class="asinLink"  >B074C7G6LQ  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B074CF2VBD"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 191283190608185,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B074CF2VBD" target="_blank"  class="asinLink"  >B074CF2VBD  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07PFTWF31"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 217505478733143,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07PFTWF31" target="_blank"  class="asinLink"  >B07PFTWF31  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B074CFR59W"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 252255780094887,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B074CFR59W" target="_blank"  class="asinLink"  >B074CFR59W  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B017CEQ3JI"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 255240370532949,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B017CEQ3JI" target="_blank"  class="asinLink"  >B017CEQ3JI  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07PDXL96M"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 266181624309606,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07PDXL96M" target="_blank"  class="asinLink"  >B07PDXL96M  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B074C9N7KF"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 273986507651641,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B074C9N7KF" target="_blank"  class="asinLink"  >B074C9N7KF  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                id: 11726031212415,
                campaignId: 11726031212415,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 11726031212415, CampaignName:"PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218"})\'  title="PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218"     >PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218</a></div>',
                campaignName: 'PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218',
                campaignName_short: 'PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218',
                Profit: 44.9,
                Revenue: 119.88,
                ADSpend: 7.6,
                ACOS: 6.34,
                Impressions: '2142',
                Clicks: '9',
                CTR: 0.42,
                CPC: 0.84,
                CVR: 22.22,
                Orders: 2,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r11726031212415==\'enabled\', \'paused\':state.r11726031212415==\'paused\', \'archived\':state.r11726031212415==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(11726031212415, \'enabled\', \'r11726031212415\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(11726031212415, \'paused\' , \'r11726031212415\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(11726031212415, \'archived\' , \'r11726031212415\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div><img src="/images/scheduleActive.svg"   style="    height: 20px;    cursor: pointer;"    ng-click="ShowSchedules(11726031212415)" >  ',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r11726031212415',
                is_auto: 0,
                is_schedule: 1,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c11726031212415"    ng-model="campaigns.r11726031212415" ng-change="ADDCampaign(11726031212415)" >\n                <label class="custom-control-label" for="c11726031212415"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(11726031212415)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r11726031212415}"  ng-init="bids.r11726031212415=7.50" >\n\n                                    <input type=text" is-number ng-model="bids.r11726031212415"  ng-click="showPanel(\'r11726031212415\',\'r11726031212415\')"  ng-blur="ReturnDefault(  \'r11726031212415\', \'r11726031212415\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r11726031212415,  11726031212415 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r11726031212415,  11726031212415, \'r11726031212415\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r11726031212415\', \'r11726031212415\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '7.50',
                Status: '<span ng-bind-html="servingStatus.r11726031212415"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'11726031212415\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '2142',
                Clicks: '9',
                ADSpend: '7.60',
                Revenue: '119.88',
                Orders: '2',
                adGroupName: '3K-CVS8-4O1B_PAT_CATEGORIES',
                adGroupId: 166445584454560,
                ACOS: 6.34,
                CVR: 22.22,
                CPC: 0.84,
                CTR: 0.42,
                Campaign: '3K-CVS8-4O1B_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 166445584454560,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '2',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '2142',
                Clicks: '9',
                ADSpend: '7.60',
                Revenue: '119.88',
                Orders: '2',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"17295526011"}]\'',
                ACOS: 6.34,
                CVR: 22.22,
                CPC: 0.84,
                CTR: 0.42,
                keywordText: null,
                keywordId: 0,
                targetId: 225081933664139,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=17295526011" target="_blank"  class="asinLink"  >Cat Litter Mats  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                id: 215597532626065,
                campaignId: 215597532626065,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 215597532626065, CampaignName:"PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224"})\'  title="PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224"     >PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224</a></div>',
                campaignName: 'PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224',
                campaignName_short: 'PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224',
                Profit: -0.93,
                Revenue: 0,
                ADSpend: 0.93,
                ACOS: 0,
                Impressions: '3609',
                Clicks: '1',
                CTR: 0.03,
                CPC: 0.93,
                CVR: 0,
                Orders: 0,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r215597532626065==\'enabled\', \'paused\':state.r215597532626065==\'paused\', \'archived\':state.r215597532626065==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(215597532626065, \'enabled\', \'r215597532626065\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(215597532626065, \'paused\' , \'r215597532626065\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(215597532626065, \'archived\' , \'r215597532626065\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r215597532626065',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c215597532626065"    ng-model="campaigns.r215597532626065" ng-change="ADDCampaign(215597532626065)" >\n                <label class="custom-control-label" for="c215597532626065"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(215597532626065)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r215597532626065}"  ng-init="bids.r215597532626065=10.00" >\n\n                                    <input type=text" is-number ng-model="bids.r215597532626065"  ng-click="showPanel(\'r215597532626065\',\'r215597532626065\')"  ng-blur="ReturnDefault(  \'r215597532626065\', \'r215597532626065\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r215597532626065,  215597532626065 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r215597532626065,  215597532626065, \'r215597532626065\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r215597532626065\', \'r215597532626065\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '10.00',
                Status: '<span ng-bind-html="servingStatus.r215597532626065"> </span>',
                Created: '12/24/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'215597532626065\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '1126',
                Clicks: '1',
                ADSpend: '0.93',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '4G-MK89-15GR_PAT_ASINS',
                adGroupId: 210903293093638,
                ACOS: 0,
                CVR: 0,
                CPC: 0.93,
                CTR: 0.09,
                Campaign: '4G-MK89-15GR_PAT_ASINS',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 210903293093638,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '102',
                Clicks: '1',
                ADSpend: '0.93',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B075CV6N6B"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0.93,
                CTR: 0.98,
                keywordText: null,
                keywordId: 0,
                targetId: 184779406772255,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B075CV6N6B" target="_blank"  class="asinLink"  >B075CV6N6B  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '9',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B077FYTDQC"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 4272949880482,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B077FYTDQC" target="_blank"  class="asinLink"  >B077FYTDQC  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B0777L8W3Q"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 7360684020890,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B0777L8W3Q" target="_blank"  class="asinLink"  >B0777L8W3Q  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '58',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B000084F3T"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 15647766120085,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B000084F3T" target="_blank"  class="asinLink"  >B000084F3T  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07C5H8BZP"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 41612779736011,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07C5H8BZP" target="_blank"  class="asinLink"  >B07C5H8BZP  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '4',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07FMRTDT7"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 57720583019948,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07FMRTDT7" target="_blank"  class="asinLink"  >B07FMRTDT7  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07DVQ6VD8"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 67700193559918,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07DVQ6VD8" target="_blank"  class="asinLink"  >B07DVQ6VD8  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '9',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01HP0Y9TU"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 96807549761074,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01HP0Y9TU" target="_blank"  class="asinLink"  >B01HP0Y9TU  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '23',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01N55SFDS"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 97624891070606,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01N55SFDS" target="_blank"  class="asinLink"  >B01N55SFDS  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01N97D4TL"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 99210950667251,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01N97D4TL" target="_blank"  class="asinLink"  >B01N97D4TL  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07F46NCSM"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 99947986261529,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07F46NCSM" target="_blank"  class="asinLink"  >B07F46NCSM  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '1',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07FCX3MSG"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 99996510627862,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07FCX3MSG" target="_blank"  class="asinLink"  >B07FCX3MSG  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '118',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B014E2AGYE"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 106554555815183,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B014E2AGYE" target="_blank"  class="asinLink"  >B014E2AGYE  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '2',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07JPD9RLP"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 118494865575924,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07JPD9RLP" target="_blank"  class="asinLink"  >B07JPD9RLP  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '4',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01MSX0VDL"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 141699773540105,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01MSX0VDL" target="_blank"  class="asinLink"  >B01MSX0VDL  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '3',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07DCPSMWN"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 142783253224980,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07DCPSMWN" target="_blank"  class="asinLink"  >B07DCPSMWN  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '127',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B01JTR0874"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 151864184207658,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B01JTR0874" target="_blank"  class="asinLink"  >B01JTR0874  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07CZ35HGJ"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 155281028405240,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07CZ35HGJ" target="_blank"  class="asinLink"  >B07CZ35HGJ  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B071WNNYPR"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 156264406362147,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B071WNNYPR" target="_blank"  class="asinLink"  >B071WNNYPR  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B00OZ30MOW"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 156546472775227,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B00OZ30MOW" target="_blank"  class="asinLink"  >B00OZ30MOW  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '32',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B071ZFY73R"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 164540186866823,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B071ZFY73R" target="_blank"  class="asinLink"  >B071ZFY73R  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '518',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B06W9L13VW"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 181530800505650,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B06W9L13VW" target="_blank"  class="asinLink"  >B06W9L13VW  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '5',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B077CRL72G"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 182108576798040,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B077CRL72G" target="_blank"  class="asinLink"  >B077CRL72G  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '0',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B07FQNCZZF"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 184029804976341,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B07FQNCZZF" target="_blank"  class="asinLink"  >B07FQNCZZF  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '24',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B0187VBWD2"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 185280813361487,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B0187VBWD2" target="_blank"  class="asinLink"  >B0187VBWD2  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                CTR: '-',
                CPC: '-',
                CVR: '-',
                ACOS: '-',
                adGroupId: 210903293093638,
                adGroupName: '4G-MK89-15GR_PAT_ASINS',
                TdClass: 'padding2level',
                Campaign: 'Show more ',
                Level: 'TargetMore'
              },
              {
                Impressions: '2483',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '4G-MK89-15GR_PAT_CATEGORIES',
                adGroupId: 134632196343512,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: '4G-MK89-15GR_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 134632196343512,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '33',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"17295526011"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 129456502631657,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=17295526011" target="_blank"  class="asinLink"  >Cat Litter Mats  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                Impressions: '1042',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"2975241011"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 148076730077158,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=2975241011" target="_blank"  class="asinLink"  >Cat Supplies  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                Impressions: '1405',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"2975296011"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 248198831728977,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=2975296011" target="_blank"  class="asinLink"  >Cat Litter & Housebreaking  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                id: 36522111104014,
                campaignId: 36522111104014,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 36522111104014, CampaignName:"PPCTools_PA-Research_484_JUMBO-BLUE_20191224"})\'  title="PPCTools_PA-Research_484_JUMBO-BLUE_20191224"     >PPCTools_PA-Research_484_JUMBO-BLUE_20191224</a></div>',
                campaignName: 'PPCTools_PA-Research_484_JUMBO-BLUE_20191224',
                campaignName_short: 'PPCTools_PA-Research_484_JUMBO-BLUE_20191224',
                Profit: -0.07,
                Revenue: 0,
                ADSpend: 0.07,
                ACOS: 0,
                Impressions: '90',
                Clicks: '1',
                CTR: 1.11,
                CPC: 0.07,
                CVR: 0,
                Orders: 0,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r36522111104014==\'enabled\', \'paused\':state.r36522111104014==\'paused\', \'archived\':state.r36522111104014==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(36522111104014, \'enabled\', \'r36522111104014\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(36522111104014, \'paused\' , \'r36522111104014\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(36522111104014, \'archived\' , \'r36522111104014\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r36522111104014',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c36522111104014"    ng-model="campaigns.r36522111104014" ng-change="ADDCampaign(36522111104014)" >\n                <label class="custom-control-label" for="c36522111104014"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(36522111104014)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r36522111104014}"  ng-init="bids.r36522111104014=7.50" >\n\n                                    <input type=text" is-number ng-model="bids.r36522111104014"  ng-click="showPanel(\'r36522111104014\',\'r36522111104014\')"  ng-blur="ReturnDefault(  \'r36522111104014\', \'r36522111104014\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r36522111104014,  36522111104014 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r36522111104014,  36522111104014, \'r36522111104014\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r36522111104014\', \'r36522111104014\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '7.50',
                Status: '<span ng-bind-html="servingStatus.r36522111104014"> </span>',
                Created: '12/24/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'36522111104014\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '30',
                Clicks: '1',
                ADSpend: '0.07',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'JUMBO-BLUE_PAT_ASINS',
                adGroupId: 70611137732341,
                ACOS: 0,
                CVR: 0,
                CPC: 0.07,
                CTR: 3.33,
                Campaign: 'JUMBO-BLUE_PAT_ASINS',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 70611137732341,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '30',
                Clicks: '1',
                ADSpend: '0.07',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinSameAs","value":"B075357QFB"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0.07,
                CTR: 3.33,
                keywordText: null,
                keywordId: 0,
                targetId: 161294685645731,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/dp/B075357QFB" target="_blank"  class="asinLink"  >B075357QFB  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span> </a>',
                Level: 'Target'
              },
              {
                Impressions: '60',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'JUMBO-BLUE_PAT_CATEGORIES',
                adGroupId: 201393481503943,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: 'JUMBO-BLUE_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 201393481503943,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '60',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"2975241011"}]\'',
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                keywordText: null,
                keywordId: 0,
                targetId: 12106842587883,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=2975241011" target="_blank"  class="asinLink"  >Cat Supplies  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              },
              {
                id: 144302553360609,
                campaignId: 144302553360609,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 144302553360609, CampaignName:"PPCTools_PA-Research_482_4G-MK89-15GR_20191224"})\'  title="PPCTools_PA-Research_482_4G-MK89-15GR_20191224"     >PPCTools_PA-Research_482_4G-MK89-15GR_20191224</a></div>',
                campaignName: 'PPCTools_PA-Research_482_4G-MK89-15GR_20191224',
                campaignName_short: 'PPCTools_PA-Research_482_4G-MK89-15GR_20191224',
                Profit: 22.04,
                Revenue: 59.94,
                ADSpend: 0.05,
                ACOS: 0.08,
                Impressions: '33',
                Clicks: '2',
                CTR: 6.06,
                CPC: 0.03,
                CVR: 100,
                Orders: 2,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r144302553360609==\'enabled\', \'paused\':state.r144302553360609==\'paused\', \'archived\':state.r144302553360609==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(144302553360609, \'enabled\', \'r144302553360609\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(144302553360609, \'paused\' , \'r144302553360609\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(144302553360609, \'archived\' , \'r144302553360609\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r144302553360609',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c144302553360609"    ng-model="campaigns.r144302553360609" ng-change="ADDCampaign(144302553360609)" >\n                <label class="custom-control-label" for="c144302553360609"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(144302553360609)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r144302553360609}"  ng-init="bids.r144302553360609=7.50" >\n\n                                    <input type=text" is-number ng-model="bids.r144302553360609"  ng-click="showPanel(\'r144302553360609\',\'r144302553360609\')"  ng-blur="ReturnDefault(  \'r144302553360609\', \'r144302553360609\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r144302553360609,  144302553360609 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r144302553360609,  144302553360609, \'r144302553360609\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r144302553360609\', \'r144302553360609\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '7.50',
                Status: '<span ng-bind-html="servingStatus.r144302553360609"> </span>',
                Created: '12/24/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'144302553360609\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '33',
                Clicks: '2',
                ADSpend: '0.05',
                Revenue: '59.94',
                Orders: '2',
                adGroupName: '4G-MK89-15GR_PAT_CATEGORIES',
                adGroupId: 155867399150750,
                ACOS: 0.08,
                CVR: 100,
                CPC: 0.03,
                CTR: 6.06,
                Campaign: '4G-MK89-15GR_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 155867399150750,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '2',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '33',
                Clicks: '2',
                ADSpend: '0.05',
                Revenue: '59.94',
                Orders: '2',
                expressions: ' \'[{"type":"asinCategorySameAs","value":"17295526011"}]\'',
                ACOS: 0.08,
                CVR: 100,
                CPC: 0.03,
                CTR: 6.06,
                keywordText: null,
                keywordId: 0,
                targetId: 170415565100479,
                expressionType: 'manual',
                TdClass: 'padding2level',
                Campaign: '<a href="https://www.amazon.com/gp/browse.html?node=17295526011" target="_blank"  class="asinLink"  >Cat Litter Mats  <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span>  </a>',
                Level: 'Target'
              }
            ],
            input: {
              draw: 4,
              columns: [
                {
                  data: 'action', name: 'action', searchable: true, orderable: false,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Campaign',
                  name: 'Campaign',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Revenue',
                  name: 'Revenue',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'ADSpend',
                  name: 'Spend',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Orders',
                  name: 'Orders',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'ACOS',
                  name: 'ACoS',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Impressions',
                  name: 'Impressions',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Clicks',
                  name: 'Clicks',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'CTR',
                  name: 'CTR',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'CPC',
                  name: 'CPC',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'CVR',
                  name: 'CVR',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Created',
                  name: 'Created',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'BiddingStrategy',
                  name: 'BiddingStrategy',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                }
              ],
              order: [
                {
                  column: 3,
                  dir: 'desc'
                }
              ],
              start: 0,
              length: 10,
              search: {
                value: '',
                regex: false
              },
              startDate: '2020-02-28',
              endDate: '2020-03-27',
              campaignType: 'All',
              CampaignIdList: [],
              filter: [],
              showTargets: true,
              showAdGroups: true,
              showASINS: false,
              onlyActive: true,
              onlyAdGroups: false,
              showSearchTerms: false,
              ToolsType: 'all',
              SelectAll: false,
              showCampaigns: false,
              RequestType: 'all',
              showKeywords: false,
              onlywithSchedules: false,
              t: '1585548751200'
            }
          };
          const respAdGroupsAsin = {
            draw: 6,
            recordsTotal: 10,
            recordsFiltered: 10,
            data: [
              {
                id: 188957570852602,
                campaignId: 188957570852602,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/a_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 188957570852602, CampaignName:"PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218"})\'  title="PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218"     >PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218</a></div>',
                campaignName: 'PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218',
                campaignName_short: 'PPCTools_Auto-Discovery_31_D7-I9YG-IDTK_20191218',
                Profit: -19.77,
                Revenue: 899.1,
                ADSpend: 438.75,
                ACOS: 48.8,
                Impressions: '305988',
                Clicks: '259',
                CTR: 0.08,
                CPC: 1.69,
                CVR: 10.81,
                Orders: 28,
                Alerts: '',
                statehtml: '<div class="dropdown   state-a ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r188957570852602==\'enabled\', \'paused\':state.r188957570852602==\'paused\', \'archived\':state.r188957570852602==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(188957570852602, \'enabled\', \'r188957570852602\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(188957570852602, \'paused\' , \'r188957570852602\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(188957570852602, \'archived\' , \'r188957570852602\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r188957570852602',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c188957570852602"    ng-model="campaigns.r188957570852602" ng-change="ADDCampaign(188957570852602)" >\n                <label class="custom-control-label" for="c188957570852602"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(188957570852602)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r188957570852602}"  ng-init="bids.r188957570852602=100.00" >\n\n                                    <input type=text" is-number ng-model="bids.r188957570852602"  ng-click="showPanel(\'r188957570852602\',\'r188957570852602\')"  ng-blur="ReturnDefault(  \'r188957570852602\', \'r188957570852602\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r188957570852602,  188957570852602 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r188957570852602,  188957570852602, \'r188957570852602\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r188957570852602\', \'r188957570852602\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '100.00',
                Status: '<span ng-bind-html="servingStatus.r188957570852602"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'188957570852602\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '305988',
                Clicks: '259',
                ADSpend: '438.75',
                Revenue: '899.10',
                Orders: '28',
                adGroupName: 'D7-I9YG-IDTK',
                adGroupId: 276958478650949,
                ACOS: 48.8,
                CVR: 10.81,
                CPC: 1.69,
                CTR: 0.08,
                Campaign: 'D7-I9YG-IDTK',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 276958478650949,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '28',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '341672',
                Clicks: '322',
                ADSpend: '494.48',
                Revenue: '989.01',
                Orders: '31',
                campaignId: 132131152914714,
                ACOS: 50,
                CVR: 9.63,
                CPC: 1.54,
                CTR: 0.09,
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                sku: '',
                asin: 'B01I2D7H2U',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '31',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                id: 261772738199901,
                campaignId: 261772738199901,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 261772738199901, CampaignName:"PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224"})\'  title="PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224"     >PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224</a></div>',
                campaignName: 'PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224',
                campaignName_short: 'PPCTools_KW-Suggested_482_4G-MK89-15GR_20191224',
                Profit: -6.31,
                Revenue: 59.94,
                ADSpend: 28.4,
                ACOS: 47.38,
                Impressions: '21185',
                Clicks: '31',
                CTR: 0.15,
                CPC: 0.92,
                CVR: 3.23,
                Orders: 1,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r261772738199901==\'enabled\', \'paused\':state.r261772738199901==\'paused\', \'archived\':state.r261772738199901==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(261772738199901, \'enabled\', \'r261772738199901\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(261772738199901, \'paused\' , \'r261772738199901\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(261772738199901, \'archived\' , \'r261772738199901\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div><img src="/images/scheduleActive.svg"   style="    height: 20px;    cursor: pointer;"    ng-click="ShowSchedules(261772738199901)" >  ',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r261772738199901',
                is_auto: 0,
                is_schedule: 1,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c261772738199901"    ng-model="campaigns.r261772738199901" ng-change="ADDCampaign(261772738199901)" >\n                <label class="custom-control-label" for="c261772738199901"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(261772738199901)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r261772738199901}"  ng-init="bids.r261772738199901=10.00" >\n\n                                    <input type=text" is-number ng-model="bids.r261772738199901"  ng-click="showPanel(\'r261772738199901\',\'r261772738199901\')"  ng-blur="ReturnDefault(  \'r261772738199901\', \'r261772738199901\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r261772738199901,  261772738199901 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r261772738199901,  261772738199901, \'r261772738199901\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r261772738199901\', \'r261772738199901\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '10.00',
                Status: '<span ng-bind-html="servingStatus.r261772738199901"> </span>',
                Created: '12/24/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'261772738199901\')" >LS</span>',
                PAT: '',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '18465',
                Clicks: '27',
                ADSpend: '24.79',
                Revenue: '59.94',
                Orders: '1',
                adGroupName: '4G-MK89-15GR_broad',
                adGroupId: 217808545623769,
                ACOS: 41.36,
                CVR: 3.7,
                CPC: 0.92,
                CTR: 0.15,
                Campaign: '4G-MK89-15GR_broad',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 217808545623769,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '1',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '24827',
                Clicks: '34',
                ADSpend: '29.38',
                Revenue: '119.88',
                Orders: '3',
                campaignId: 261772738199901,
                ACOS: 24.51,
                CVR: 8.82,
                CPC: 0.86,
                CTR: 0.14,
                adGroupName: '4G-MK89-15GR_phrase',
                adGroupId: 140675178458421,
                sku: '',
                asin: 'B01I2D7HG6',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 140675178458421,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '3',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                Impressions: '2156',
                Clicks: '4',
                ADSpend: '3.61',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '4G-MK89-15GR_phrase',
                adGroupId: 140675178458421,
                ACOS: 0,
                CVR: 0,
                CPC: 0.9,
                CTR: 0.19,
                Campaign: '4G-MK89-15GR_phrase',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 140675178458421,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '24827',
                Clicks: '34',
                ADSpend: '29.38',
                Revenue: '119.88',
                Orders: '3',
                campaignId: 261772738199901,
                ACOS: 24.51,
                CVR: 8.82,
                CPC: 0.86,
                CTR: 0.14,
                adGroupName: '4G-MK89-15GR_phrase',
                adGroupId: 140675178458421,
                sku: '',
                asin: 'B01I2D7HG6',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 140675178458421,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '3',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                Impressions: '564',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '4G-MK89-15GR_exact',
                adGroupId: 191054536856136,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: '4G-MK89-15GR_exact',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 191054536856136,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '24827',
                Clicks: '34',
                ADSpend: '29.38',
                Revenue: '119.88',
                Orders: '3',
                campaignId: 261772738199901,
                ACOS: 24.51,
                CVR: 8.82,
                CPC: 0.86,
                CTR: 0.14,
                adGroupName: '4G-MK89-15GR_phrase',
                adGroupId: 140675178458421,
                sku: '',
                asin: 'B01I2D7HG6',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 140675178458421,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '3',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                id: 208258318022604,
                campaignId: 208258318022604,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 208258318022604, CampaignName:"PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218"})\'  title="PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218"     >PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218</a></div>',
                campaignName: 'PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218',
                campaignName_short: 'PPCTools_KW-Suggested_15_D7-I9YG-IDTK_20191218',
                Profit: 5.56,
                Revenue: 59.94,
                ADSpend: 22.37,
                ACOS: 37.32,
                Impressions: '10373',
                Clicks: '25',
                CTR: 0.24,
                CPC: 0.89,
                CVR: 8,
                Orders: 2,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r208258318022604==\'enabled\', \'paused\':state.r208258318022604==\'paused\', \'archived\':state.r208258318022604==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(208258318022604, \'enabled\', \'r208258318022604\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(208258318022604, \'paused\' , \'r208258318022604\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(208258318022604, \'archived\' , \'r208258318022604\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r208258318022604',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c208258318022604"    ng-model="campaigns.r208258318022604" ng-change="ADDCampaign(208258318022604)" >\n                <label class="custom-control-label" for="c208258318022604"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(208258318022604)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r208258318022604}"  ng-init="bids.r208258318022604=100.00" >\n\n                                    <input type=text" is-number ng-model="bids.r208258318022604"  ng-click="showPanel(\'r208258318022604\',\'r208258318022604\')"  ng-blur="ReturnDefault(  \'r208258318022604\', \'r208258318022604\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r208258318022604,  208258318022604 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r208258318022604,  208258318022604, \'r208258318022604\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r208258318022604\', \'r208258318022604\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '100.00',
                Status: '<span ng-bind-html="servingStatus.r208258318022604"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'208258318022604\')" >LS</span>',
                PAT: '',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '2857',
                Clicks: '12',
                ADSpend: '10.53',
                Revenue: '29.97',
                Orders: '1',
                adGroupName: 'D7-I9YG-IDTK_broad',
                adGroupId: 158798992864612,
                ACOS: 35.14,
                CVR: 8.33,
                CPC: 0.88,
                CTR: 0.42,
                Campaign: 'D7-I9YG-IDTK_broad',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 158798992864612,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '1',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '341672',
                Clicks: '322',
                ADSpend: '494.48',
                Revenue: '989.01',
                Orders: '31',
                campaignId: 132131152914714,
                ACOS: 50,
                CVR: 9.63,
                CPC: 1.54,
                CTR: 0.09,
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                sku: '',
                asin: 'B01I2D7H2U',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '31',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                Impressions: '2722',
                Clicks: '6',
                ADSpend: '5.97',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 149617006457057,
                ACOS: 0,
                CVR: 0,
                CPC: 1,
                CTR: 0.22,
                Campaign: 'D7-I9YG-IDTK_exact',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 149617006457057,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '341672',
                Clicks: '322',
                ADSpend: '494.48',
                Revenue: '989.01',
                Orders: '31',
                campaignId: 132131152914714,
                ACOS: 50,
                CVR: 9.63,
                CPC: 1.54,
                CTR: 0.09,
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                sku: '',
                asin: 'B01I2D7H2U',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '31',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                Impressions: '4794',
                Clicks: '7',
                ADSpend: '5.87',
                Revenue: '29.97',
                Orders: '1',
                adGroupName: 'D7-I9YG-IDTK_phrase',
                adGroupId: 30129559784098,
                ACOS: 19.59,
                CVR: 14.29,
                CPC: 0.84,
                CTR: 0.15,
                Campaign: 'D7-I9YG-IDTK_phrase',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 30129559784098,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '1',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '341672',
                Clicks: '322',
                ADSpend: '494.48',
                Revenue: '989.01',
                Orders: '31',
                campaignId: 132131152914714,
                ACOS: 50,
                CVR: 9.63,
                CPC: 1.54,
                CTR: 0.09,
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                sku: '',
                asin: 'B01I2D7H2U',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '31',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                id: 102205254730768,
                campaignId: 102205254730768,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 102205254730768, CampaignName:"PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218"})\'  title="PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218"     >PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218</a></div>',
                campaignName: 'PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218',
                campaignName_short: 'PPCTools_PA-Suggested_15_D7-I9YG-IDTK_20191218',
                Profit: -6.57,
                Revenue: 29.97,
                ADSpend: 20.54,
                ACOS: 68.54,
                Impressions: '19124',
                Clicks: '22',
                CTR: 0.12,
                CPC: 0.93,
                CVR: 4.55,
                Orders: 1,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r102205254730768==\'enabled\', \'paused\':state.r102205254730768==\'paused\', \'archived\':state.r102205254730768==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(102205254730768, \'enabled\', \'r102205254730768\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(102205254730768, \'paused\' , \'r102205254730768\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(102205254730768, \'archived\' , \'r102205254730768\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r102205254730768',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c102205254730768"    ng-model="campaigns.r102205254730768" ng-change="ADDCampaign(102205254730768)" >\n                <label class="custom-control-label" for="c102205254730768"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(102205254730768)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r102205254730768}"  ng-init="bids.r102205254730768=10.00" >\n\n                                    <input type=text" is-number ng-model="bids.r102205254730768"  ng-click="showPanel(\'r102205254730768\',\'r102205254730768\')"  ng-blur="ReturnDefault(  \'r102205254730768\', \'r102205254730768\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r102205254730768,  102205254730768 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r102205254730768,  102205254730768, \'r102205254730768\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r102205254730768\', \'r102205254730768\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '10.00',
                Status: '<span ng-bind-html="servingStatus.r102205254730768"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'102205254730768\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '4959',
                Clicks: '11',
                ADSpend: '10.63',
                Revenue: '29.97',
                Orders: '1',
                adGroupName: 'D7-I9YG-IDTK_PAT_ASINS',
                adGroupId: 248105359224653,
                ACOS: 35.47,
                CVR: 9.09,
                CPC: 0.97,
                CTR: 0.22,
                Campaign: 'D7-I9YG-IDTK_PAT_ASINS',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 248105359224653,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '1',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '341672',
                Clicks: '322',
                ADSpend: '494.48',
                Revenue: '989.01',
                Orders: '31',
                campaignId: 132131152914714,
                ACOS: 50,
                CVR: 9.63,
                CPC: 1.54,
                CTR: 0.09,
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                sku: '',
                asin: 'B01I2D7H2U',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '31',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                Impressions: '14165',
                Clicks: '11',
                ADSpend: '9.91',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'D7-I9YG-IDTK_PAT_CATEGORIES',
                adGroupId: 44842580523576,
                ACOS: 0,
                CVR: 0,
                CPC: 0.9,
                CTR: 0.08,
                Campaign: 'D7-I9YG-IDTK_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 44842580523576,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '341672',
                Clicks: '322',
                ADSpend: '494.48',
                Revenue: '989.01',
                Orders: '31',
                campaignId: 132131152914714,
                ACOS: 50,
                CVR: 9.63,
                CPC: 1.54,
                CTR: 0.09,
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                sku: '',
                asin: 'B01I2D7H2U',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '31',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                id: 132131152914714,
                campaignId: 132131152914714,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 132131152914714, CampaignName:"PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218"})\'  title="PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218"     >PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218</a></div>',
                campaignName: 'PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218',
                campaignName_short: 'PPCTools_KW-Research_38_D7-I9YG-IDTK_20191218',
                Profit: -12.82,
                Revenue: 0,
                ADSpend: 12.82,
                ACOS: 0,
                Impressions: '6187',
                Clicks: '16',
                CTR: 0.26,
                CPC: 0.8,
                CVR: 0,
                Orders: 0,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r132131152914714==\'enabled\', \'paused\':state.r132131152914714==\'paused\', \'archived\':state.r132131152914714==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(132131152914714, \'enabled\', \'r132131152914714\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(132131152914714, \'paused\' , \'r132131152914714\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(132131152914714, \'archived\' , \'r132131152914714\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r132131152914714',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c132131152914714"    ng-model="campaigns.r132131152914714" ng-change="ADDCampaign(132131152914714)" >\n                <label class="custom-control-label" for="c132131152914714"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(132131152914714)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r132131152914714}"  ng-init="bids.r132131152914714=100.00" >\n\n                                    <input type=text" is-number ng-model="bids.r132131152914714"  ng-click="showPanel(\'r132131152914714\',\'r132131152914714\')"  ng-blur="ReturnDefault(  \'r132131152914714\', \'r132131152914714\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r132131152914714,  132131152914714 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r132131152914714,  132131152914714, \'r132131152914714\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r132131152914714\', \'r132131152914714\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '100.00',
                Status: '<span ng-bind-html="servingStatus.r132131152914714"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'132131152914714\')" >LS</span>',
                PAT: '',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '6181',
                Clicks: '16',
                ADSpend: '12.82',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'D7-I9YG-IDTK_broad',
                adGroupId: 214007996512462,
                ACOS: 0,
                CVR: 0,
                CPC: 0.8,
                CTR: 0.26,
                Campaign: 'D7-I9YG-IDTK_broad',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 214007996512462,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '341672',
                Clicks: '322',
                ADSpend: '494.48',
                Revenue: '989.01',
                Orders: '31',
                campaignId: 132131152914714,
                ACOS: 50,
                CVR: 9.63,
                CPC: 1.54,
                CTR: 0.09,
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                sku: '',
                asin: 'B01I2D7H2U',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '31',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                Impressions: '6',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: 'D7-I9YG-IDTK_exact',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '341672',
                Clicks: '322',
                ADSpend: '494.48',
                Revenue: '989.01',
                Orders: '31',
                campaignId: 132131152914714,
                ACOS: 50,
                CVR: 9.63,
                CPC: 1.54,
                CTR: 0.09,
                adGroupName: 'D7-I9YG-IDTK_exact',
                adGroupId: 105106432399978,
                sku: '',
                asin: 'B01I2D7H2U',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H2U" target="_blank" class="asinLink">B01I2D7H2U <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 105106432399978,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '31',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                id: 240451908552662,
                campaignId: 240451908552662,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 240451908552662, CampaignName:"PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218"})\'  title="PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218"     >PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218</a></div>',
                campaignName: 'PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218',
                campaignName_short: 'PPCTools_PA-Suggested_14_3K-CVS8-4O1B_20191218',
                Profit: -10.65,
                Revenue: 0,
                ADSpend: 10.65,
                ACOS: 0,
                Impressions: '10863',
                Clicks: '13',
                CTR: 0.12,
                CPC: 0.82,
                CVR: 0,
                Orders: 0,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r240451908552662==\'enabled\', \'paused\':state.r240451908552662==\'paused\', \'archived\':state.r240451908552662==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(240451908552662, \'enabled\', \'r240451908552662\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(240451908552662, \'paused\' , \'r240451908552662\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(240451908552662, \'archived\' , \'r240451908552662\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r240451908552662',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c240451908552662"    ng-model="campaigns.r240451908552662" ng-change="ADDCampaign(240451908552662)" >\n                <label class="custom-control-label" for="c240451908552662"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(240451908552662)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r240451908552662}"  ng-init="bids.r240451908552662=10.00" >\n\n                                    <input type=text" is-number ng-model="bids.r240451908552662"  ng-click="showPanel(\'r240451908552662\',\'r240451908552662\')"  ng-blur="ReturnDefault(  \'r240451908552662\', \'r240451908552662\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r240451908552662,  240451908552662 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r240451908552662,  240451908552662, \'r240451908552662\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r240451908552662\', \'r240451908552662\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '10.00',
                Status: '<span ng-bind-html="servingStatus.r240451908552662"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'240451908552662\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '10598',
                Clicks: '13',
                ADSpend: '10.65',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '3K-CVS8-4O1B_PAT_CATEGORIES',
                adGroupId: 45091806970607,
                ACOS: 0,
                CVR: 0,
                CPC: 0.82,
                CTR: 0.12,
                Campaign: '3K-CVS8-4O1B_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 45091806970607,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '13005',
                Clicks: '22',
                ADSpend: '18.25',
                Revenue: '119.88',
                Orders: '2',
                campaignId: 240451908552662,
                ACOS: 15.22,
                CVR: 9.09,
                CPC: 0.83,
                CTR: 0.17,
                adGroupName: '3K-CVS8-4O1B_PAT_CATEGORIES',
                adGroupId: 45091806970607,
                sku: '',
                asin: 'B01I2D7H8Y',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H8Y" target="_blank" class="asinLink">B01I2D7H8Y <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H8Y" target="_blank" class="asinLink">B01I2D7H8Y <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 45091806970607,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '2',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                Impressions: '265',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '3K-CVS8-4O1B_PAT_ASINS',
                adGroupId: 241108390169214,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: '3K-CVS8-4O1B_PAT_ASINS',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 241108390169214,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '13005',
                Clicks: '22',
                ADSpend: '18.25',
                Revenue: '119.88',
                Orders: '2',
                campaignId: 240451908552662,
                ACOS: 15.22,
                CVR: 9.09,
                CPC: 0.83,
                CTR: 0.17,
                adGroupName: '3K-CVS8-4O1B_PAT_CATEGORIES',
                adGroupId: 45091806970607,
                sku: '',
                asin: 'B01I2D7H8Y',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H8Y" target="_blank" class="asinLink">B01I2D7H8Y <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H8Y" target="_blank" class="asinLink">B01I2D7H8Y <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 45091806970607,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '2',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                id: 11726031212415,
                campaignId: 11726031212415,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 11726031212415, CampaignName:"PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218"})\'  title="PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218"     >PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218</a></div>',
                campaignName: 'PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218',
                campaignName_short: 'PPCTools_PA-Research_18_3K-CVS8-4O1B_20191218',
                Profit: 44.9,
                Revenue: 119.88,
                ADSpend: 7.6,
                ACOS: 6.34,
                Impressions: '2142',
                Clicks: '9',
                CTR: 0.42,
                CPC: 0.84,
                CVR: 22.22,
                Orders: 2,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r11726031212415==\'enabled\', \'paused\':state.r11726031212415==\'paused\', \'archived\':state.r11726031212415==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(11726031212415, \'enabled\', \'r11726031212415\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(11726031212415, \'paused\' , \'r11726031212415\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(11726031212415, \'archived\' , \'r11726031212415\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div><img src="/images/scheduleActive.svg"   style="    height: 20px;    cursor: pointer;"    ng-click="ShowSchedules(11726031212415)" >  ',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r11726031212415',
                is_auto: 0,
                is_schedule: 1,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c11726031212415"    ng-model="campaigns.r11726031212415" ng-change="ADDCampaign(11726031212415)" >\n                <label class="custom-control-label" for="c11726031212415"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(11726031212415)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r11726031212415}"  ng-init="bids.r11726031212415=7.50" >\n\n                                    <input type=text" is-number ng-model="bids.r11726031212415"  ng-click="showPanel(\'r11726031212415\',\'r11726031212415\')"  ng-blur="ReturnDefault(  \'r11726031212415\', \'r11726031212415\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r11726031212415,  11726031212415 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r11726031212415,  11726031212415, \'r11726031212415\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r11726031212415\', \'r11726031212415\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '7.50',
                Status: '<span ng-bind-html="servingStatus.r11726031212415"> </span>',
                Created: '12/18/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'11726031212415\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '2142',
                Clicks: '9',
                ADSpend: '7.60',
                Revenue: '119.88',
                Orders: '2',
                adGroupName: '3K-CVS8-4O1B_PAT_CATEGORIES',
                adGroupId: 166445584454560,
                ACOS: 6.34,
                CVR: 22.22,
                CPC: 0.84,
                CTR: 0.42,
                Campaign: '3K-CVS8-4O1B_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 166445584454560,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '2',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '13005',
                Clicks: '22',
                ADSpend: '18.25',
                Revenue: '119.88',
                Orders: '2',
                campaignId: 240451908552662,
                ACOS: 15.22,
                CVR: 9.09,
                CPC: 0.83,
                CTR: 0.17,
                adGroupName: '3K-CVS8-4O1B_PAT_CATEGORIES',
                adGroupId: 45091806970607,
                sku: '',
                asin: 'B01I2D7H8Y',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H8Y" target="_blank" class="asinLink">B01I2D7H8Y <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7H8Y" target="_blank" class="asinLink">B01I2D7H8Y <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 45091806970607,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '2',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                id: 215597532626065,
                campaignId: 215597532626065,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 215597532626065, CampaignName:"PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224"})\'  title="PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224"     >PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224</a></div>',
                campaignName: 'PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224',
                campaignName_short: 'PPCTools_PA-Suggested_482_4G-MK89-15GR_20191224',
                Profit: -0.93,
                Revenue: 0,
                ADSpend: 0.93,
                ACOS: 0,
                Impressions: '3609',
                Clicks: '1',
                CTR: 0.03,
                CPC: 0.93,
                CVR: 0,
                Orders: 0,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r215597532626065==\'enabled\', \'paused\':state.r215597532626065==\'paused\', \'archived\':state.r215597532626065==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(215597532626065, \'enabled\', \'r215597532626065\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(215597532626065, \'paused\' , \'r215597532626065\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(215597532626065, \'archived\' , \'r215597532626065\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r215597532626065',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c215597532626065"    ng-model="campaigns.r215597532626065" ng-change="ADDCampaign(215597532626065)" >\n                <label class="custom-control-label" for="c215597532626065"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(215597532626065)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r215597532626065}"  ng-init="bids.r215597532626065=10.00" >\n\n                                    <input type=text" is-number ng-model="bids.r215597532626065"  ng-click="showPanel(\'r215597532626065\',\'r215597532626065\')"  ng-blur="ReturnDefault(  \'r215597532626065\', \'r215597532626065\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r215597532626065,  215597532626065 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r215597532626065,  215597532626065, \'r215597532626065\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r215597532626065\', \'r215597532626065\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '10.00',
                Status: '<span ng-bind-html="servingStatus.r215597532626065"> </span>',
                Created: '12/24/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'215597532626065\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '1126',
                Clicks: '1',
                ADSpend: '0.93',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '4G-MK89-15GR_PAT_ASINS',
                adGroupId: 210903293093638,
                ACOS: 0,
                CVR: 0,
                CPC: 0.93,
                CTR: 0.09,
                Campaign: '4G-MK89-15GR_PAT_ASINS',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 210903293093638,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '24827',
                Clicks: '34',
                ADSpend: '29.38',
                Revenue: '119.88',
                Orders: '3',
                campaignId: 261772738199901,
                ACOS: 24.51,
                CVR: 8.82,
                CPC: 0.86,
                CTR: 0.14,
                adGroupName: '4G-MK89-15GR_phrase',
                adGroupId: 140675178458421,
                sku: '',
                asin: 'B01I2D7HG6',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 140675178458421,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '3',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                Impressions: '2483',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: '4G-MK89-15GR_PAT_CATEGORIES',
                adGroupId: 134632196343512,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: '4G-MK89-15GR_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 134632196343512,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '24827',
                Clicks: '34',
                ADSpend: '29.38',
                Revenue: '119.88',
                Orders: '3',
                campaignId: 261772738199901,
                ACOS: 24.51,
                CVR: 8.82,
                CPC: 0.86,
                CTR: 0.14,
                adGroupName: '4G-MK89-15GR_phrase',
                adGroupId: 140675178458421,
                sku: '',
                asin: 'B01I2D7HG6',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 140675178458421,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '3',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                id: 36522111104014,
                campaignId: 36522111104014,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 36522111104014, CampaignName:"PPCTools_PA-Research_484_JUMBO-BLUE_20191224"})\'  title="PPCTools_PA-Research_484_JUMBO-BLUE_20191224"     >PPCTools_PA-Research_484_JUMBO-BLUE_20191224</a></div>',
                campaignName: 'PPCTools_PA-Research_484_JUMBO-BLUE_20191224',
                campaignName_short: 'PPCTools_PA-Research_484_JUMBO-BLUE_20191224',
                Profit: -0.07,
                Revenue: 0,
                ADSpend: 0.07,
                ACOS: 0,
                Impressions: '90',
                Clicks: '1',
                CTR: 1.11,
                CPC: 0.07,
                CVR: 0,
                Orders: 0,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r36522111104014==\'enabled\', \'paused\':state.r36522111104014==\'paused\', \'archived\':state.r36522111104014==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(36522111104014, \'enabled\', \'r36522111104014\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(36522111104014, \'paused\' , \'r36522111104014\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(36522111104014, \'archived\' , \'r36522111104014\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r36522111104014',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c36522111104014"    ng-model="campaigns.r36522111104014" ng-change="ADDCampaign(36522111104014)" >\n                <label class="custom-control-label" for="c36522111104014"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(36522111104014)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r36522111104014}"  ng-init="bids.r36522111104014=7.50" >\n\n                                    <input type=text" is-number ng-model="bids.r36522111104014"  ng-click="showPanel(\'r36522111104014\',\'r36522111104014\')"  ng-blur="ReturnDefault(  \'r36522111104014\', \'r36522111104014\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r36522111104014,  36522111104014 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r36522111104014,  36522111104014, \'r36522111104014\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r36522111104014\', \'r36522111104014\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '7.50',
                Status: '<span ng-bind-html="servingStatus.r36522111104014"> </span>',
                Created: '12/24/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'36522111104014\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '30',
                Clicks: '1',
                ADSpend: '0.07',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'JUMBO-BLUE_PAT_ASINS',
                adGroupId: 70611137732341,
                ACOS: 0,
                CVR: 0,
                CPC: 0.07,
                CTR: 3.33,
                Campaign: 'JUMBO-BLUE_PAT_ASINS',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 70611137732341,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '90',
                Clicks: '1',
                ADSpend: '0.07',
                Revenue: '0.00',
                Orders: '0',
                campaignId: 36522111104014,
                ACOS: 0,
                CVR: 0,
                CPC: 0.07,
                CTR: 1.11,
                adGroupName: 'JUMBO-BLUE_PAT_ASINS',
                adGroupId: 70611137732341,
                sku: '',
                asin: 'B01N7KTF9A',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01N7KTF9A" target="_blank" class="asinLink">B01N7KTF9A <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01N7KTF9A" target="_blank" class="asinLink">B01N7KTF9A <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 70611137732341,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                Impressions: '60',
                Clicks: '0',
                ADSpend: '0.00',
                Revenue: '0.00',
                Orders: '0',
                adGroupName: 'JUMBO-BLUE_PAT_CATEGORIES',
                adGroupId: 201393481503943,
                ACOS: 0,
                CVR: 0,
                CPC: 0,
                CTR: 0,
                Campaign: 'JUMBO-BLUE_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 201393481503943,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '90',
                Clicks: '1',
                ADSpend: '0.07',
                Revenue: '0.00',
                Orders: '0',
                campaignId: 36522111104014,
                ACOS: 0,
                CVR: 0,
                CPC: 0.07,
                CTR: 1.11,
                adGroupName: 'JUMBO-BLUE_PAT_ASINS',
                adGroupId: 70611137732341,
                sku: '',
                asin: 'B01N7KTF9A',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01N7KTF9A" target="_blank" class="asinLink">B01N7KTF9A <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01N7KTF9A" target="_blank" class="asinLink">B01N7KTF9A <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 70611137732341,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '0',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              },
              {
                id: 144302553360609,
                campaignId: 144302553360609,
                status: 1,
                Autopilot: '',
                Campaign: '<div class="details-container new-details"> <img src=\'/images/iconStatuses/m_paused.svg\' class=\'campaignType\'> <a ui-sref=\'Campaign({campaignId: 144302553360609, CampaignName:"PPCTools_PA-Research_482_4G-MK89-15GR_20191224"})\'  title="PPCTools_PA-Research_482_4G-MK89-15GR_20191224"     >PPCTools_PA-Research_482_4G-MK89-15GR_20191224</a></div>',
                campaignName: 'PPCTools_PA-Research_482_4G-MK89-15GR_20191224',
                campaignName_short: 'PPCTools_PA-Research_482_4G-MK89-15GR_20191224',
                Profit: 22.04,
                Revenue: 59.94,
                ADSpend: 0.05,
                ACOS: 0.08,
                Impressions: '33',
                Clicks: '2',
                CTR: 6.06,
                CPC: 0.03,
                CVR: 100,
                Orders: 2,
                Alerts: '',
                statehtml: '<div class="dropdown   state-m ">\n          <button class="btn btn-secondary dropdown-toggle dropdown-content-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >\n            <span class="round paused " ng-class="{\'enabled\':state.r144302553360609==\'enabled\', \'paused\':state.r144302553360609==\'paused\', \'archived\':state.r144302553360609==\'archived\'}"> </span>\n          </button>\n          <div class="dropdown-menu state-a-select" aria-labelledby="dropdownMenuButton"    >\n            <a class="dropdown-item item-variant" ng-click="setState(144302553360609, \'enabled\', \'r144302553360609\', 4 )" ><span class="d-flex align-items-center"><span class="round enabled"></span>Enabled</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(144302553360609, \'paused\' , \'r144302553360609\' , 4)" ><span class="d-flex align-items-center"><span class="round paused"></span>Paused</span></a>\n            <a class="dropdown-item item-variant" ng-click="setState(144302553360609, \'archived\' , \'r144302553360609\', 4 )"><span class="d-flex align-items-center"><span class="round archived"></span>Archived</span></a>\n          </div>\n        </div>',
                state: 'paused',
                ServerStatus: '<span class="CampaignRunning">Enabled</span>',
                slug: 'r144302553360609',
                is_auto: 0,
                is_schedule: 0,
                action: '<div class="custom-control custom-checkbox custom-control-new">\n                <input type="checkbox" class="custom-control-input" id="c144302553360609"    ng-model="campaigns.r144302553360609" ng-change="ADDCampaign(144302553360609)" >\n                <label class="custom-control-label" for="c144302553360609"></label>\n                </div>',
                EditAction: '<button ng-click="EditCampaign(144302553360609)" class="blue_button"> Edit </button>   ',
                Budget: '  <div class="table-edited-value details"  ng-class="{\'active\':panel.r144302553360609}"  ng-init="bids.r144302553360609=7.50" >\n\n                                    <input type=text" is-number ng-model="bids.r144302553360609"  ng-click="showPanel(\'r144302553360609\',\'r144302553360609\')"  ng-blur="ReturnDefault(  \'r144302553360609\', \'r144302553360609\')"  ng-cloak   select-on-click  ng-keydown="$event.keyCode === 13 && SaveBid(bids.r144302553360609,  144302553360609 )"  >\n                                    <button class="btn btn-sm table-edited-value-btn btn-primary" ng-click="SaveBid(bids.r144302553360609,  144302553360609, \'r144302553360609\' )">Save</button>\n                                    <button class="btn btn-sm table-edited-value-btn btn-secondary"  ng-click="hidePanel( \'r144302553360609\', \'r144302553360609\')" >Cancel</button>\n                                </div>\n                               ',
                bidOriginal: '7.50',
                Status: '<span ng-bind-html="servingStatus.r144302553360609"> </span>',
                Created: '12/24/2019',
                BiddingStrategy: '<span  class="pointer"  ng-click="showBiddingControls( \'144302553360609\')" >LS</span>',
                PAT: '<span  style="\n                                    margin-left: 20px;\n                                    font-size: xx-large;  "> + </span>',
                Level: 'Campaign',
                adgroups: '',
                TdClass: ' '
              },
              {
                Impressions: '33',
                Clicks: '2',
                ADSpend: '0.05',
                Revenue: '59.94',
                Orders: '2',
                adGroupName: '4G-MK89-15GR_PAT_CATEGORIES',
                adGroupId: 155867399150750,
                ACOS: 0.08,
                CVR: 100,
                CPC: 0.03,
                CTR: 6.06,
                Campaign: '4G-MK89-15GR_PAT_CATEGORIES',
                sku_asin: '',
                TdClass: 'padding1level',
                productImage: '',
                Image: '',
                sku: '',
                slug: '',
                id: 155867399150750,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '2',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'AdGroup'
              },
              {
                Impressions: '24827',
                Clicks: '34',
                ADSpend: '29.38',
                Revenue: '119.88',
                Orders: '3',
                campaignId: 261772738199901,
                ACOS: 24.51,
                CVR: 8.82,
                CPC: 0.86,
                CTR: 0.14,
                adGroupName: '4G-MK89-15GR_phrase',
                adGroupId: 140675178458421,
                sku: '',
                asin: 'B01I2D7HG6',
                Campaign: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                sku_asin: '<p></p><a href="https://www.amazon.com/dp/B01I2D7HG6" target="_blank" class="asinLink">B01I2D7HG6 <span><svg aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="newTab-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path  d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"></path></svg></span></a>',
                TdClass: 'padding2level',
                productImage: '',
                Image: '',
                slug: '',
                id: 140675178458421,
                profit: '',
                TrueProfit: '',
                OrdersTotal: '3',
                UnitsOrdered: '',
                TotalSales: '',
                quantity: '',
                quantityDate: '',
                sales_rank: '',
                SalesRank: '',
                amazon_FBA_fees: '',
                average_selling_price: '',
                RealAcos: '',
                costPerUnit: '',
                amazonFBAFees: '',
                averageSellingPrice: '',
                profitMargin: '',
                profitMarginsPercent: '',
                AdSpendtoTotalRevenue: '',
                PPCtoTotalRevenue: '',
                wizzard: '',
                Level: 'ASIN'
              }
            ],
            input: {
              draw: 6,
              columns: [
                {
                  data: 'action',
                  name: 'action',
                  searchable: true,
                  orderable: false,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Campaign',
                  name: 'Campaign',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Revenue',
                  name: 'Revenue',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'ADSpend',
                  name: 'Spend',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Orders',
                  name: 'Orders',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'ACOS',
                  name: 'ACoS',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Impressions',
                  name: 'Impressions',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Clicks',
                  name: 'Clicks',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'CTR',
                  name: 'CTR',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'CPC',
                  name: 'CPC',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'CVR',
                  name: 'CVR',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'Created',
                  name: 'Created',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                },
                {
                  data: 'BiddingStrategy',
                  name: 'BiddingStrategy',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false
                  }
                }
              ],
              order: [
                {
                  column: 3,
                  dir: 'desc'
                }
              ],
              start: 0,
              length: 10,
              search: {
                value: '',
                regex: false
              },
              startDate: '2020-02-28',
              endDate: '2020-03-27',
              campaignType: 'All',
              CampaignIdList: [],
              filter: [],
              showTargets: false,
              showAdGroups: true,
              showASINS: true,
              onlyActive: true,
              onlyAdGroups: false,
              showSearchTerms: false,
              ToolsType: 'all',
              SelectAll: false,
              showCampaigns: false,
              RequestType: 'all',
              showKeywords: false,
              onlywithSchedules: false,
              t: '1585548751200'
            }
          };

          const resp = respAdgrouptargets;
          this.allCampaigns = resp.data;
          this.footerData = {
            Revenue: 0,
            Spend: 0,
            Orders: 0,
            ACOSSum: 0,
            ACOSAVG: 0,
            AmazonFees: 0,
            Impressions: 0,
            Clicks: 0,
            CTRSum: 0,
            CTRAvg: 0,
            CPCSum: 0,
            CPCAvg: 0,
            CVRSum: 0,
            CVRAvg: 0,
            Inventory: 0,
            TotalRecords: 0
          };

          this.allCampaigns.forEach((element, index: number) => {
            if (element.Level === 'Campaign') {
              this.footerData.Revenue += parseFloat(element.Revenue);
              this.footerData.Spend += parseFloat(element.ADSpend);
              this.footerData.Orders += parseInt(element.Orders, 10);
              this.footerData.ACOSSum += parseFloat(element.ACOS);
              this.footerData.AmazonFees += parseFloat(element.amazon_FBA_fees);
              this.footerData.Impressions += parseInt(element.Impressions, 10);
              this.footerData.Clicks += parseInt(element.Clicks, 10);
              this.footerData.CTRSum += parseFloat(element.CTR);
              this.footerData.CPCSum += parseFloat(element.CPC);
              this.footerData.CVRSum += parseFloat(element.CVR);
              this.footerData.TotalRecords += 1;
            }
          });

          this.prepareFooterData();

          resp.data.forEach((element, index: number) => {

            if (element.Level === 'Target' || element.Level === 'AdGroup' || element.Level === 'Campaign') {
              this.bids[element.slug] = element.bidOriginal;
            }
            if (element.is_auto !== 0) {
              this.toggleValue[element.slug] = true;
            } else {
              this.toggleValue[element.slug] = false;
            }
            this.state[element.slug] = element.state;

          });

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });

        } else {


          const postData = {...dataTablesParameters, ...this.postdata()};
          // this.updatesInProgress = true;
          this.http
            .post<DataTablesResponse>(
              url,
              postData
            ).subscribe(resp => {

            this.allCampaigns = resp.data;
            this.totalData = resp.totalData;
            console.log('data');
            console.log(resp);
            if (resp.redirect !== undefined && resp.redirect !== '') {
              console.log('we have redirect');
              return false;
            }


            this.footerData = {
              Revenue: 0,
              Spend: 0,
              Orders: 0,
              ACOSSum: 0,
              ACOSAVG: 0,
              AmazonFees: 0,
              Impressions: 0,
              Clicks: 0,
              CTRSum: 0,
              CTRAvg: 0,
              CPCSum: 0,
              CPCAvg: 0,
              CVRSum: 0,
              CVRAvg: 0,
              Inventory: 0,
              TotalRecords: 0
            };

            this.allCampaigns.forEach((element, index: number) => {
              if (element.Level === 'Campaign') {
                this.footerData.Revenue += parseFloat(element.Revenue);
                this.footerData.Spend += parseFloat(element.ADSpend);
                this.footerData.Orders += parseInt(element.Orders, 10);
                this.footerData.ACOSSum += parseFloat(element.ACOS);
                this.footerData.AmazonFees += parseFloat(element.amazon_FBA_fees);
                this.footerData.Impressions += parseInt(element.Impressions, 10);
                this.footerData.Clicks += parseInt(element.Clicks, 10);
                this.footerData.CTRSum += parseFloat(element.CTR);
                this.footerData.CPCSum += parseFloat(element.CPC);
                this.footerData.CVRSum += parseFloat(element.CVR);
                this.footerData.TotalRecords += 1;
              }
            });

            this.prepareFooterData();

            resp.data.forEach((element, index: number) => {

              if (element.Level === 'Target' || element.Level === 'AdGroup' || element.Level === 'Campaign') {
                this.bids[element.slug] = element.bidOriginal;
              }
              if (element.is_auto !== 0) {
                this.toggleValue[element.slug] = true;
              } else {
                this.toggleValue[element.slug] = false;
              }
              this.state[element.slug] = element.state;

            });


            if (resp.error === false || resp.error === undefined) {
            } else {
              this.toastr.warning(resp.msg);
            }
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }
      },
      columns: [
        {data: 'action', name: 'action', orderable: false},
        {data: 'Campaign', name: 'Campaign'},
        {data: 'state', name: 'state', orderable: false},
        {data: 'bid', name: 'Bid', orderable: false},
        {data: 'Revenue', name: 'Revenue'},
        {data: 'ADSpend', name: 'Spend'},
        {data: 'Orders', name: 'Orders'},
        {data: 'ACOS', name: 'ACoS'},
        {data: 'Impressions', name: 'Impressions'},
        {data: 'Clicks', name: 'Clicks'},
        {data: 'CTR', name: 'CTR'},
        {data: 'CPC', name: 'CPC'},
        {data: 'CVR', name: 'CVR'},
        {data: 'Created', name: 'Created', orderable: false},
        //    {data: 'BiddingStrategy', name: 'BiddingStrategy', orderable: false},
        {data: 'Autopilot', name: 'Autopilot', orderable: false},

      ],
      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {
        this.updatesInProgress = false;
        this.hideLoadingBar();
      }
    };

    const campaignId = history.state.campaignId;
    const campaignName = history.state.campaignName;

    const sku = history.state.sku;

    let mustUpdateFilterObject = false;

    if (campaignId !== undefined && campaignId !== null) {
      let filterExisted = false;
      this.selectedFilters.forEach((element) => {
        if (element.filterParametr === 'campaignId' && element.filterValue === campaignId) {
          filterExisted = true;
        }
      });
      if (!filterExisted) {
        this.selectedFilters.push({
          filterParametr: 'campaignId',
          filterCondition: 'equal',
          filterValue: campaignId,
          locked: false,
          verbose: '[CAMP] ' + campaignName
        });
        mustUpdateFilterObject = true;
      }
    }

    if (sku !== undefined && sku !== null) {
      let filterExisted = false;

      this.selectedFilters.forEach((element, key) => {
        if (element.filterParametr === 'sku') {
          this.selectedFilters.splice(key, 1);
        }
      });

      this.selectedFilters.forEach((element) => {
        if (element.filterParametr === 'sku' && element.filterValue === sku) {
          filterExisted = true;
        }
      });
      if (!filterExisted) {
        this.selectedFilters.push({
          filterParametr: 'sku',
          filterCondition: 'equal',
          filterValue: sku,
          locked: false,
          verbose: '[SKU] ' + sku
        });
        mustUpdateFilterObject = true;
      }
    }

    if (mustUpdateFilterObject === true) {
      this.updateGlonbalObject();
    }

    //  $('[data-toggle="tooltip"]').tooltip();
  }

  updatePageData() {


    this.selectAll = false;
    this.selectAllRecords();

    this.showLoadingBar();

    if (this.showTargets) {
      this.showASINS = false;
    }

    if (this.showASINS) {
      this.showTargets = false;
    }

    if (this.firstUpdateTableCall === true) {
      this.firstUpdateTableCall = false;
      if (this.dtElements !== undefined) {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
            //      this.TableLength = dtInstance.table().page.len();
          });
        });
      }
    } else {
      if (this.dtElements !== undefined) {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
            dtInstance.table().ajax.reload();
            //       this.TableLength = dtInstance.table().page.len();
          });
        });
      }
    }
    this.getDashboardGraph();
    this.getTotalProfit();
  }


  selectAllRecords() {
    console.log('select all records');
    this.selectedRecords = [];
    if (this.allCampaigns == undefined) {
      this.allCampaigns = [];
    }
    this.allCampaigns.forEach((element) => {
      if (this.selectAll === true) {
        this.campaigns[element.slug] = true;
        this.AddRecord(element);
      } else {
        this.campaigns[element.slug] = false;
      }
    });

  }

  getDashboardGraph() {

    if (this.showDemoData === true) {
      const str = '{"redirect":"","graphData":[{"values":[{"x":0,"y":57.36},{"x":1,"y":24.37},{"x":2,"y":25.01},{"x":3,"y":19.37},{"x":4,"y":14.04},{"x":5,"y":18.71},{"x":6,"y":48.51},{"x":7,"y":79.87},{"x":8,"y":46.87},{"x":9,"y":19.31},{"x":10,"y":14.55},{"x":11,"y":6.61},{"x":12,"y":8.78},{"x":13,"y":22.39}],"key":"Spend Total","color":"#673ab7"},{"values":[{"x":0,"y":149.85},{"x":1,"y":0},{"x":2,"y":89.91},{"x":3,"y":29.97},{"x":4,"y":29.97},{"x":5,"y":0},{"x":6,"y":29.97},{"x":7,"y":179.82},{"x":8,"y":29.97},{"x":9,"y":29.97},{"x":10,"y":29.97},{"x":11,"y":0},{"x":12,"y":119.88},{"x":13,"y":149.85}],"key":"Revenue","color":"#76ddfb"},{"values":[{"x":0,"y":92.49},{"x":1,"y":-24.37},{"x":2,"y":64.9},{"x":3,"y":10.6},{"x":4,"y":15.93},{"x":5,"y":-18.71},{"x":6,"y":-18.54},{"x":7,"y":99.95},{"x":8,"y":-16.9},{"x":9,"y":10.66},{"x":10,"y":15.42},{"x":11,"y":-6.61},{"x":12,"y":111.1},{"x":13,"y":127.46}],"key":" Net Revenue","color":"#b8e986"},{"values":[{"x":0,"y":8.26},{"x":1,"y":0},{"x":2,"y":14.36},{"x":3,"y":0},{"x":4,"y":0},{"x":5,"y":0},{"x":6,"y":0},{"x":7,"y":0},{"x":8,"y":0},{"x":9,"y":0},{"x":10,"y":0},{"x":11,"y":0},{"x":12,"y":43.72},{"x":13,"y":43.23}],"key":"Profit positive","color":"green","area":true},{"values":[{"x":0,"y":0},{"x":1,"y":-24.37},{"x":2,"y":0},{"x":3,"y":-6.25},{"x":4,"y":-0.92},{"x":5,"y":-18.71},{"x":6,"y":-35.39},{"x":7,"y":-1.13},{"x":8,"y":-33.75},{"x":9,"y":-6.19},{"x":10,"y":-1.43},{"x":11,"y":-6.61},{"x":12,"y":0},{"x":13,"y":0}],"key":"Profit negative","color":"#f04c5e","area":true}],"Tick":{"values":[0,1,2,3,4,5,6,7,8,9,10,11,12,13],"days":["2020-03-01","2020-03-02","2020-03-03","2020-03-04","2020-03-05","2020-03-06","2020-03-07","2020-03-08","2020-03-09","2020-03-10","2020-03-11","2020-03-12","2020-03-13","2020-03-14"]}}';
      const params = JSON.parse(str);
      this.graphData = params.graphData;
      this.graphDays = params.Tick.days;
    } else {
      const url = '/api/CampaignList/graph';
      this.http.post(url, this.postdata()).subscribe((params: DashboardGraphResponse) => {
        console.log('params');
        console.log(params);
        this.hideLoadingBar();
        if (params.redirect !== '') {
          this.router.navigate([params.redirect]);
        } else {
          this.graphData = params.graphData;
          this.graphDays = params.Tick.days;
        }
      });
    }
  }

  getTotalProfit() {

    let url = '/api/calculateProfit';
    const postData = {...{section: 'campaignList'}, ...this.postdata()};
    this.http
      .post<any>(
        url,
        postData
      ).toPromise().then(result => {
      console.log(result);

      if (result.error === false) {
        this.profitDownloaded = true;
        this.TotalProfit = result.profit.real;
        this.ProfitPPC = result.profit.ppc;
      }
    });


  }
}


