import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseComponent} from './base/base.component';
import {NgSelectConfig} from '@ng-select/ng-select';
import {SharedService2Service} from './shared-service2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {DOCUMENT, Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css',
    '../../node_modules/nvd3/build/nv.d3.css',
    '../../node_modules/morris.js/morris.css'
  ],

  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends BaseComponent implements OnInit {
  constructor(private config: NgSelectConfig, public dataShared: SharedService2Service,
              private router: Router,
              public http: HttpClient,
              private authenticationService: AuthService,
              public toastr: ToastrService,
              public modalService: NgbModal,
              public loader: LoadingBarService,
              location: Location,
              @Inject(DOCUMENT) private document: Document
  ) {
    super(dataShared, http, toastr, modalService);

  }

  ngOnInit(): void {

  }

}
