import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-keyword-settings-modal',
  templateUrl: './keyword-settings-modal.component.html',
  styleUrls: ['./keyword-settings-modal.component.scss']
})
export class KeywordSettingsModalComponent implements OnInit {

  @Input() TargetKeywordId;
  ScheduleAction: string = 'noAds';
  TargetPosition: number = 0;
  ScheduleActionVal: string = '';


  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, private http: HttpClient) {

  }

  ngOnInit() {
  }

  SaveTrackingSettings() {
    const url = '/api/keywordsTrackPosition/saveSettings';
    const postData = {
      TargetKeywordId: this.TargetKeywordId,
      TargetPosition: this.TargetPosition,
      action: this.ScheduleAction,
      ScheduleActionVal: this.ScheduleActionVal
    };
    this.http.post<any>(url, postData).toPromise().then(result => {
      if (result.error === false) {
        this.toastr.success(result.msg, 'Updated', {enableHtml: true});
        this.activeModal.close('updated');
      } else {
        this.toastr.warning(result.msg);
      }
    });


  }
}
