import {Component, OnDestroy, OnInit} from '@angular/core';
import {SharedServiceService} from '../shared-service.service';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {BaseComponent} from '../base/base.component';
import {SharedService2Service} from '../shared-service2.service';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

class DashboardResponse {
  redirect: string;

}

class DashboardGraphResponse {
  redirect: string;
  graphData: any[];
  Tick: {
    values,
    days
  };
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {

  params: any;
  CurrencyCurentObject: any;
  graphOptions;
  graphData;
  public graphDays;
  title: string;
  activeIdString: string = 'dashboard';

  public formatDateForGraph(d) {
    return d3.time.format('%-d %b')(new Date(this.graphDays[d]));
  }

  constructor(public http: HttpClient,
              public dataShared: SharedService2Service,
              private router: Router,
              private authenticationService: AuthService,
              public toastr: ToastrService,
              public modalService: NgbModal,
  ) {
    super(dataShared, http, toastr, modalService);
    this.listAvaliableFilters = [];

    this.title = 'Dashboard';
    this.ActivePreset = '30d';

    this.CurrencyCurentObject = {
      id: 1,
      CurrencyCode: 'USD',
      Multiplier: 1,
      symbol: '$'
    };

    this.graphOptions = {
      chart: {
        type: 'lineChart',
        height: 450,
        margin: {
          top: 20,
          right: 20,
          bottom: 50,
          left: 55
        },
        showValues: true,
        valueFormat(d) {
          return d3.format(',.2f')(d);
        },
        duration: 500,
        xAxis: {
          axisLabel: 'days',
          ticks: 5,
          tickFormat: (d) => {
            return d3.time.format('%-d %b')(new Date(this.graphDays[d]));
            //   this.formatDateForGraph(d);
            //     (d) => this.formatDateForGraph(d);
          }

        },
        yAxis: {
          axisLabel: 'value',
          axisLabelDistance: -10,
          tickFormat(d) {
            return d3.format('.2f')(d);
          }
        }
      }
    };


  }

  ngInitFinished() {
    //  this.showMainLayout();
  }


  updatePageData() {
    this.updateGlonbalObject();
    this.showLoadingBar();
    const url = '/api/dashboard_7';
    if (this.showDemoData === true) {

// https://json-to-js.com/  usefull tools
      this.params = {
        redirect: '',
        Impressions: '65723',
        GrossRevenue: 389.61,
        Clicks: '73',
        ADSpend: 78.43,
        TotalProfit: 38.45,
        Orders: '11',
        Conversion: 15.07,
        CTR: 0.11,
        Acos: 20.13,
        Revenue: {
          TotalProfit: 243.4,
          TotalSales: 788.71,
          TotalOrders: 40,
          Acos: 9.94
        },
        TS: 1585543322,
        diff: {
          interval: {
            startDate: '2020-02-28 00:00:00',
            endDate: '2020-03-14 23:59:59'
          },
          data: {
            TotalProfit: -37.79,
            Impressions: '327700',
            GrossRevenue: 989.01,
            Clicks: '325',
            ADSpend: 486.14,
            Orders: '30',
            Conversion: 9.23,
            CTR: 0.1,
            Acos: 49.15
          },
          percent: {
            TotalProfit: 2,
            Impressions: -80,
            GrossRevenue: -61,
            Clicks: -78,
            ADSpend: -84,
            Orders: -63,
            Conversion: 63,
            CTR: 10,
            Acos: -59
          },
          Revenue: {
            TotalProfit: -62,
            TotalSales: -47,
            TotalOrders: -40,
            Acos: -70
          }
        },
        noData: 'We are still processing your data. There are 0 sales reports and 0 advertising reports left. Please come back later.'
      };

      this.getDashboardGraph();
    } else {
      this.http.post(url, this.postdata()).subscribe((params: DashboardResponse) => {
        console.log('params');
        console.log(params);
        if (params.redirect !== '') {
          this.authenticationService.logout();
          // this.router.navigate([params.redirect]);
          this.hideLoadingBar();
        } else {
          this.getDashboardGraph();
          this.params = params;
        }
      });
    }
  }

  getDashboardGraph() {

    if (this.showDemoData === true) {
      const str = '{"redirect":"","graphData":[{"values":[{"x":0,"y":57.36},{"x":1,"y":24.37},{"x":2,"y":25.01},{"x":3,"y":19.37},' +
        '{"x":4,"y":14.04},{"x":5,"y":18.71},{"x":6,"y":48.51},{"x":7,"y":79.87},{"x":8,"y":46.87},{"x":9,"y":19.31},{"x":10,"y":14.55},' +
        '{"x":11,"y":6.61},{"x":12,"y":8.78},{"x":13,"y":22.39}],"key":"Spend Total","color":"#673ab7"},{"values":[{"x":0,"y":149.85},' +
        '{"x":1,"y":0},{"x":2,"y":89.91},{"x":3,"y":29.97},{"x":4,"y":29.97},{"x":5,"y":0},{"x":6,"y":29.97},{"x":7,"y":179.82},' +
        '{"x":8,"y":29.97},{"x":9,"y":29.97},{"x":10,"y":29.97},{"x":11,"y":0},{"x":12,"y":119.88},{"x":13,"y":149.85}],' +
        '"key":"Revenue","color":"#76ddfb"},{"values":[{"x":0,"y":92.49},{"x":1,"y":-24.37},{"x":2,"y":64.9},{"x":3,"y":10.6},' +
        '{"x":4,"y":15.93},{"x":5,"y":-18.71},{"x":6,"y":-18.54},{"x":7,"y":99.95},{"x":8,"y":-16.9},{"x":9,"y":10.66},{"x":10,"y":15.42},' +
        '{"x":11,"y":-6.61},{"x":12,"y":111.1},{"x":13,"y":127.46}],"key":" Net Revenue","color":"#b8e986"},{"values":[{"x":0,"y":8.26},' +
        '{"x":1,"y":0},{"x":2,"y":14.36},{"x":3,"y":0},{"x":4,"y":0},{"x":5,"y":0},{"x":6,"y":0},{"x":7,"y":0},{"x":8,"y":0},{"x":9,"y":0},' +
        '{"x":10,"y":0},{"x":11,"y":0},{"x":12,"y":43.72},{"x":13,"y":43.23}],"key":"Profit positive","color":"green","area":true},' +
        '{"values":[{"x":0,"y":0},{"x":1,"y":-24.37},{"x":2,"y":0},{"x":3,"y":-6.25},{"x":4,"y":-0.92},{"x":5,"y":-18.71},' +
        '{"x":6,"y":-35.39},{"x":7,"y":-1.13},{"x":8,"y":-33.75},{"x":9,"y":-6.19},{"x":10,"y":-1.43},{"x":11,"y":-6.61},{"x":12,"y":0},' +
        '{"x":13,"y":0}],"key":"Profit negative","color":"#f04c5e","area":true}],"Tick":{"values":[0,1,2,3,4,5,6,7,8,9,10,11,12,13],' +
        '"days":["2020-03-01","2020-03-02","2020-03-03","2020-03-04","2020-03-05","2020-03-06","2020-03-07","2020-03-08","2020-03-09",' +
        '"2020-03-10","2020-03-11","2020-03-12","2020-03-13","2020-03-14"]}}';
      const params = JSON.parse(str);
      this.graphData = params.graphData;
      this.graphDays = params.Tick.days;
    } else {
      const url = '/api/dashboardGraph_7';
      this.http.post(url, this.postdata()).subscribe((params: DashboardGraphResponse) => {
        console.log('params');
        console.log(params);
        this.hideLoadingBar();
        if (params.redirect !== '') {
          this.router.navigate([params.redirect]);
        } else {
          this.graphData = params.graphData;
          this.graphDays = params.Tick.days;
        }
      });
    }
  }


}
