import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {BaseComponent} from '../base/base.component';
import {SharedService2Service} from '../shared-service2.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {AmazonLoginComponent} from 'angular-amazon-login';
import {AmazonAuthResponse} from 'angular-amazon-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  email: string;
  password: string;
  submitted: boolean = false;
  loading: boolean = false;
  returnUrl: string;
  bodyLayout = 'auth';
  selfLayout = 'blank';
  token: string;

  private myClientId: string = 'amzn1.application-oa2-client.e360d62d47e14817b2bafb150553f45d';
  private redirectUrl: string = 'https://app.ppc.tools/amazonLogin';


  constructor(
    private route: ActivatedRoute,
    public dataShared: SharedService2Service,
    private router: Router,
    public modalService: NgbModal,
    public http: HttpClient,
    private authenticationService: AuthService,
    public toastr: ToastrService,
  ) {
    super(dataShared, http, toastr, modalService);
    console.log('construct login');
    if (this.authenticationService.currentUserValue && this.router.url !== '/Logout') {
      this.router.navigate(['/productList']);
    }
    this.selfLayout = 'blank';
    this.showAuthLayout();

  }

  ngOnInit() {
    //  this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.returnUrl = '/productList';
    const url = this.router.url;
    if (url === '/Logout') {
      this.DoLogout();
    }
    this.hideLoadingBar();
    this.loading = false;
    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (params.token !== undefined && params.token !== '') {
        this.token = params.token;
        this.authenticationService.authByToken(this.token);
      }
    });

  }


  onAmazonAuthResponse(event: AmazonAuthResponse) {
    console.log('response');
    console.log(AmazonAuthResponse);
  }

  DoLogout() {
    this.loading = true;
    this.authenticationService.logout()
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.loading = false;
          //  this.router.navigate([this.returnUrl]);
        },
        error => {
          this.toastr.warning(error);
          this.loading = false;
        });
  }

  Dologin() {
    this.submitted = true;
    this.loading = true;
    this.authenticationService.login(this.email, this.password).then(result => {
      console.log(result);
      this.loading = false;
      this.router.navigate(['productList']);
    }, error => {
      console.log('error');
      this.loading = false;
    });
  }


  isControlHasError(param1, param2) {

  }

  submit() {

  }
}
