import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {SharedService2Service} from '../shared-service2.service';
import {NgbModal, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {DataTablesResponse} from '../models/datatableResponse';
import {DataTableDirective} from 'angular-datatables';

class DashboardGraphResponse {
  redirect: string;
  graphData: any[];
  Tick: {
    values,
    days
  };
}

class DashboardResponse {
  redirect: string;
}

declare var $: any;

@Component({
  selector: 'app-autopilot',
  templateUrl: './autopilot.component.html',
  styleUrls: ['./autopilot.component.scss']
})
export class AutopilotComponent extends BaseComponent implements OnInit {

  title: string = 'Autopilot';
  AllAvaliableCampaigns: any [];
  allCampaigns: any [];
  params: any;
  graphOptions;
  graphData;
  public graphDays;
  SelectedTab: string = 'Autopilot';
  dtOptionsAutopilot: DataTables.Settings = {};
  dtOptionsAutopilotHistory: DataTables.Settings = {};
  redrawGroupFinished: boolean = false;
  SearchINTableHistory: string = '';
  allHistoryData: any[];
  campaigns: any = {};
  AutopilotAction: any = [];
  activeAutopilotTab: string = 'Autopilot';
  AutopilotActionNegative: boolean = false;
  AutopilotActionZero: boolean = false;
  AutopilotActionBid: boolean = false;


  @ViewChild('myTabSet', {static: false})
  private myTabSet: NgbTabset;


  constructor(public dataShared: SharedService2Service,
              public modalService: NgbModal, private router: Router,
              private authenticationService: AuthService,
              public http: HttpClient, public toastr: ToastrService) {
    super(dataShared, http, toastr, modalService);

    this.campaigns.r1 = 1;
    this.bids.r1 = 0;
    this.panel = {};
    this.showDropdown.r1 = 1;
    this.tmpBid.r1 = 1;
    this.edited.r1 = 1;
    this.neg.r1 = 1;
    this.negPending.r1 = 1;
    this.records.r1 = 1;

    this.graphOptions = {
      chart: {
        type: 'lineChart',
        height: 200,
        margin: {
          top: 20,
          right: 20,
          bottom: 50,
          left: 55
        },
        showValues: true,
        valueFormat(d) {
          return d3.format(',.2f')(d);
        },
        duration: 500,
        xAxis: {
          axisLabel: 'X Axis',
          ticks: 5,
          tickFormat: (d) => {
            return d3.time.format('%-d %b')(new Date(this.graphDays[d]));
            //   this.formatDateForGraph(d);
            //     (d) => this.formatDateForGraph(d);
          }

        },
        yAxis: {
          axisLabel: 'parametrs',
          axisLabelDistance: -10,
          tickFormat(d) {
            return d3.format('.2f')(d);
          }
        }
      }
    };


  }

  ngInitFinished() {
    this.showMainLayout();
    this.updatePageData();
    //  $('[data-toggle="tooltip"]').tooltip();
  }

  updatePageData() {
    this.GetAllData();
    this.getAutopilotData();
    // @todo check  why here and in suggestions we get undefined but in campaign list we alwayse have working this section ?
    if (this.dtElements !== undefined) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          //   this.TableLength = dtInstance.table().page.len();
          dtInstance.table().ajax.reload();
        });
      });
    }
    this.getTotalProfit();

  }


  GetAllData() {
    const url = '/api/autopilot/summary';
    this.http.post(url, this.postdata()).subscribe((params: DashboardResponse) => {
      console.log('params');
      console.log(params);
      if (params.redirect !== '') {
        //   $state.go(params.redirect);
        this.authenticationService.logout();
        // this.router.navigate([params.redirect]);
      } else {
        this.getDashboardGraph();
        //  this.params = params;
        this.totalData = params;
      }
    });
  }

  getDashboardGraph() {
    const url = '/api/autopilot/graph';
    this.http.post(url, this.postdata()).subscribe((params: DashboardGraphResponse) => {

      if (params.redirect !== '') {
//          $state.go(params.redirect);
      } else {
        this.graphData = params.graphData;
        this.graphDays = params.Tick.days;
      }
    });
  }


  getAutopilotData() {

    const url = '/api/autopilot/warnings/campaigns?t=' + Date.now();
    this.dtOptionsAutopilot = {
      pagingType: 'full_numbers',
      processing: true,
      lengthChange: false,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [3, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {
        //    console.log(dataTablesParameters);
        const postData = {...dataTablesParameters, ...this.postdata()};

        this.http
          .post<DataTablesResponse>(
            url,
            postData
          ).subscribe(resp => {


          this.redrawGroupFinished = true;
          this.allCampaigns = resp.data;

          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }
          this.footerData = {
            Revenue: 0,
            Spend: 0,
            Orders: 0,
            ACOSSum: 0,
            ACOSAVG: 0,
            AmazonFees: 0,
            Impressions: 0,
            Clicks: 0,
            CTRSum: 0,
            CTRAvg: 0,
            CPCSum: 0,
            CPCAvg: 0,
            CVRSum: 0,
            CVRAvg: 0,
            Inventory: 0,
            TotalRecords: 0
          };
          this.allCampaigns.forEach((element, index: number) => {
            if (element.Level === 'Campaign') {
              this.footerData.Revenue += parseFloat(element.Revenue);
              this.footerData.Spend += parseFloat(element.ADSpend);
              this.footerData.Orders += parseInt(element.Orders, 10);
              this.footerData.ACOSSum += parseFloat(element.ACOS);
              this.footerData.AmazonFees += parseFloat(element.amazon_FBA_fees);
              this.footerData.Impressions += parseInt(element.Impressions, 10);
              this.footerData.Clicks += parseInt(element.Clicks, 10);
              this.footerData.CTRSum += parseFloat(element.CTR);
              this.footerData.CPCSum += parseFloat(element.CPC);
              this.footerData.CVRSum += parseFloat(element.CVR);
              this.footerData.TotalRecords += 1;
            }
          });

          this.prepareFooterData();

          resp.data.forEach((element, index: number) => {

            if (element.Level === 'Target' || element.Level === 'AdGroup' || element.Level === 'Campaign') {
              this.bids[element.slug] = element.bidOriginal;
            }
            if (element.Autopilot !== '') {
              this.toggleValue[element.slug] = true;
            } else {
              this.toggleValue[element.slug] = false;
            }
            this.state[element.slug] = element.state;

          });


          if (resp.error === false || resp.error === undefined) {

          } else {
            this.toastr.warning(resp.msg);
          }


          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },


      columns: [


        {data: 'Campaign', name: 'Campaign'},
        {data: 'state', name: 'state', orderable: false},
        {data: 'bid', name: 'Bid', orderable: false},
        {data: 'Revenue', name: 'Revenue'},
        {data: 'ADSpend', name: 'Spend'},
        {data: 'Orders', name: 'Orders'},
        {data: 'ACOS', name: 'ACoS'},
        {data: 'Impressions', name: 'Impressions'},
        {data: 'Clicks', name: 'Clicks'},
        {data: 'CTR', name: 'CTR'},
        {data: 'CPC', name: 'CPC'},
        {data: 'CVR', name: 'CVR'},
        {data: 'Created', name: 'Created', orderable: false},
        {data: 'Autopilot', name: 'Autopilot', orderable: false},


      ],

      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {
        this.updatesInProgress = false;
        this.hideLoadingBar();
      }
    };


    const urlHistory = '/api/autopilot/history?t=' + Date.now();
    this.dtOptionsAutopilotHistory = {

      pagingType: 'full_numbers',
      processing: true,
      lengthChange: false,
      serverSide: true,
      language: {
        processing: '<div class="loadingBar"><img src="/assets/media/illustrations/loader.svg"></div> ',
      },
      order: [14, 'desc'],
      autoWidth: false,
      info: false,

      ajax: (dataTablesParameters: any, callback) => {
        //    console.log(dataTablesParameters);
        const postData = {...dataTablesParameters, ...this.postdata()};

        this.http
          .post<DataTablesResponse>(
            urlHistory,
            postData
          ).subscribe(resp => {

          this.allHistoryData = resp.data;


          if (resp.redirect !== undefined && resp.redirect !== '') {
            console.log('we have redirect');
            return false;
          }

          if (resp.error === false || resp.error === undefined) {

          } else {
            this.toastr.warning(resp.msg);
          }

          resp.data.forEach((element, index: number) => {
            this.bids[element.slug] = element.bidOriginal;
            this.state[element.slug] = element.state;
          });

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'keywordText', name: 'keywordText'},
        {data: 'state', name: 'state', orderable: false, searchable: false},

        //   {data: 'Campaign', name: 'Campaign'},
        {data: 'bid', name: 'bid', searchable: false, orderable: false},
        {data: 'ACOS', name: 'ACOS', searchable: false},
        {data: 'CPC', name: 'CPC', searchable: false},
        {data: 'suggested', name: 'suggested', searchable: false, orderable: false},
        {data: 'isNegative', name: 'isNegative', searchable: false, orderable: false},

        {data: 'Revenue', name: 'Revenue', searchable: false},
        {data: 'ADSpend', name: 'ADSpend', searchable: false},
        {data: 'Impressions', name: 'Impressions', searchable: false},
        {data: 'Clicks', name: 'Clicks', searchable: false},
        {data: 'Orders', name: 'Orders', searchable: false},
        {data: 'BidChange', name: 'BidChange', searchable: false, orderable: false},
        {data: 'date', name: 'date', searchable: false},
        {data: 'daysLength', name: 'daysLength', searchable: false},
        {data: 'comment', name: 'comment'},

      ],

      footerCallback: (row, dataFooter, start, end, display) => {
      },
      drawCallback: (settings) => {
        this.updatesInProgress = false;
        this.hideLoadingBar();
      }
    };
  }


  updateActionFilter() {
    console.log(this.AutopilotAction);


    this.AutopilotAction = [];
    if (this.AutopilotActionNegative) {
      this.AutopilotAction.push('negative');
    }
    if (this.AutopilotActionZero) {
      this.AutopilotAction.push('zeroSales');
    }
    if (this.AutopilotActionBid) {
      this.AutopilotAction.push('bidChanged');
    }

    let filterExisted = false;
    this.selectedFilters.forEach((element) => {
      if (element.filterParametr === 'actionType') {
        filterExisted = true;
        element.filterValue = this.AutopilotAction;
        element.verbose = 'action one of ' + this.AutopilotAction.join(',');
      }
    });
    if (filterExisted === false) {
      this.selectedFilters.push({
        filterParametr: 'actionType',
        filterCondition: 'equal',
        filterValue: this.AutopilotAction,
        locked: false,
        verbose: 'action one of ' + this.AutopilotAction.join(',')
      });

    }
    this.updateGlonbalObject();

    if (this.dtElements !== undefined) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          dtInstance.table().ajax.reload();
          //    this.TableLength = dtInstance.table().page.len();
        });
      });
    }

  }


  showHistoryForCampaign(campaignId, campaignName) {
    let filterExisted = false;
    this.selectedFilters.forEach((element) => {
      if (element.filterParametr === 'campaignId' && element.filterValue === campaignId) {
        filterExisted = true;
      }
    });
    if (!filterExisted) {
      this.selectedFilters.push({
        filterParametr: 'campaignId',
        filterCondition: 'equal',
        filterValue: campaignId,
        locked: false,
        verbose: campaignName
      });
      this.updateGlonbalObject();
    }
    // switch tab

    /*  this.activeAutopilotTab = 'Autopilot';
      this.activeAutopilotTab = 'History';
  */
    this.myTabSet.select('History');
  }


  getTotalProfit() {

    let url = '/api/calculateProfit';
    const postData = {...{section: 'autopilot'}, ...this.postdata()};
    this.http
      .post<any>(
        url,
        postData
      ).toPromise().then(result => {
      console.log(result);

      if (result.error === false) {
        this.profitDownloaded = true;
        this.TotalProfit = result.profit.real;
        this.ProfitPPC = result.profit.ppc;
      }
    });


  }


}
