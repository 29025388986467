import {Component, OnInit} from '@angular/core';
import {SharedService2Service} from '../shared-service2.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../base/base.component';

@Component({
  selector: 'app-add-child-user',
  templateUrl: './add-child-user.component.html',
  styleUrls: ['./add-child-user.component.scss']
})
export class AddChildUserComponent extends BaseComponent implements OnInit {

  AllChildAccounts: any [];
  ChildEmail: string;
  account: any;

  constructor(
    public dataShared: SharedService2Service,
    public modalService: NgbModal,
    public http: HttpClient,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    super(dataShared, http, toastr, modalService);
    this.account = {
      email: ''
    };
  }

  updatePageData() {
    this.getChild();
  }

  deleteChild(account) {
    const url = '/api/settings/childAccount';
    const postData = {email: account.email, action: 'delete'};
    this.http.post<any>(url, postData).toPromise()
      .then(
        result => {
          console.log('params');
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.getChild();
            this.toastr.success('Deleted');
          }
        });
  }

  resendInvite(inviteId) {

    const url = '/api/settings/childAccount';
    const postData = {inviteId, action: 'resendInvite'};
    this.http.post<any>(url, postData).toPromise()
      .then(
        result => {
          console.log('params');
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.getChild();
            this.toastr.success('Resended');
          }
        });
  }


  GiveAdminToUser(userId) {
    const url = '/api/settings/childAccount';
    const postData = {userId, action: 'giveAdmin'};
    this.http.post<any>(url, postData).toPromise()
      .then(
        result => {

          console.log('params');
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.getChild();
            this.toastr.success('Updated');

          }
        });
  }


  RemoveAdminFromUser(userId) {
    const url = '/api/settings/childAccount';
    const postData = {userId, action: 'removeAdmin'};
    this.http.post<any>(url, postData).toPromise()
      .then(
        result => {
          console.log('params');
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.getChild();
            this.toastr.success('Updated');
          }
        });
  }


  ChangeToogle(userId) {
    /*
        console.log('cange wizard');
        var modelkeyword = $parse('toggleValue.u' + userId)($scope);
        if (modelkeyword == true) {
          $scope.GiveAdminToUser(userId);
        } else {
          $scope.RemoveAdminFromUser(userId);
        }
        */
  }


  getChild() {
    this.http.get<any>('/api/settings/getChildAccount').toPromise()
      .then(
        result => {
          console.log('params');
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.AllChildAccounts = result.AllChildAccounts;
          }
        });
  }


  addChild = function() {
    const url = '/api/settings/childAccount';
    const postData = {email: this.ChildEmail, action: 'add'};
    this.http.post(url, postData).toPromise()
      .then(
        result => {
          console.log('params');
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.getChild();
            this.toastr.success('Updated');
          }
        });
  };

}
