import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService2Service} from '../shared-service2.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth.service';
import {ToastrService} from 'ngx-toastr';
import {BaseComponent} from '../base/base.component';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent extends BaseComponent implements OnInit {

  email: string;
  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public dataShared: SharedService2Service,
    private router: Router,
    public modalService: NgbModal,
    public http: HttpClient,
    private authenticationService: AuthService,
    public toastr: ToastrService,
  ) {
    super(dataShared, http, toastr, modalService);
    this.showAuthLayout();
  }

  ngOnInit() {
    this.hideLoadingBar();
  }

  Recover() {
    this.authenticationService.recover(this.email).then(
      result => {
        console.log(result);
        if (result.error === true) {
          this.toastr.warning(result.email);
        } else {
          this.toastr.success(result.email);
        }
      }, msg => {
        console.log(msg);
      }
    );
  }

}
