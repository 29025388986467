import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService2Service} from '../shared-service2.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth.service';
import {ToastrService} from 'ngx-toastr';
import {BaseComponent} from '../base/base.component';

@Component({
  selector: 'app-settings-add-mws',
  templateUrl: './settings-add-mws.component.html',
  styleUrls: ['./settings-add-mws.component.scss']
})
export class SettingsAddMwsComponent extends BaseComponent implements OnInit {

  weKnowCurrentStage: boolean = true;
  tab: string = 'mwsData';
  precessedStep: number = 0;
  devID: string = '3280-6223-7789';
  SellerLink: string;
  country: string;
  NewAccount: any = {};

  constructor(private route: ActivatedRoute,
              public dataShared: SharedService2Service,
              private router: Router,
              public modalService: NgbModal,
              public http: HttpClient,
              private authenticationService: AuthService,
              public toastr: ToastrService,
              public activeModal: NgbActiveModal,
              private window: Window) {
    super(dataShared, http, toastr, modalService);
    this.NewAccount = {
      id: 0,
      name: '',
      sellerId: 0,
      mwsToken: '',
      country_coode: '',
      AuthToken: ''
    };
    this.SellerLink = 'https://sellercentral.amazon.com/gp/mws/registration/register.html?signInPageDisplayed=1&developerName=PPC.tools&devMWSAccountId=' + this.devID;


  }

  ngOnInit() {
  }

  changeTab(section) {
    switch (section) {

      case 'mwsData':
        this.tab = 'mwsData';
        break;

      case 'selectToken':
        if (this.precessedStep > 1) {
          this.tab = 'selectToken';
        }
        break;
      case 'selectsku':

        if (this.precessedStep > 2) {
          this.tab = 'selectsku';
        }
        break;

      case 'downloadingReports':
        if (this.precessedStep > 3) {
          this.tab = 'downloadingReports';
        }
        break;
    }

  }


  changedCountry() {


    switch (this.NewAccount.country_coode) {
      case'de':
      case'uk':
      case'es':
      case'it':
      case'fr':
        console.log('switched');
        this.devID = '2393-8033-9656';
        window['amazon'].Login.setRegion(window['amazon'].Login.Region.Europe);
        this.SellerLink = 'https://sellercentral-europe.amazon.com/gp/mws/registration/register.html?signInPageDisplayed=1&developerName=PPC.tools&devMWSAccountId=' + this.devID;
        break;

      case'au':
        window['amazon'].Login.setRegion(window['amazon'].Login.Region.AsiaPacific);
        this.devID = '9986-4620-8155';
        this.SellerLink = 'https://sellercentral.amazon.com.au/gp/mws/registration/register.html?signInPageDisplayed=1&developerName=PPC.tools&devMWSAccountId=' + this.devID;
        break;
      case'jp':
        window['amazon'].Login.setRegion(window['amazon'].Login.Region.AsiaPacific);
        this.devID = '9986-4620-8155';
        this.SellerLink = 'https://sellercentral.amazon.co.jp/gp/mws/registration/register.html?signInPageDisplayed=1&developerName=PPC.tools&devMWSAccountId=' + this.devID;
        break;
      case 'ae':
        window['amazon'].Login.setRegion(window['amazon'].Login.Region.AsiaPacific);
        this.devID = '9986-4620-8155';
        this.SellerLink = 'https://sellercentral.amazon.ae/gp/mws/registration/register.html?signInPageDisplayed=1&developerName=PPC.tools&devMWSAccountId=' + this.devID;
        break;
      case 'ca':
        window['amazon'].Login.setRegion(window['amazon'].Login.Region.NorthAmerica);
        this.devID = '3280-6223-7789';
        this.SellerLink = 'https://sellercentral.amazon.ca/gp/mws/registration/register.html?signInPageDisplayed=1&developerName=PPC.tools&devMWSAccountId=' + this.devID;
        break;
      default: //us
        window['amazon'].Login.setRegion(window['amazon'].Login.Region.NorthAmerica);
        this.devID = '3280-6223-7789';
        this.SellerLink = 'https://sellercentral.amazon.com/gp/mws/registration/register.html?signInPageDisplayed=1&developerName=PPC.tools&devMWSAccountId=' + this.devID;
        break;
    }

  }


  addAccount() {
    this.showLoadingBar();
    if (this.NewAccount.country_coode === undefined || this.NewAccount.country_coode === '') {
      this.NewAccount.country_coode = 'us';
    }

    const account = this.NewAccount;
    this.http.post<any>('/api/settings/account', account).toPromise()
      .then(result => {
        this.hideLoadingBar();

        if (result.status === 0) {
          this.toastr.warning(result.msg);

        } else {
          this.toastr.success('success', 'Data is valid');
          this.activeModal.close('saved');
        }
      });
  }

}
