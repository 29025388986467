import {Component, isDevMode, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import * as moment from 'moment';
import {SharedService2Service} from '../shared-service2.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {FiltersModalComponent} from '../filters-modal/filters-modal.component';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {VisibilityFilterModalComponent} from '../visibility-filter-modal/visibility-filter-modal.component';
import {ConfirmationModalComponent} from '../confirmation-modal/confirmation-modal.component';
import {ScheduleModalComponent} from '../schedule-modal/schedule-modal.component';
import {ScheduleListModalComponent} from '../schedule-list-modal/schedule-list-modal.component';
import {AddToNegativeModalComponent} from '../add-to-negative-modal/add-to-negative-modal.component';
import {AddKeywordsToCampaignsModalComponent} from '../add-keywords-to-campaigns-modal/add-keywords-to-campaigns-modal.component';
import {FooterTable} from '../models/footerTable';
import {AutopilotModalComponent} from '../autopilot-modal/autopilot-modal.component';


@Component({
  selector: 'app-base',
  template: ` `,
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit, OnDestroy {


  TableLength: any;
  selectedFilters: any[];
  selectedFiltersTemporary: any[]; // use to restore filter list if user closed filter modal
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;
  ActivePreset: string;
  allProducts: any[];
  LoadingInProgress: boolean = true;
  bodyLayout = 'auth';
  campaignType: string = 'All';
  portfolioId: number = -1;
  HelpersBlock: any;
  SearchINTable: string = '';
  CurPage: string;
  totalSuggestions: number;
  totalAutopilot: number;
  allColumnsVis: any[];
  selectedSectionForColumnVis: string;
  AccountId: string;
  panel: object;
  bids: any = {};
  tmpBid: any = {};
  showDropdown: any = {};
  edited: any = {};
  neg: any = {};
  negPending: any = {};
  updatedNegative: any = {};
  records: any = {};
  state: any = {};
  filterSKU: any;
  showTargets: boolean = false;
  showAdGroups: boolean = false;
  showASINS: boolean = false;
  onlyActive: boolean = false;
  onlyAdGroups: boolean = false;
  showSearchTerms: boolean = false;
  toolsType: string = 'all';
  SelectAll: boolean = false;
  showCampaigns: boolean = false;
  requestType: string = 'all';
  showKeywords: boolean = false;
  presetSelected: any;
  myValueSub: Subscription;
  public updatesInProgress = false;
  hideBid: boolean = true;
  CampaignIdList: any[];
  onlywithSchedules: boolean = false;
  ActionValue: string;
  ActionValueSchedules: string = 'init';
  ActionValueStatus: string = 'init';
  ActionValueAutopilot: string = 'init';
  ActionValueTracking: string = 'init';
  ActionValueNegative: string = 'init';
  ActionValueSuggestedBid: string = 'init';
  ActionValueSuggestedNegative: string = 'init';

  selectedRecords: any[];
  recordCheckboxes: any = {};
  visibleColumns: any[]; // used to show/hide columns until this  issue opened https://github.com/l-lin/angular-datatables/issues/1344
  allVisibleNegative: any [];
  allVisibleNew: any[];
  allVisibleBids: any[];
  allVisibleDuplicates: any[];
  allVisibleAutopilot: any  [];
  allVisibleAutopilotHistory: any [];
  toggleValue: any = {};
  profitDownloaded: boolean = false;
  TotalProfit:number=0;
  ProfitPPC:number=0;
  dateRangesPresets: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  dateRange: {
    startDate: moment.Moment, endDate: moment.Moment
  };

  SelectedCampaigns: any [];
  allCampaigns: any[];
  selectedPreset: string = 'all';
  negativeTarget: string = '';
  listAvaliableFilters: any [];
  skipedFilters = []; // filters exist but not avaliable on this page;
  AllAvaliableRecords: any[];
  showDemoData = false;
  footerData: FooterTable;
  totalData: any;

  hideFilterBlock: boolean = true;

  filterParametr: string;
  filterCondition: string;

  filterValue: string;
  autopilotSettings: any[];
  childComponent: any;
  // https://medium.com/@sujeeshdl/angular-parent-to-child-and-child-to-parent-communication-from-router-outlet-868b39d1ca89
  selectedFiltersTempFogGlobalObject: any[]; // this will used for temporary storage  global filterobject and protect it  state

  SelectedSuggestionNegatives: any [];
  SelectedSuggestionNew: any [];
  SelectedSuggestionBids: any [];
  SelectedSuggestionDuplicates: any [];


  constructor(public dataShared: SharedService2Service, public http: HttpClient, public toastr: ToastrService,
              public modalService: NgbModal
  ) {


    if (location.host === 'localhost:4200') {
      this.showDemoData = true;
      console.log('running dev');

    }

    this.SelectedSuggestionNegatives = [];
    this.SelectedSuggestionNew = [];
    this.SelectedSuggestionBids = [];
    this.SelectedSuggestionDuplicates = [];
    this.state.r1 = 1;
    this.recordCheckboxes.r1 = 1;
    this.selectedRecords = [];
    this.selectedFilters = [];
    this.selectedFiltersTemporary = [];
    // just show all columns for first run
    this.visibleColumns = [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true,
      true, true, true, true, true, true, true, true, true, true, true, true];

    this.HelpersBlock = {
      suggestion: true,
      adGroupList: true,
      campaignList: true,
      keywordTools: true,
      skuList: true,
      searchTermTools: true,
    };
    this.CampaignIdList = [];
    this.TableLength = 10;
  }

  ngOnInit() {
    console.log('on init BASe controller');
    this.myValueSub = this.dataShared.currentMessage.subscribe(globalSharedObject => {
      let needUpdate = false;
      if (globalSharedObject !== undefined) {
        console.log(globalSharedObject);
        this.portfolioId = globalSharedObject.portfolioId;
        if (globalSharedObject.dateRange === undefined) {
          this.dateRange = {
            startDate: moment().subtract(16, 'days'),
            endDate: moment().subtract(2, 'days')
          };
          console.log('update date object in base component');
          //         needUpdate = true;
        } else {

          if (this.dateRange === undefined) {
            this.dateRange = globalSharedObject.dateRange;
          } else {
            if (globalSharedObject.dateRange.startDate !== null && globalSharedObject.dateRange.endDate !== null) {

              if ((this.dateRange.startDate.format('YYYY-MM-DD') !== globalSharedObject.dateRange.startDate.format('YYYY-MM-DD'))
                || (this.dateRange.endDate.format('YYYY-MM-DD') !== globalSharedObject.dateRange.endDate.format('YYYY-MM-DD'))) {
                needUpdate = true;
                this.dateRange = globalSharedObject.dateRange;
                console.log('dates changed need update');
              }
            }
          }

        }
        if (this.campaignType !== globalSharedObject.campaignType) {
          needUpdate = true;
          this.campaignType = globalSharedObject.campaignType;
          console.log('campaign type changed need update');
        }
        if (this.TableLength !== globalSharedObject.TableLength) {
          needUpdate = true;
          this.TableLength = globalSharedObject.TableLength;
          console.log('campaign type changed need update');
        }

        /*
                if (this.portfolioId !== globalSharedObject.portfolioId) {
                  needUpdate = true;
                  this.portfolioId = globalSharedObject.portfolioId;
                  console.log('portfolioId type changed need update');
                }
                */
        if (globalSharedObject.selectedFilters !== undefined) {


          if (globalSharedObject.selectedFilters.length > 0) {
            this.selectedFiltersTempFogGlobalObject = JSON.parse(JSON.stringify(globalSharedObject.selectedFilters));

            if (this.selectedFilters.length === 0) {


              //   this.selectedFilters = JSON.parse(JSON.stringify(globalSharedObject.selectedFilters));
              //       this.selectedFilters = globalSharedObject.selectedFilters;
              if (this.listAvaliableFilters !== undefined) {
                this.selectedFiltersTempFogGlobalObject.forEach((element) => {
                  if (this.listAvaliableFilters.indexOf(element.filterParametr) !== -1) {
                    if (this.skipedFilters === undefined) {
                      this.skipedFilters = [];
                    }
                    this.skipedFilters.push(element);

                    this.selectedFilters.push(element);
                    needUpdate = true;
                  }
                });
              }


            } else {
              // @todo need compare 2 arrays they may have different order of items
              /*
                          // compare objects
                          let tmp1 = JSON.parse(JSON.stringify(this.selectedFilters));
                          let tmp2 = JSON.parse(JSON.stringify(globalSharedObject.selectedFilters));

                          tmp1.forEach((element1) => {

                            tmp2.forEach((element2) => {

                              if (element1.filterParametr === element2.filterParametr &&
                               element1.filterCondition === element2.filterCondition && element1.filterValue === element2.filterValue) {
                                tmp1.splice(tmp1.indexOf(element1), 1);
                                tmp2.splice(tmp2.indexOf(element2), 1);

                              }
                            });
                          });
              */

              //     this.selectedFilters = [ ...this.selectedFilters, ...globalSharedObject.selectedFilters];
              //   if (this.selectedFilters !== globalSharedObject.selectedFilters) {

              /*      let tmpTotalFilters =[];
                    this.selectedFilters.forEach((element2) => {
                      tmpTotalFilters.push(element2);
                    })


                    globalSharedObject.selectedFilters.forEach((element1) => {
                      let elementExist = true;
                      this.selectedFilters.forEach((element2) => {
                        if (element1.filterParametr !== element2.filterParametr || element1.filterCondition !== element2.filterCondition ||
                        element1.filterValue !== element2.filterValue) {
                          elementExist = false;
                          needUpdate = true;
                        }
                      });
                      if (!elementExist) {
                        this.selectedFilters.push(element1);
                      }
                    });

      */


              globalSharedObject.selectedFilters.forEach((element1) => {
                let elementExist = false;
                this.selectedFilters.forEach((element2) => {
                  if (element1.filterParametr === element2.filterParametr && element1.filterCondition === element2.filterCondition &&
                    element1.filterValue === element2.filterValue) {
                    elementExist = true;
                    //      needUpdate = true;
                  }
                });

                if (!elementExist) {

                  if (this.listAvaliableFilters !== undefined) {
                    if (this.listAvaliableFilters.indexOf(element1.filterParametr) !== -1) {

                      this.selectedFilters.push(element1);
                      needUpdate = true;
                    }
                  }
                }
              });

// do the same for global objects
              this.selectedFilters.forEach((element1) => {
                let elementExist = false;
                globalSharedObject.selectedFilters.forEach((element2) => {
                  if (element1.filterParametr === element2.filterParametr && element1.filterCondition === element2.filterCondition &&
                    element1.filterValue === element2.filterValue) {
                    elementExist = true;
                    //      needUpdate = true;
                  }
                });

                if (!elementExist) {

                  if (this.listAvaliableFilters !== undefined) {
                    if (this.listAvaliableFilters.indexOf(element1.filterParametr) !== -1) {

                      this.selectedFiltersTempFogGlobalObject.push(element1);
                      needUpdate = true;
                    }
                  }
                }
              });


              // this.selectedFilters = JSON.parse(JSON.stringify(globalSharedObject.selectedFilters));
              //    this.selectedFilters = globalSharedObject.selectedFilters;
              this.selectedFilters.forEach((element) => {
                if (this.listAvaliableFilters !== undefined) {
                  if (this.listAvaliableFilters.indexOf(element.filterParametr) === -1) {
                    if (this.skipedFilters === undefined) {
                      this.skipedFilters = [];
                    }
                    this.skipedFilters.push(element);
                    this.selectedFilters.splice(this.selectedFilters.indexOf(element), 1);
                  }
                }
              });
              //   console.log('selected filters changed need update');
              //    needUpdate = true;
              //      }
            }
          } else {
            if(this.selectedFilters!==undefined){
              this.selectedFiltersTempFogGlobalObject = JSON.parse(JSON.stringify(this.selectedFilters));
            }
          }
        }
      }
      if (needUpdate) {
        this.updatePageData();
      }
    });
    this.ngInitFinished();
  }

  ngInitFinished() {

  }

  isarrayEqual(array1, array2) {
    const arrayEquals = true;

    if (array1.length === 0 && array2.length === 0) {
      return arrayEquals;
    }
    const tmpArray = [];


    array1.forEach((element) => {
      console.log(element);
      tmpArray.push(element);


    });

  }


  changePageLength(event) {
    console.log('page length changed');
    this.updateGlonbalObject();
    this.updatePageData();

    if (this.dtElements !== undefined) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          dtInstance.table().page.len(this.TableLength).draw();
        });
      });
    }
  }

  searchIndatatables(event) {
    console.log(event);
    if (this.dtElements !== undefined) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
          dtInstance.table().search(this.SearchINTable).draw();
        });
      });
    }
  }

  ShowPreset(range) {
    this.ActivePreset = range;
    switch (range) {
      case 'today':
        this.dateRange.startDate = moment();
        this.dateRange.endDate = moment();

        break;
      case '24h':
        this.dateRange.startDate = moment().subtract(24, 'hours');
        this.dateRange.endDate = moment();

        break;
      case 'yesterday':
        this.dateRange.startDate = moment().subtract(1, 'days');
        this.dateRange.endDate = moment().subtract(1, 'days');

        break;
      case '7d':
        this.dateRange.startDate = moment().subtract(9, 'days');
        this.dateRange.endDate = moment().subtract(2, 'days');

        break;
      case '14d':
        this.dateRange.startDate = moment().subtract(16, 'days');
        this.dateRange.endDate = moment().subtract(2, 'days');

        break;
      case '30d':
        this.dateRange.startDate = moment().subtract(32, 'days');
        this.dateRange.endDate = moment().subtract(2, 'days');

        break;
      case '60d':
        this.dateRange.startDate = moment().subtract(62, 'days');
        this.dateRange.endDate = moment().subtract(2, 'days');

        break;
      case '90d':
        this.dateRange.startDate = moment().subtract(92, 'days');
        this.dateRange.endDate = moment().subtract(2, 'days');
        break;
    }
    this.updateGlonbalObject();
    this.updatePageData();
  }


  updatePageData() {
    console.log('update page data BASE');
    // main function for updating all datas on page
    this.updateGlonbalObject();
    this.selectedRecords = [];
  }

  /**
   * we will use only one function for all components. unneceserry  parts will be ignored on backend
   */
  postdata() {
    return {
      startDate: this.dateRange.startDate.format('YYYY-MM-DD'),
      endDate: this.dateRange.endDate.format('YYYY-MM-DD'),
      campaignType: this.campaignType,
      portfolioId: this.portfolioId,
      CampaignIdList: this.CampaignIdList,
      filter: this.selectedFilters,
      filterSKU: this.filterSKU,
      showTargets: this.showTargets,
      showAdGroups: this.showAdGroups,
      showASINS: this.showASINS,
      onlyActive: this.onlyActive,
      onlyAdGroups: this.onlyAdGroups,
      showSearchTerms: this.showSearchTerms,
      ToolsType: this.toolsType,
      SelectAll: this.SelectAll,
      showCampaigns: this.showCampaigns,
      RequestType: this.requestType,
      showKeywords: this.showKeywords,
      presetSelected: this.presetSelected,
      onlywithSchedules: this.onlywithSchedules
    };
  }

  updateGlonbalObject() {
    console.log('global updated');


    const globalSharedObject = {
      dateRange: this.dateRange,
      dateRangesPresets: this.dateRangesPresets,
      LoadingInProgress: this.LoadingInProgress,
      bodyLayout: this.bodyLayout,
      campaignType: this.campaignType,
      portfolioId: this.portfolioId,
      selectedFilters: this.selectedFiltersTempFogGlobalObject,
      skipedFilters: this.skipedFilters,
      TableLength: this.TableLength
    };
    this.dataShared.changeMessage(globalSharedObject);
  }

  showLoadingBar() {
    this.LoadingInProgress = true;
    this.updateGlonbalObject();
  }

  hideLoadingBar() {
    this.LoadingInProgress = false;
    this.updateGlonbalObject();
  }

  showAuthLayout() {
    this.bodyLayout = 'auth';
    this.updateGlonbalObject();
  }

  showMainLayout() {
    this.bodyLayout = 'main';
    this.updateGlonbalObject();
  }

  closeHelp(type) {
    console.log('type');
  }

  changeCampaignType(event) {
    this.updateGlonbalObject();
    this.updatePageData();
  }

  changePortfolio(event) {
    console.log('portfolio changed');

    /*
        if (this.dtElements !== undefined) {
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            dtElement.dtInstance.then((dtInstance: any) => {
              console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
              dtInstance.table().ajax.reload();
              this.TableLength = dtInstance.table().page.len();
            });
          });
        }
    */
    this.updateGlonbalObject();
    this.childComponent.updatePageData();
    this.childComponent.potfolioId = this.portfolioId;

  }

  changeAccountId(event) {
    console.log('account changed');
  }


  ShowVisiblityFilter(section) {
    console.log(section);
    this.selectedSectionForColumnVis = section;

    switch (section) {
      case 'negativeSuggestion':
        this.visibleColumns = this.allVisibleNegative;
        break;
      case 'newSuggestion':
        this.visibleColumns = this.allVisibleNew;
        break;
      case 'bidsSuggestion':
        this.visibleColumns = this.allVisibleBids;
        break;
      case 'duplicatesSuggestion':
        this.visibleColumns = this.allVisibleDuplicates;
        break;
    }

    const allColumnsVis = [];
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
        if (dtInstance.table().node().id === section + 'Table') {
          console.log(dtInstance.table().columns());
          let id = 0;
          dtInstance.table().columns().every(function() {
            const data = this.data();
            console.log(data);
            //   console.log(this.column().name()); // since DT 2.0
            console.log(this.visible());
            const colheader = this.header();
            const title = $(colheader).text().trim();
            console.log(title);
            if (title !== '') {
              allColumnsVis.push({name: title, id, visibility: this.visible()});
            }

            id += 1;

          });
          this.allColumnsVis = allColumnsVis;
          this.openVisibilityModal(dtInstance.table());
        }
      });
    });
  }

  openVisibilityModal(datatableInstance) {
    const modalRef = this.modalService.open(VisibilityFilterModalComponent, {size: 'sm'});
    modalRef.componentInstance.allColumnsVis = this.allColumnsVis;
    modalRef.componentInstance.datatableInstance = datatableInstance;
    modalRef.componentInstance.visibleColumns = this.visibleColumns;
    modalRef.result.then((result) => {
      console.log(result);
      if (result) {
        console.log(result);
        this.visibilityAppliedCallback();
      }
    }, (reason) => {
      console.log(reason);
      console.log(this.allColumnsVis);

      const url = '/api/visability/save';
      const postData = {section: this.selectedSectionForColumnVis, visibility: this.allColumnsVis};
      /*   this.http.post<any>(url, postData).toPromise().then(
          result => {
            console.log('updated');
          }
        );
        */
      if (reason === ModalDismissReasons.ESC) {
        console.log('by pressing ESC');
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        console.log('by clicking on a backdrop');
      } else {
        console.log(`with: ${reason}`);
      }
    });
  }

  visibilityAppliedCallback() {
  }

  SaveBid(record, bidType = 1) {
    console.log('saveBid');
    console.log(record);
    this.panel[record.slug] = false;

    const url = '/api/keywordsBid/update';
    let recordId = 0;


    if (record.Level === 'Campaign') {
      bidType = 4;
    } else if (record.Level === 'AdGroup') {
      bidType = 3;
    } else {
      if (record.targetId > 0) {
        bidType = 2;
      } else {
        bidType = 1;
      }
    }


    /*
        if (bidType === 1 && record.keywordId === 0 && record.targetId > 0) {
          bidType = 2;
        }
    */

    switch (bidType) {
      case 1:
        recordId = record.keywordId;
        break;
      case 2:
        recordId = record.targetId;
        break;
      case 3:
        recordId = record.adGroupId;
        break;
      case 4:
        recordId = record.campaignId;
        break;
    }

    const postData = {keyword: recordId, newBid: this.bids[record.slug], bidType};
    this.http.post<any>(url, postData).toPromise().then(
      result => {
        console.log('From Promise:', result);
        if (result.error === false) {
          this.toastr.success(result.msg);
        } else {
          this.toastr.warning('Error while update keyword' + result.msg);
        }
      }
    );
  }


  SaveSuggestedBid(newbid, record) {
    console.log('suggested');
    console.log(record);
    this.bids[record.slug] = newbid;
    this.SaveBid(record);
  }

  showPanel(panelName) {
    console.log(panelName);
    this.panel[panelName] = true;
    for (let prop in this.panel) {
      console.log(prop);
      if (prop !== panelName) {
        this.panel[prop] = false;
      }
    }
  }

  hidePanel(panelName) {
    this.panel[panelName] = false;
  }

  negativeSelectorchanged(negativeType, record) {
    console.log('update neg in select');
    console.log(record);
    if (negativeType === '0') {
      this.neg[record.slug] = '';
    } else {
      this.neg[record.slug] = negativeType;
    }
    this.edited[record.slug] = true;

    const url = '/api/negatives/add';
    const selectedRecords = [{
      keywordId: record.keywordId,
      keywordText: record.keywordText_origin,
      searchTerm: record.searchTerm_origin,
      campaignId: record.campaignId,
      targetId: record.targetId
    }];

    const postData = {
      adGroup: record.adGroupId,
      negativeTarget: 'searchterm',
      negativeType,
      records: selectedRecords,
      startDate: null,
      endDate: null
    };

    this.http.post<any>(url, postData).toPromise().then(
      result => {
        console.log('From Promise:', result);
        if (result.error === false) {
          this.toastr.success(result.msg);
          this.updatePageData();
        } else {
          this.toastr.warning('Error while update keyword' + result.msg);
        }
      }
    );

  }


  updateNegative(negativeType, record) {
    console.log('update neg');
    console.log(record);
    if (negativeType === '0') {
      this.neg[record.slug] = '';
    } else {
      this.neg[record.slug] = negativeType;
    }
    this.edited[record.slug] = true;

    const url = '/api/negatives/add';
    const selectedRecords = [{
      keywordId: record.keywordId,
      keywordText: record.keywordText_origin,
      searchTerm: record.searchTerm_origin,
      campaignId: record.campaignId,
      targetId: record.targetId
    }];

    const postData = {
      adGroup: record.adGroupId,
      negativeTarget: 'searchterm',
      negativeType,
      records: selectedRecords,
      startDate: null,
      endDate: null
    };

    this.http.post<any>(url, postData).toPromise().then(
      result => {
        console.log('From Promise:', result);
        if (result.error === false) {
          this.toastr.success(result.msg);
          this.updatePageData();
        } else {
          this.toastr.warning('Error while update keyword' + result.msg);
        }
      }
    );
  }


  ReturnDefault(record) {


  }

  AddRecord(record) {

    if (this.selectedRecords !== undefined) {
      let recordExisted = false;
      let indexExisted = 0;
      this.selectedRecords.forEach((element, index: number) => {
        if (element.keywordId === record.keywordId && element.targetId === record.targetId &&
          element.keywordText === record.keywordText_origin &&
          element.searchTerm === record.searchTerm_origin && element.campaignId === record.campaignId &&
          element.adGroupId === record.adGroupId) {
          recordExisted = true;
          indexExisted = index;
        }
      });

      if (!recordExisted) {
        this.selectedRecords.push({
          keywordId: record.keywordId,
          targetId: record.targetId,
          keywordText: record.keywordText_origin,
          searchTerm: record.searchTerm_origin,
          slug: record.slug,
          campaignId: record.campaignId,
          adGroupId: record.adGroupId
        });
      } else {
        this.selectedRecords.splice(indexExisted, 1);

      }
    } else {
      this.selectedRecords = [];
      this.selectedRecords.push({
        keywordId: record.keywordId,
        targetId: record.targetId,
        keywordText: record.keywordText,
        searchTerm: record.keywordText,
        slug: record.slug,
        campaignId: record.campaignId,
        adGroupId: record.adGroupId
      });
    }
  }

  filterAppliedCallback() {
    this.updatePageData();
  }

  ShowFiltersModal() {
    this.hideFilterBlock = !this.hideFilterBlock;
    /*
    const modalRef = this.modalService.open(FiltersModalComponent, {size: 'lg'});
    if (this.selectedFilters === undefined) {
      this.selectedFilters = [];
    }
    //  this.selectedFiltersTemporary=this.selectedFilters;

    this.selectedFiltersTemporary = JSON.parse(JSON.stringify(this.selectedFilters));
    modalRef.componentInstance.selectedFilters = this.selectedFilters;

    modalRef.result.then((result) => {
      console.log(result);
      if (result) {
        console.log(result);
        this.selectedFilters = result;
        this.filterAppliedCallback();
      }
    }, (reason) => {

      this.selectedFilters = this.selectedFiltersTemporary;

      console.log(reason);
      if (reason === ModalDismissReasons.ESC) {
        console.log('by pressing ESC');
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        console.log('by clicking on a backdrop');
      } else {
        console.log(`with: ${reason}`);
      }
    });
    */
  }

  deleteFilter(item) {

    this.selectedFilters.splice(this.selectedFilters.indexOf(item), 1);

    let indexGlobal = 0;
    this.selectedFiltersTempFogGlobalObject.forEach((element, index) => {
      if (element.verbose === item.verbose) {
        indexGlobal = index;
      }
    });
    this.selectedFiltersTempFogGlobalObject.splice(indexGlobal, 1);
    this.filterAppliedCallback();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  setState(state, record) {


    console.log('set state');
    this.state[record.slug] = state;

    switch (state) {
      case 'enabled':
        if (record.Level === 'Campaign') {
          this.RunCampaign(record);
        } else if (record.Level === 'AdGroup') {
          this.RunAdGroup(record);
        } else {
          this.RunSelected(record);
        }

        break;
      case 'paused':

        if (record.Level === 'Campaign') {
          this.PauseCampaign(record);
        } else if (record.Level === 'AdGroup') {
          this.PauseAdGroup(record);
        } else {
          this.PauseSelected(record);
        }

        break;
      case 'archived':

        const modalRef = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
        modalRef.componentInstance.ConfirmMessage = 'Would you like to archive this record(s)?';
        modalRef.result.then((result) => {
          console.log(result);
          if (result) {
            console.log(result);
            if (result === 'Yes') {
              this.ArchiveSelected(record);
            }
          }
        }, (reason) => {
          console.log(reason);
        });

        break;
    }

  }

  ngOnDestroy(): void {
    console.log('ng destroy');
    if (this.myValueSub) {
      this.myValueSub.unsubscribe();
    }
  }

  applySelectedActionSchedule() {
    this.ActionValue = this.ActionValueSchedules;
    this.applySelectedAction();
    this.ActionValueSchedules = 'init';
  }

  applySelectedActionStatus() {
    this.ActionValue = this.ActionValueStatus;

    this.applySelectedAction();

  }

  applySelectedActionSuggestedBid() {
    this.ActionValue = this.ActionValueSuggestedBid;
    this.applySelectedAction();
  }

  applySelectedActionSuggestedNegative() {
    this.ActionValue = this.ActionValueSuggestedNegative;
    this.applySelectedAction();
    // this.ActionValueStatus = 'init';
    //  this.tmptest();
  }

  /*
    tmptest(){
      this.ActionValueStatus = 'init';
    }
  */
  applySelectedActionAutopilot() {
    this.ActionValue = this.ActionValueAutopilot;
    this.applySelectedAction();

  }

  applySelectedActionTracking() {
    this.ActionValue = this.ActionValueTracking;
    this.applySelectedAction();
    this.ActionValueTracking = 'init';
  }

  applySelectedActionNegative() {
    this.ActionValue = this.ActionValueNegative;
    this.applySelectedAction();
    this.ActionValueNegative = 'init';
  }

  applySelectedAction(action = '') {
    if (action !== '') {
      this.ActionValue = action;
    }
    console.log(this.ActionValue);

    switch (this.ActionValue) {

      case 'pauseCampaign':
        this.PauseCampaign();
        break;

      case 'pauseAdGroup':
        this.PauseAdGroup();
        break;

      case 'runCampaign':
        this.RunCampaign();
        break;

      case 'runAdGroup':
        this.RunAdGroup();
        break;

      case 'archiveCampaign':
        this.ArchiveCampaign(null);
        break;

      case 'archiveAdGroup':
        this.ArchiveAdGroup(null);
        break;

      case 'schedule':
        this.ShowScheduleModal();
        setTimeout(() => {
          this.ActionValueSchedules = 'init';
        }, 100);
        break;

      case 'schedule_stop':
        this.DeleteSelectedSchedule();
        setTimeout(() => {
          this.ActionValueSchedules = 'init';
        }, 100);
        break;

      case 'schedule_settings':
        this.ShowSchedules();
        setTimeout(() => {
          this.ActionValueSchedules = 'init';
        }, 100);
        break;

      case 'copyKW':
      case 'copyST':
        this.PasteSelected(null);
        break;

      case 'negativeKW':
        this.NegativeSelected('keyword');
        setTimeout(() => {
          this.ActionValueNegative = 'init';
        }, 100);
        break;

      case 'negativeST':
        this.NegativeSelected('searchterm');
        setTimeout(() => {
          this.ActionValueNegative = 'init';
        }, 100);
        break;

      case 'negativeKW_delete':
        this.NegativeSelectedDELETE('keyword');
        break;

      case 'negativeST_delete':
        this.NegativeSelectedDELETE('searchterm');
        break;

      case 'pauseKW':
        this.PauseSelected(null);
        break;

      case 'runKW':
        this.RunSelected(null);
        break;

      case 'archiveKW':
        this.ArchiveSelected(null);
        break;

      case 'track_start':
        this.addSelectedRecordsToTracking();
        break;

      case 'track_stop':
        this.UnTrackSelected();
        break;

      case 'track_start_ST':
        this.addSelectedRecordsToTracking('ST');
        break;

      case 'track_stop_ST':
        this.UnTrackSelected('ST');
        break;


      case 'add_to_Campaign':
        this.ShowAddToCampaignModal();
        break;

      case 'autopilot_start':
        this.ShowAutopilotModal();
        setTimeout(() => {
          this.ActionValueAutopilot = 'init';
        }, 100);
        break;

      case 'autopilot_stop':
        this.stopAutopilot();
        setTimeout(() => {
          this.ActionValueAutopilot = 'init';
        }, 100);
        break;

      case 'apply_allBids':
        this.ApplyAllSuggestedBids();
        setTimeout(() => {
          this.ActionValueSuggestedBid = 'init';
        }, 100);
        break;

      case 'apply_allNegatives':
        this.ApplyAllSuggestedNegatives();
        setTimeout(() => {
          this.ActionValueSuggestedNegative = 'init';
        }, 100);
        break;


    }
    this.ActionValue = '';
  }

  ApplyAllSuggestedBids() {

  }

  ApplyAllSuggestedNegatives() {

  }


  PauseCampaign(campaignId = null) {
    const url = '/api/campaignAction/pause';
    let postData = {};
    if (campaignId === null) {
      if (this.selectedRecords.length === 0) {
        this.toastr.warning('Please select records first');
        return false;
      } else {
        postData = {records: this.selectedRecords};
      }
    } else {
      postData = {records: [campaignId]};
    }

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        setTimeout(() => {
          this.ActionValueStatus = 'init';
        }, 100);

        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
            this.updatePageData();
            /*
                    angular.forEach(PostData.campaigns, function(value) {
                      var model = $parse('state.r' + value);
                      model.assign($scope, 'paused');
                    });
                    */
          }
        }
      });
  }

  PauseAdGroup(adGroupId = null) {
    const url = '/api/adGroupAction/pause';
    let postData = {};
    if (adGroupId === null) {
      if (this.selectedRecords.length === 0) {
        this.toastr.warning('Please select records first');
        return false;
      } else {
        postData = {records: this.selectedRecords};
      }
    } else {
      postData = {records: [adGroupId]};
    }

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        setTimeout(() => {
          this.ActionValueStatus = 'init';
        }, 100);
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
            this.updatePageData();
          }
        }
      });
  }

  RunCampaign(campaignId = null) {
    const url = '/api/campaignAction/run';
    let postData = {};
    if (campaignId === null) {
      if (this.selectedRecords.length === 0) {
        this.toastr.warning('Please select records first');
        return false;
      } else {
        postData = {records: this.selectedRecords};
      }
    } else {
      postData = {records: [campaignId]};
    }

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        setTimeout(() => {
          this.ActionValueStatus = 'init';
        }, 100);
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
            this.updatePageData();
            /*
                    angular.forEach(PostData.campaigns, function(value) {
                      var model = $parse('state.r' + value);
                      model.assign($scope, 'paused');
                    });
                    */
          }
        }

      });
  }

  RunAdGroup(adgroupId = null) {
    const url = '/api/adGroupAction/run';
    let postData = {};
    this.ActionValueStatus = 'init';
    if (adgroupId === null) {
      if (this.selectedRecords.length === 0) {
        this.toastr.warning('Please select records first');
        return false;
      } else {
        postData = {records: this.selectedRecords};
      }
    } else {
      postData = {records: [adgroupId]};
    }

    this.http.post<any>(url, postData).toPromise()
      .then(result => {

        setTimeout(() => {
          this.ActionValueStatus = 'init';
        }, 100);

        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
            this.updatePageData();
            /*
                    angular.forEach(PostData.campaigns, function(value) {
                      var model = $parse('state.r' + value);
                      model.assign($scope, 'paused');
                    });
                    */
          }
        }

      });
  }

  ArchiveCampaign(campaignId) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    modalRef.componentInstance.ConfirmMessage = 'Would you like to archive this campaign(s)?';
    modalRef.result.then((resultModal) => {
      console.log(resultModal);
      if (resultModal) {
        console.log(resultModal);
        if (resultModal === 'Yes') {


          const url = '/api/campaignAction/archive';
          let postData = {};
          if (campaignId === null) {
            if (this.selectedRecords.length === 0) {
              this.toastr.warning('Please select records first');
              return false;
            } else {
              postData = {records: this.selectedRecords};
            }
          } else {
            postData = {campaigns: [campaignId]};
          }

          this.http.post<any>(url, postData).toPromise()
            .then(result => {
              setTimeout(() => {
                this.ActionValueStatus = 'init';
              }, 100);
              if (result === undefined) {
                this.toastr.warning('Server error');
              } else {
                if (result.error === true) {
                  this.toastr.warning(result.msg);
                } else {
                  this.toastr.success(result.msg, 'Updated', {enableHtml: true});
                  this.updatePageData();
                  /*
                          angular.forEach(PostData.campaigns, function(value) {
                            var model = $parse('state.r' + value);
                            model.assign($scope, 'paused');
                          });
                          */
                }
              }
            });
        }
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  ArchiveAdGroup(adGroupId) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    modalRef.componentInstance.ConfirmMessage = 'Would you like to archive this adgroup(s)?';
    modalRef.result.then((resultModal) => {
      console.log(resultModal);
      if (resultModal) {
        console.log(resultModal);
        if (resultModal === 'Yes') {


          const url = '/api/adGroupAction/archive';
          let postData = {};
          if (adGroupId === null) {
            if (this.selectedRecords.length === 0) {
              this.toastr.warning('Please select records first');
              return false;
            } else {
              postData = {records: this.selectedRecords};
            }
          } else {
            postData = {records: [adGroupId]};
          }

          this.http.post<any>(url, postData).toPromise()
            .then(result => {
              setTimeout(() => {
                this.ActionValueStatus = 'init';
              }, 100);
              if (result === undefined) {
                this.toastr.warning('Server error');
              } else {
                if (result.error === true) {
                  this.toastr.warning(result.msg);
                } else {
                  this.toastr.success(result.msg, 'Updated', {enableHtml: true});
                  this.updatePageData();
                  /*
                          angular.forEach(PostData.campaigns, function(value) {
                            var model = $parse('state.r' + value);
                            model.assign($scope, 'paused');
                          });
                          */
                }
              }
            });


        }
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  ShowScheduleModal(elementId = null) {

    console.log('call show modal');

    let SelectedCampaigns = [];
    if (elementId !== null) {
      SelectedCampaigns = [elementId];
    } else {
      this.selectedRecords.forEach((element) => {
        SelectedCampaigns.push(element.campaignId);
      });
    }
    let ScheduleTarget = '';
    this.allCampaigns.forEach((element) => {
      if (element.campaignId === SelectedCampaigns[0]) {
        ScheduleTarget = element.campaignName;
      }
    });

    const modalRef = this.modalService.open(ScheduleModalComponent, {size: 'xl'});
    modalRef.componentInstance.ScheduleTarget = ScheduleTarget;
    modalRef.componentInstance.SelectedCampaigns = SelectedCampaigns;
    modalRef.result.then((resultModal) => {
      console.log(resultModal);
    }, (reason) => {
      console.log(reason);
    });


  }

  DeleteSelectedSchedule() {
    let postData = {};
    if (this.selectedRecords.length === 0) {
      this.toastr.warning('Please select records first');
      return false;
    } else {
      postData = {records: this.selectedRecords};
    }
    const url = '/api/schedule/campaigns/Delete';

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === true) {
            this.toastr.warning(result.msg);
          } else {
            this.toastr.success('Updated');

            this.updatePageData();
          }
        }
      });

  }

  ShowSchedules(elementId = null) {

    const scheduleTarget = 'campaign';
    let ScheduleTargetId = 0;
    let OnlyOneCampaign = '';
    let SelectedCampaigns = [];
    if (elementId !== null) {
      SelectedCampaigns = [elementId];
      OnlyOneCampaign = elementId;
    } else {
      if (this.selectedRecords.length === 0) {
        this.toastr.warning('please select records first');
        return false;
      }
      SelectedCampaigns.push(this.selectedRecords[0].campaignId);
    }
    if (SelectedCampaigns.length === 0) {
      this.toastr.warning('please select records first');
      return false;
    }

    ScheduleTargetId = SelectedCampaigns[0];
    const modalRef = this.modalService.open(ScheduleListModalComponent, {size: 'lg'});
    modalRef.componentInstance.scheduleTargetText = scheduleTarget;
    modalRef.componentInstance.ScheduleTargetId = ScheduleTargetId;
    modalRef.result.then((resultModal) => {
      console.log(resultModal);
    }, (reason) => {
      console.log(reason);
    });

  }

  PasteSelected(dataArray) {
    let Results = '';
    if (dataArray === null) {
      dataArray = this.selectedRecords;

    }
    console.log(typeof (dataArray));
    if (Array.isArray(dataArray)) {
      dataArray.forEach((element, index: number) => {
        Results = Results + element.keywordText + '\n';
      });
    } else {
      Results = dataArray;
    }
    const copyElement = document.createElement('textarea');
    copyElement.style.position = 'fixed';
    copyElement.style.opacity = '0';
    copyElement.textContent = Results;
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(copyElement);
    copyElement.select();
    document.execCommand('copy');
    body.removeChild(copyElement);

    this.toastr.success('Copied');
    return Results;
  }

  NegativeSelected(type) {

    this.negativeTarget = type;
    let NegativeTarget = '';
    console.log('call Show Modal NegativeSelected');
    if (this.selectedRecords.length === 1) {
      NegativeTarget = this.selectedRecords[0].keywordText;
    } else {
      NegativeTarget = this.selectedRecords.length + ' keywords';
    }


    const modalRef = this.modalService.open(AddToNegativeModalComponent, {size: 'md'});
    modalRef.componentInstance.selectedRecords = this.selectedRecords;
    modalRef.componentInstance.negativeTarget = this.negativeTarget;
    modalRef.result.then((resultModal) => {
      console.log(resultModal);
      this.updatePageData();
    }, (reason) => {
      console.log(reason);
    });

  }

  NegativeSelectedDELETE(negativetarget) {

    if (this.selectedRecords.length === 0) {
      this.toastr.warning('Please select records first');
      return false;
    }

    const url = '/api/negatives/delete';
    const postData = {
      negativeTarget: negativetarget,
      records: this.selectedRecords,
      startDate: this.dateRange.startDate.format('YYYY-MM-DD'),
      endDate: this.dateRange.endDate.format('YYYY-MM-DD'),
    };

    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        setTimeout(() => {
          this.ActionValueNegative = 'init';
        }, 100);
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
            this.updatePageData();
          } else {
            this.toastr.warning('Error while update keyword' + result.msg);

          }
        }
      });
  }

  PauseSelected(keywordId) {
    let postData = {};
    if (keywordId == null) {
      if (this.selectedRecords.length === 0) {
        this.toastr.warning('Please select keywords first');
        return false;
      }
      postData = {keyword: this.selectedRecords};
    } else {
      postData = {keyword: [keywordId]};
    }

    const url = '/api/keywordsAction/pause';
    this.http.post<any>(url, postData).toPromise()
      .then(result => {

        setTimeout(() => {
          this.ActionValueStatus = 'init';
        }, 100);

        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            if (result.msg === '') {
              result.msg = 'Updated';
            }
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
            this.updatePageData();
          } else {
            this.toastr.warning(result.msg);
          }
        }
      });
  }

  RunSelected(keywordId) {
    let postData = {};
    if (keywordId == null) {
      if (this.selectedRecords.length === 0) {
        this.toastr.warning('Please select keywords first');
        return false;
      }
      postData = {keyword: this.selectedRecords};
    } else {
      postData = {keyword: [keywordId]};
    }

    const url = '/api/keywordsAction/run';
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        setTimeout(() => {
          this.ActionValueStatus = 'init';
        }, 100);

        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            if (result.msg === '') {
              result.msg = 'Updated';
            }
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
            this.updatePageData();
          } else {
            this.toastr.warning(result.msg);
          }
        }
      });

  }

  ArchiveSelected(keywordId) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    modalRef.componentInstance.ConfirmMessage = 'Would you like to archive this keyword(s)?';
    modalRef.result.then((resultModal) => {
      console.log(resultModal);
      if (resultModal) {
        console.log(resultModal);
        if (resultModal === 'Yes') {

          let postData = {};
          if (keywordId == null) {
            if (this.selectedRecords.length === 0) {
              this.toastr.warning('Please select keywords first');
              return false;
            }
            postData = {keyword: this.selectedRecords};
          } else {
            postData = {keyword: [keywordId]};
          }

          const url = '/api/keywordsAction/archive';
          this.http.post<any>(url, postData).toPromise()
            .then(result => {
              setTimeout(() => {
                this.ActionValueStatus = 'init';
              }, 100);

              if (result === undefined) {
                this.toastr.warning('Server error');
              } else {
                if (result.error === false) {
                  if (result.msg === '') {
                    result.msg = 'Updated';
                  }
                  this.toastr.success(result.msg, 'Updated', {enableHtml: true});
                  this.updatePageData();
                } else {
                  this.toastr.warning(result.msg);
                }
              }
            });
        }
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  addSelectedRecordsToTracking(type = 'KW') {

    let url = '';
    if (type === 'KW') {
      url = '/api/keywordsAction/addToTrack';
    } else {
      url = '/api/keywordsAction/addToTrackST';
    }
    this.showLoadingBar();
    const postData = {keyword: this.selectedRecords, productId: 0};
    this.http.post<any>(url, postData).toPromise()
      .then(result => {

        setTimeout(() => {
          this.ActionValueTracking = 'init';
        }, 100);
        this.hideLoadingBar();
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
          } else {
            this.toastr.warning(result.msg);
          }
        }
      });
  }

  UnTrackSelected(type = 'KW') {

    let url = '';
    if (type === 'KW') {
      url = '/api/keywordsAction/delFromTrack';
    } else {
      url = '/api/keywordsAction/delFromTrackST';
    }
    this.showLoadingBar();
    const postData = {keyword: this.selectedRecords, productId: 0};
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        setTimeout(() => {
          this.ActionValueTracking = 'init';
        }, 100);
        this.hideLoadingBar();
        if (result === undefined) {
          this.toastr.warning('Server error');
        } else {
          if (result.error === false) {
            this.toastr.success(result.msg, 'Updated', {enableHtml: true});
          } else {
            this.toastr.warning(result.msg);
          }
        }
      });
  }

  ShowAddToCampaignModal() {
    if (this.selectedRecords.length === 0) {
      this.toastr.warning('Please select records first');
      return false;
    }

    const modalRef = this.modalService.open(AddKeywordsToCampaignsModalComponent, {size: 'lg'});
    //  modalRef.componentInstance.ConfirmMessage = 'Would you like to archive this keyword(s)?';
    modalRef.result.then((resultModal) => {
      console.log(resultModal);
      if (resultModal) {
        console.log(resultModal);
        if (resultModal === 'Yes') {

        }
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  recalculateBid() {

  }

  switchPreset(presetType) {
    this.selectedPreset = presetType;
    switch (presetType) {
      case 'all':
        this.selectedFilters = [];
        break;
      case 'profitable':
        this.selectedFilters = [];
        this.selectedFilters.push({
          filterParametr: 'ACOS',
          filterCondition: 'lessThan',
          filterValue: 25,
          locked: false,
          verbose: 'ACoS < 25%'
        });
        break;
      case 'notProfitable':
        this.selectedFilters = [];
        this.selectedFilters.push({
          filterParametr: 'ACOS',
          filterCondition: 'lessThan',
          filterValue: 50,
          locked: false,
          verbose: 'ACoS > 50%'
        });
        break;
      case 'zeroSales':
        this.selectedFilters = [];
        this.selectedFilters.push({
          filterParametr: 'Sales',
          filterCondition: 'equals',
          filterValue: 0,
          locked: false,
          verbose: 'Sales = 0'
        });
        break;
    }
    this.filterAppliedCallback();
  }


  isAcosRed(acos) {
    if (isNaN(acos)) {
      return null;
    } else {
      if (acos === 0) {
        return 1;
      } else {
        const acosZone = 30;
        if (acos > acosZone) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  }


  updateNegatives(KeywordsToWork = []) {
    console.log('update negatives in service');
    const url = '/api/bulklockupNegativesST';
    let KeywordsToSent = [];
    if (KeywordsToWork.length === 0) {
      KeywordsToSent = this.AllAvaliableRecords;
    } else {
      KeywordsToSent = KeywordsToWork;
    }
    if (KeywordsToSent === undefined) {
      return false;
    }
    if (KeywordsToSent.length === 0) {
      return false;
    }
    const postData = {records: KeywordsToSent};
    this.http.post<any>(url, postData).toPromise()
      .then(result => {
        if (result === undefined) {
          this.toastr.warning('Error with your request');
        }

        if (result.error === true) {
          this.toastr.warning(result.msg);
        } else {
          const newKeywordsToSend = [];


          let founded = false;
          KeywordsToSent.forEach((elementKw, indexKw: number) => {
            result.keywords.forEach((element, index: number) => {
              if (elementKw.keywordId === element.keywordId) {
                // this record exist
                founded = true;
              }
            });
            result.targets.forEach((element, index: number) => {
              if (elementKw.targetId === element.targetId) {
                // this record exist
                founded = true;
              }
            });
            if (founded === false) {
              newKeywordsToSend.push(elementKw);
            }
          });


          result.keywords.forEach((element, index: number) => {
            this.neg[element.slugNeg] = element.isNegative;
            this.negPending[element.slugNeg] = element.pendingNegative;
            this.updatedNegative[element.slugNeg] = true;

            // KeywordsToSent.splice(KeywordsToSent.indexOf(element.keywordId), 1);
          });

          result.targets.forEach((element, index: number) => {
            this.neg[element.slugNeg] = element.isNegative;
            this.negPending[element.slugNeg] = element.pendingNegative;
            this.updatedNegative[element.slugNeg] = true;
            /*  KeywordsToSent.forEach((elementKw, indexKw: number) => {
                if (elementKw.targetId === element.targetId) {
                  // this record exist
                } else {
                  newKeywordsToSend.push(elementKw);
                }
              });
              */
            // KeywordsToSent.splice(KeywordsToSent.indexOf(element.keywordId), 1);
          });
          /*      */

          KeywordsToSent = newKeywordsToSend;

          if (KeywordsToSent.length > 0) {
            this.updateNegatives(KeywordsToSent);
          } else {

            return true;
          }
        }
      });

  }

  prepareFooterData() {
    this.footerData.Revenue = parseFloat(this.footerData.Revenue.toFixed(2));
    this.footerData.Spend = parseFloat(this.footerData.Spend.toFixed(2));
    this.footerData.Orders = parseFloat(this.footerData.Orders.toFixed(2));
    this.footerData.AmazonFees = parseFloat(this.footerData.AmazonFees.toFixed(2));
    this.footerData.Impressions = parseFloat(this.footerData.Impressions.toFixed(2));
    this.footerData.Clicks = parseFloat(this.footerData.Clicks.toFixed(2));
    if (this.footerData.TotalRecords > 0) {
      this.footerData.ACOSAVG = parseFloat((this.footerData.ACOSSum / this.footerData.TotalRecords).toFixed(2));
      this.footerData.CVRAvg = parseFloat((this.footerData.CVRSum / this.footerData.TotalRecords).toFixed(2));
      this.footerData.CTRAvg = parseFloat((this.footerData.CTRSum / this.footerData.TotalRecords).toFixed(2));
      this.footerData.CPCAvg = parseFloat((this.footerData.CPCSum / this.footerData.TotalRecords).toFixed(2));
    }

  }

  switchTab(newTab) {
    console.log(newTab);
  }


  //// FILTER


  makeVerbose() {

    let verboseString = this.filterParametr + ' ';

    switch (this.filterCondition) {

      case 'greated':
        verboseString += ' > ';
        break;
      case 'greatedOrEqual':
        verboseString += ' >= ';
        break;
      case 'lessThan':
        verboseString += ' < ';
        break;
      case 'lessOrEqual':
        verboseString += ' <= ';
        break;
      default :
        verboseString += ' = ';
        break;

    }
    verboseString += ' ' + this.filterValue;
    return verboseString;


  }


  addFilterCondition() {
    if (this.canFilterConditionAdded()) {
      this.selectedFilters.push({
        filterParametr: this.filterParametr,
        filterCondition: this.filterCondition,
        filterValue: this.filterValue,
        locked: false,
        verbose: this.makeVerbose()
      });

      if (this.selectedFiltersTempFogGlobalObject === undefined) {
        this.selectedFiltersTempFogGlobalObject = [];
      }
      this.selectedFiltersTempFogGlobalObject.push({
        filterParametr: this.filterParametr,
        filterCondition: this.filterCondition,
        filterValue: this.filterValue,
        locked: false,
        verbose: this.makeVerbose()
      });

      this.filterAppliedCallback();

      this.updateGlonbalObject();
    } else {
      this.toastr.warning('we cant add this filter. please try another');
    }

  }

  canFilterConditionAdded() {
    let parametrExist = false;
    let weCanAddCondition = true;
    this.selectedFilters.forEach(eachObj => {

      if (this.filterParametr === eachObj.filterParametr) {
        parametrExist = true;
        switch (eachObj.filterCondition) {
          case 'equals': {
            weCanAddCondition = false;
            break;
          }
          case 'greated': {
            const avaliableConditions = ['lessThan', 'lessOrEqual'];
            if (avaliableConditions.indexOf(this.filterCondition) === -1) {
              weCanAddCondition = false;
            } else {
              if (parseFloat(eachObj.filterValue) > parseFloat(this.filterValue)) {
                weCanAddCondition = false;
              } else {
                weCanAddCondition = true;
              }
            }
            break;
          }

          case 'greatedOrEqual': {
            const avaliableConditions = ['lessThan', 'lessOrEqual'];
            if (avaliableConditions.indexOf(this.filterCondition) === -1) {
              weCanAddCondition = false;
            } else {
              if (parseFloat(eachObj.filterValue) > parseFloat(this.filterValue)) {
                weCanAddCondition = false;
              } else {
                weCanAddCondition = true;
              }
            }
            break;
          }

          case 'lessThan': {
            const avaliableConditions = ['greated', 'greatedOrEqual'];
            if (avaliableConditions.indexOf(this.filterCondition) === -1) {
              weCanAddCondition = false;
            } else {
              if (parseFloat(eachObj.filterValue) < parseFloat(this.filterValue)) {
                weCanAddCondition = false;
              } else {
                weCanAddCondition = true;
              }
            }
            break;
          }

          case 'lessOrEqual': {
            const avaliableConditions = ['greated', 'greatedOrEqual'];
            if (avaliableConditions.indexOf(this.filterCondition) === -1) {
              weCanAddCondition = false;
            } else {
              if (parseFloat(eachObj.filterValue) < parseFloat(this.filterValue)) {
                weCanAddCondition = false;
              } else {
                weCanAddCondition = true;
              }

            }
            break;
          }
        }
      }
    });
    return weCanAddCondition;
  }


  // END FILTER

  ShowAutopilotModal() {

    const modalRef = this.modalService.open(AutopilotModalComponent, {size: 'lg'});
    modalRef.componentInstance.selectedRecords = this.selectedRecords;
    modalRef.result.then((result) => {
      console.log(result);
      if (result) {
        console.log(result);

      }
    }, (reason) => {
      console.log(reason);
      console.log(this.allColumnsVis);

      if (reason === ModalDismissReasons.ESC) {
        console.log('by pressing ESC');
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        console.log('by clicking on a backdrop');
      } else {
        console.log(`with: ${reason}`);
      }
    });


  }

  stopAutopilot() {
    const url = '/api/autopilot/autoSetupCampaigns';
    const data = {
      campaigns: this.selectedRecords,
      parametrs: this.autopilotSettings,
      action: 'stop'
    };

    this.http.post<any>(url, data).toPromise()
      .then(result => {
        if (result.error === false) {
          this.toastr.success(result.msg, 'Updated', {enableHtml: true});
          this.updatePageData();
        } else {
          this.toastr.warning(result.msg);
        }
      });
  }


  ChangeAutopilotToogle(curItem) {
    console.log('cange autopilot');

    if (this.toggleValue[curItem.slug] === true) {

      this.selectedRecords = [];
      this.selectedRecords.push(curItem);
      /*
            angular.forEach($scope.CampaignList, function (value, key) {
              if (value.id == campaignId) {
                $scope.AutopilotTarget = value.campaignName;
              }
            })
      */

      this.ShowAutopilotModal();
    } else {

      this.selectedRecords = [];
      this.selectedRecords.push(curItem);
      this.stopAutopilot();


    }


  }

  ShowHiddenBid() {
    this.hideBid = !this.hideBid;
  }


  onActivate(componentReference) {
    console.log('ACTIVATE');
    console.log(componentReference);
    this.childComponent = componentReference;
    // componentReference.anyFunction();
  }

  canAddNegative(curItem) {
    if (curItem.Level === 'SearchTerm' || curItem.Level === 'Target') {
      if (curItem.expressions == null) {
        return true;
      } else {
        if (curItem.matchType === 'Close-match') {
          return true;
        } else {
          return false;
        }

      }
    } else {
      return false;
    }
  }

}
